import React from 'react'
import Icon from 'components/utils/Icon'
import ViewLocation from 'components/utils/ViewLocation'
import Warning from 'components/utils/Warning'

const Modal = (props) => {

  let modal = {
    position: 'fixed',
    zIndex: props.zIndex === undefined ? 4 : props.zIndex,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)'
  }

  let modalContent = {
    borderRadius: 10,
    border: '1px solid #d3d3d3',
    backgroundColor: 'white',
    margin: '5px auto',
    overflow: 'auto',
    height: props.height === undefined ? 'calc(100% - 30px)' : props.height,
    width: props.width === undefined ? 'calc(100% - 30px)' : props.width,
    maxWidth: props.maxWidth === undefined ? 600 : props.maxWidth,
    maxHeight: props.maxHeight === undefined ? 'calc(100% - 30px)' : props.maxHeight,
  }

   // UNDO: for undo-ing signature in Sign mode

  return (
    <div style={modal}>
      <div style={modalContent}>

        <div style={{display: 'flex', flexFlow: 'column', width: '100%', height: '100%'}}>

          <div style={{flex: '0 1 auto'}}>

            <div style={{display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, backgroundColor: 'white'}}>
              <Icon name='close' color='tomato' title='Close' onClick={props.closeModal} />
              {props.delete !== undefined && props.delete !== null ? <Icon name='delete' title='Delete' color='tomato' onClick={props.delete} /> : null}
              {props.view !== undefined && props.view !== null ? <Icon name='assignment' title='View' onClick={props.view} /> : null}
              {props.sign !== undefined && props.sign !== null ? <Icon name='create' title='Sign' onClick={props.sign} /> : null}
              {props.reTest !== undefined && props.reTest !== null ? <Icon name='rotate_right' title='Perform Re-test' onClick={props.reTest} /> : null}
              {props.undo !== undefined && props.undo !== null ? <Icon name='undo' title='Undo' onClick={props.undo} /> : null}
              {props.help !== undefined && props.help !== null ? <Icon name='help_outline' onClick={props.help} /> : null}
              {props.add_alt !== undefined && props.add_alt !== null ? <Icon name='add_circle' title='Add point to drawing' onClick={props.add_alt} /> : null}
              {props.add !== undefined && props.add !== null ? <Icon name='check_circle' title='Save' onClick={props.add} /> : null}
            </div>

            {props.isModal !== undefined ? props.isModal.edit ? <ViewLocation data={props.isValidated} /> : null : null}

            {props.isModal !== undefined ? props.isModal.edit && props.isModal.warning ? <Warning content={props.isModal.warningContent} /> : null : null}

          </div>

          <div style={{flex: '2', justifyContent: 'center', width: '100%', overflow: 'auto'}}>
            {props.content}
          </div>

        </div>

      </div>
    </div>
  )
}

export default Modal
