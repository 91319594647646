import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Sa = (props) => {

  const data = props.data[0]
  //const [isChangedSample, setIsChangedSample] = useState(false)
  const canvasRef = useRef(null)

  const [isValidated, setIsValidated] = useState({
    retain10MoistureTaken: data.retain10MoistureTaken === 1 ? true : false,
    pass10MoistureTaken: data.pass10MoistureTaken === 1 ? true : false,
    method: data.method,
    retain10TotalWt: data.retain10totalweight,
    retain10TareNo: data.retain10TareNo,
    retain10TareWt: data.retain10tarewt,
    retain10WetSoilAndTareWt: data.retain10wetsoilandtarewt,
    retain10DrySoilAndTareWt: data.retain10drysoilandtarewt,
    pass10TotalWt: data.pass10totalweight,
    pass10TareNo: data.pass10TareNo,
    pass10TareWt: data.pass10tarewt,
    pass10WetSoilAndTareWt: data.pass10wetsoilandtarewt,
    pass10DrySoilAndTareWt: data.pass10drysoilandtarewt,
    s12: data.s12,
    s6: data.s6,
    s3: data.s3,
    s2: data.s2,
    s3_2: data.s3_2,
    s1: data.s1,
    s3_4: data.s3_4,
    s1_2: data.s1_2,
    s3_8: data.s3_8,
    s4: data.s4,
    s8: data.s8,
    s10: data.s10,
    s16: data.s16,
    s20: data.s20,
    s30: data.s30,
    s40: data.s40,
    s50: data.s50,
    s60: data.s60,
    s100: data.s100,
    s140: data.s140,
    s200: data.s200,
    pan: data.pan,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateSa', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            retain10MoistureTaken: isValidated.retain10MoistureTaken,
            pass10MoistureTaken: isValidated.pass10MoistureTaken,
            method: isValidated.method,
            retain10TotalWt: isValidated.retain10TotalWt,
            retain10TareNo: isValidated.retain10TareNo,
            retain10TareWt: isValidated.retain10TareWt,
            retain10WetSoilAndTareWt: isValidated.retain10WetSoilAndTareWt,
            retain10DrySoilAndTareWt: isValidated.retain10DrySoilAndTareWt,
            pass10TotalWt: isValidated.pass10TotalWt,
            pass10TareNo: isValidated.pass10TareNo,
            pass10TareWt: isValidated.pass10TareWt,
            pass10WetSoilAndTareWt: isValidated.pass10WetSoilAndTareWt,
            pass10DrySoilAndTareWt: isValidated.pass10DrySoilAndTareWt,
            s12: isValidated.s12,
            s6: isValidated.s6,
            s3: isValidated.s3,
            s2: isValidated.s2,
            s3_2: isValidated.s3_2,
            s1: isValidated.s1,
            s3_4: isValidated.s3_4,
            s1_2: isValidated.s1_2,
            s3_8: isValidated.s3_8,
            s4: isValidated.s4,
            s8: isValidated.s8,
            s10: isValidated.s10,
            s16: isValidated.s16,
            s20: isValidated.s20,
            s30: isValidated.s30,
            s40: isValidated.s40,
            s50: isValidated.s50,
            s60: isValidated.s60,
            s100: isValidated.s100,
            s140: isValidated.s140,
            s200: isValidated.s200,
            pan: isValidated.pan
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'sieve', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statussa',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')

      } else if (color === '' || color === null) {
        alert('Please provide a color.')

      } else if (isValidated.method === '' || isValidated.method === null) {
        alert('Please select a method.')

      } else if (isValidated.retain10TotalWt === '' || isValidated.retain10TotalWt === null) {
        alert('Please provide a total sample weight.')

      } else if (isValidated.pass10TotalWt === '' || isValidated.pass10TotalWt === null) {
        alert('Please provide a total weight passing 10.')

      } else if (isValidated.s200 === '' || isValidated.s200 === null) {
        alert('Please provide a weight for the 200.')

      } else if (isValidated.retain10MoistureTaken === 1) {

        if (isValidated.retain10TareNo === '' || isValidated.retain10TareNo === null) {
          alert('Please specify a tare number for the moisture.')
        } else if (isValidated.retain10TareWt === '' || isValidated.retain10TareWt === null) {
          alert('Please specify a tare weight for the moisture.')
        } else if (isValidated.retain10WetSoilAndTareWt === '' || isValidated.retain10WetSoilAndTareWt === null) {
          alert('Please provide a wet soil and tare weight.')
        } else if (isValidated.retain10DrySoilAndTareWt === '' || isValidated.retain10DrySoilAndTareWt === null) {
          alert('Please provide a dry soil and tare weight.')
        } else {
          update()
        }

      } else if (isValidated.pass10MoistureTaken === 1) {

        if (isValidated.pass10TareNo === '' || isValidated.pass10TareNo === null) {
          alert('Please specify a tare number for the moisture.')
        } else if (isValidated.pass10TareWt === '' || isValidated.pass10TareWt === null) {
          alert('Please specify a tare weight for the moisture.')
        } else if (isValidated.pass10WetSoilAndTareWt === '' || isValidated.pass10WetSoilAndTareWt === null) {
          alert('Please provide a wet soil and tare weight.')
        } else if (isValidated.pass10DrySoilAndTareWt === '' || isValidated.pass10DrySoilAndTareWt === null) {
          alert('Please provide a dry soil and tare weight.')
        } else {
          update()
        }

      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const clearMoistureInputsRetain10 = (event) => {
    let value = event.target.checked

    if (!value) {
      if (window.confirm('Remove retained #10 moisture if found?')) {

        setIsValidated(prevState => ({...prevState,
          retain10TareNo: '',
          retain10TareWt: null,
          retain10WetSoilAndTareWt: null,
          retain10DrySoilAndTareWt: null
        }))

      } else {
        event.target.checked = true

        // the below code isnt necessary i believe. if cb is set to true, it doesnt change.
        // Therefore onChange doesnt trigger. However this is safer for now

        setIsValidated(prevState => ({...prevState,
          retain10MoistureTaken: true
        }))
      }
    }

  }

  const clearMoistureInputsPass10 = (event) => {
    let value = event.target.checked

    if (!value) {
      if (window.confirm('Remove passing #10 moisture if found?')) {

        setIsValidated(prevState => ({...prevState,
          pass10TareNo: '',
          pass10TareWt: null,
          pass10WetSoilAndTareWt: null,
          pass10DrySoilAndTareWt: null
        }))

      } else {
        event.target.checked = true

        // the below code isnt necessary i believe. if cb is set to true, it doesnt change.
        // Therefore onChange doesnt trigger. However this is safer for now

        setIsValidated(prevState => ({...prevState,
          pass10MoistureTaken: true
        }))
      }
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let wtOfWater = Number(isValidated.retain10WetSoilAndTareWt) - Number(isValidated.retain10DrySoilAndTareWt)
  let wtOfDrySoil = Number(isValidated.retain10DrySoilAndTareWt) - Number(isValidated.retain10TareWt)
  let retain10MoistureContent = isValidated.retain10MoistureTaken ? Math.round(10*wtOfWater/wtOfDrySoil*100)/10 : 0

  wtOfWater = Number(isValidated.pass10WetSoilAndTareWt) - Number(isValidated.pass10DrySoilAndTareWt)
  wtOfDrySoil = Number(isValidated.pass10DrySoilAndTareWt) - Number(isValidated.pass10TareWt)
  let pass10MoistureContent = isValidated.pass10MoistureTaken ? Math.round(10*wtOfWater/wtOfDrySoil*100)/10 : 0

  //console.log('retainMC: ' + retain10MoistureContent)
  //console.log('passMC: ' + pass10MoistureContent)

  let waterFactorUpper = 1/(1 + (retain10MoistureContent/100))
  let waterFactorLower = 1/(1 + (pass10MoistureContent/100))

  //console.log('waterFactorUpper: ' + waterFactorUpper)
  //console.log('waterFactorLower: ' + waterFactorLower)

  let totalWt = isValidated.retain10TotalWt * waterFactorUpper
  let totalWtLower = isValidated.pass10TotalWt * waterFactorLower

  //console.log('totalWt: ' + totalWt)
  //console.log('totalWtLower: ' + totalWtLower)

  let inc12 = isValidated.s12 === '' || isValidated.s12 === null ? 0 : isValidated.s12 * waterFactorUpper
  let inc6 = isValidated.s6 === '' || isValidated.s6 === null ? 0 : isValidated.s6 * waterFactorUpper
  let inc3 = isValidated.s3 === '' || isValidated.s3 === null ? 0 : isValidated.s3 * waterFactorUpper
  let inc2 = isValidated.s2 === '' || isValidated.s2 === null ? 0 : isValidated.s2 * waterFactorUpper
  let inc3_2 = isValidated.s3_2 === '' || isValidated.s3_2 === null ? 0 : isValidated.s3_2 * waterFactorUpper
  let inc1 = isValidated.s1 === '' || isValidated.s1 === null ? 0 : isValidated.s1 * waterFactorUpper
  let inc3_4 = isValidated.s3_4 === '' || isValidated.s3_4 === null ? 0 : isValidated.s3_4 * waterFactorUpper
  let inc1_2 = isValidated.s1_2 === '' || isValidated.s1_2 === null ? 0 : isValidated.s1_2 * waterFactorUpper
  let inc3_8 = isValidated.s3_8 === '' || isValidated.s3_8 === null ? 0 : isValidated.s3_8 * waterFactorUpper
  let inc4 = isValidated.s4 === '' || isValidated.s4 === null ? 0 : isValidated.s4 * waterFactorUpper
  let inc8 = isValidated.s8 === '' || isValidated.s8 === null ? 0 : isValidated.s8 * waterFactorLower
  let inc10 = isValidated.s10 === '' || isValidated.s10 === null ? 0 : isValidated.s10 * waterFactorLower
  let inc16 = isValidated.s16 === '' || isValidated.s16 === null ? 0 : isValidated.s16 * waterFactorLower
  let inc20 = isValidated.s20 === '' || isValidated.s20 === null ? 0 : isValidated.s20 * waterFactorLower
  let inc30 = isValidated.s30 === '' || isValidated.s30 === null ? 0 : isValidated.s30 * waterFactorLower
  let inc40 = isValidated.s40 === '' || isValidated.s40 === null ? 0 : isValidated.s40 * waterFactorLower
  let inc50 = isValidated.s50 === '' || isValidated.s50 === null ? 0 : isValidated.s50 * waterFactorLower
  let inc60 = isValidated.s60 === '' || isValidated.s60 === null ? 0 : isValidated.s60 * waterFactorLower
  let inc100 = isValidated.s100 === '' || isValidated.s100 === null ? 0 : isValidated.s100 * waterFactorLower
  let inc140 = isValidated.s140 === '' || isValidated.s140 === null ? 0 : isValidated.s140 * waterFactorLower
  let inc200 = isValidated.s200 === '' || isValidated.s200 === null ? 0 : isValidated.s200 * waterFactorLower
  let incPan = isValidated.pan === '' || isValidated.pan === null ? 0 : isValidated.pan * waterFactorLower

  //let cum8L, cum16L, cum20L, cum30L, cum40L, cum50L, cum60L, cum100L, cum140L, cum200L, cumPanL
  let cum12, cum6, cum3, cum2, cum3_2, cum1, cum3_4, cum1_2, cum3_8, cum4, cum8, cum10, cum16, cum20, cum30, cum40, cum50, cum60, cum100, cum140, cum200, cumPan
  let pass12, pass6, pass3, pass2, pass3_2, pass1, pass3_4, pass1_2, pass3_8, pass4, pass8, pass10, pass16, pass20, pass30, pass40, pass50, pass60, pass100, pass140, pass200
  let retain12, retain6, retain3, retain2, retain3_2, retain1, retain3_4, retain1_2, retain3_8, retain4, retain8, retain10, retain16, retain20, retain30, retain40, retain50, retain60, retain100, retain140, retain200, retainPan

  retain12 = Math.round(10*inc12 / totalWt * 100)/10
  cum12 = Math.round(10*totalWt * retain12/100)/10
  pass12 = Math.round(10*(100 - retain12))/10

  retain6 = retain12 + Math.round(10*inc6 / totalWt * 100)/10
  cum6 = Math.round(10*totalWt * retain6/100)/10
  pass6 = Math.round(10*(100 - retain6))/10

  retain3 = retain6 + Math.round(10*inc3 / totalWt * 100)/10
  cum3 = Math.round(10*totalWt * retain3/100)/10
  pass3 = Math.round(10*(100 - retain3))/10

  retain2 = retain3 + Math.round(10*inc2 / totalWt * 100)/10
  cum2 = Math.round(10*totalWt * retain2/100)/10
  pass2 = Math.round(10*(100 - retain2))/10

  retain3_2 = retain2 + Math.round(10*inc3_2 / totalWt * 100)/10
  cum3_2 = Math.round(10*totalWt * retain3_2/100)/10
  pass3_2 = Math.round(10*(100 - retain3_2))/10

  retain1 = retain3_2 + Math.round(10*inc1 / totalWt * 100)/10
  cum1 = Math.round(10*totalWt * retain1/100)/10
  pass1 = Math.round(10*(100 - retain1))/10

  retain3_4 = retain1 + Math.round(10*inc3_4 / totalWt * 100)/10
  cum3_4 = Math.round(10*totalWt * retain3_4/100)/10
  pass3_4 = Math.round(10*(100 - retain3_4))/10

  retain1_2 = retain3_4 + Math.round(10*inc1_2 / totalWt * 100)/10
  cum1_2 = Math.round(10*totalWt * retain1_2/100)/10
  pass1_2 = Math.round(10*(100 - retain1_2))/10

  retain3_8 = retain1_2 + Math.round(10*inc3_8 / totalWt * 100)/10
  cum3_8 = Math.round(10*totalWt * retain3_8/100)/10
  pass3_8 = Math.round(10*(100 - retain3_8))/10

  retain4 = retain3_8 + Math.round(10*inc4 / totalWt * 100)/10
  cum4 = Math.round(10*totalWt * retain4/100)/10
  pass4 = Math.round(10*(100 - retain4))/10

  if (isValidated.method === 'D422') {

    retain10 = retain4 + Math.round(10*inc10 / totalWt * 100)/10
    cum10 = Math.round(10*totalWt * retain10/100)/10
    pass10 = 100 - retain10


    pass20 = Math.round(10*pass10 * (totalWtLower - inc20) / totalWtLower)/10
    retain20 = 100 - pass20
    cum20 = Math.round(10*totalWt * retain20/100)/10

    retain40 = Math.round(10*(retain20 + pass10 / 100 * inc40 / totalWtLower * 100))/10
    cum40 = Math.round(10*totalWt * retain40/100)/10
    pass40 = Math.round(10*(100 - retain40))/10

    retain60 = Math.round(10*(retain40 + pass10 / 100 * inc60 / totalWtLower * 100))/10
    cum60 = Math.round(10*totalWt * retain60/100)/10
    pass60 = Math.round(10*(100 - retain60))/10

    retain140 = Math.round(10*(retain60 + pass10 / 100 * inc140 / totalWtLower * 100))/10
    cum140 = Math.round(10*totalWt * retain140/100)/10
    pass140 = Math.round(10*(100 - retain140))/10

    retain200 = Math.round(10*(retain140 + pass10 / 100 * inc200 / totalWtLower * 100))/10
    cum200 = Math.round(10*totalWt * retain200/100)/10
    pass200 = Math.round(10*(100 - retain200))/10

    retainPan = Math.round(10*(retain200 + pass10 / 100 * incPan / totalWtLower * 100))/10
    cumPan = Math.round(10*totalWt * retainPan/100)/10

  } else if (isValidated.method === 'C136') {

    pass8 = Math.round(10*(pass4 * (totalWtLower - inc8) / totalWtLower))/10
    retain8 = Math.round(10*(100 - pass8))/10
    cum8 = Math.round(10*totalWt * retain8/100)/10

    // console.log(`pass4: ${pass4}`)
    // console.log(`pass8: ${pass8}`)
    // console.log(`pass8wt: ${totalWtLower - inc8}`)
    // console.log(`totalWtLower: ${totalWtLower}`)
    // console.log(`waterFactorLower: ${waterFactorLower}`)

    retain16 = Math.round(10*(retain8 + pass4 / 100 * inc16 / totalWtLower * 100))/10
    cum16 = Math.round(10*totalWt * retain16/100)/10
    pass16 = Math.round(10*(100 - retain16))/10

    retain30 = Math.round(10*(retain16 + pass4 / 100 * inc30 / totalWtLower * 100))/10
    cum30 = Math.round(10*totalWt * retain30/100)/10
    pass30 = Math.round(10*(100 - retain30))/10

    retain50 = Math.round(10*(retain30 + pass4 / 100 * inc50 / totalWtLower * 100))/10
    cum50 = Math.round(10*totalWt * retain50/100)/10
    pass50 = Math.round(10*(100 - retain50))/10

    retain100 = Math.round(10*(retain50 + pass4 / 100 * inc100 / totalWtLower * 100))/10
    cum100 = Math.round(10*totalWt * retain100/100)/10
    pass100 = Math.round(10*(100 - retain100))/10

    retain200 = Math.round(10*(retain100 + pass4 / 100 * inc200 / totalWtLower * 100))/10
    cum200 = Math.round(10*totalWt * retain200/100)/10
    pass200 = Math.round(10*(100 - retain200))/10

    retainPan = Math.round(10*(retain200 + pass4 / 100 * incPan / totalWtLower * 100))/10
    cumPan = Math.round(10*totalWt * retainPan/100)/10

  }

  const buildGraph = () => {
    let canvas, ctx, chart, percentConsol, loadAccum
    let backgroundColor = '#d3d3d3'
    let borderColor = '#000000'
    let pointBackgroundColor = []
    let pointBorderColor = []

    let graphData =
    isValidated.method === 'D422' ? [
      {x: 305, y: pass12},
      {x: 152, y: pass6},
      {x: 75, y: pass3},
      {x: 50, y: pass2},
      {x: 37.5, y: pass3_2},
      {x: 25, y: pass1},
      {x: 19, y: pass3_4},
      {x: 12.5, y: pass1_2},
      {x: 9.5, y: pass3_8},
      {x: 4.75, y: pass4},
      {x: 2, y: pass10},
      {x: 0.850, y: pass20},
      {x: 0.425, y: pass40},
      {x: 0.250, y: pass60},
      {x: 0.106, y: pass140},
      {x: 0.075, y: pass200}
    ] :
    isValidated.method === 'C136' ? [
      {x: 305, y: pass12},
      {x: 152, y: pass6},
      {x: 75, y: pass3},
      {x: 50, y: pass2},
      {x: 37.5, y: pass3_2},
      {x: 25, y: pass1},
      {x: 19, y: pass3_4},
      {x: 12.5, y: pass1_2},
      {x: 9.5, y: pass3_8},
      {x: 4.75, y: pass4},
      {x: 2.38, y: pass8},
      {x: 1.188, y: pass16},
      {x: 0.667, y: pass30},
      {x: 0.400, y: pass50},
      {x: 0.200, y: pass100},
      {x: 0.075, y: pass200}
    ] : []

    canvas = canvasRef.current
    ctx = canvas.getContext('2d')

    chart = new window.Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [{
          label: data.phaseid === 'P' ? data.location + ' @ '+ data.depth : 'SN: ' + data.sampleno,
          yAxisID: 'pass',
          data: graphData,
          borderColor: 'black',
          borderWidth: 1,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBorderColor,
          pointRadius: 5,
          pointHoverRadius: 5,
          fill: false,
          tension: 0,
          showLine: true
        }]
      },
      options: {
        maintainAspectRatio: true,
        legend: {display: true},
        scales: {
          xAxes: [{
            id: 'xPass',
            type: 'logarithmic',
            position: 'bottom',
            gridLines: {
               color: 'black'
            },
            ticks: {
              reverse: true,
              min: 0.0001,
              max: 200,
              callback: function (value, index, values) {
                return Number(value.toString()) //pass tick values as a string into Number function
              }
            },
            afterBuildTicks: function (chartObj) { //Build ticks labelling as per your need
              chartObj.ticks = [100, 10, 1, 0.1, 0.01, 0.001, 0.0001]
              // chartObj.ticks =
              // [
              //   200, 100, 90, 80, 70, 60, 50, 40, 30, 20, 10, 9, 8, 7, 6, 5, 4, 3, 2, 1,
              //   0.9, 0.8, 0.7, 0.6, 0.5, 0.4, 0.3, 0.2, 0.1,
              //   0.09, 0.08, 0.07, 0.06, 0.05, 0.04, 0.03, 0.02, 0.01,
              //   0.009, 0.008, 0.007, 0.006, 0.005, 0.004, 0.003, 0.002, 0.001,
              //   0.0009, 0.0008, 0.0007, 0.0006, 0.0005, 0.0004, 0.0003, 0.0002, 0.0001
              // ]
              // chartObj.ticks.push(100)
              // chartObj.ticks.push(10)
              // chartObj.ticks.push(1)
              // chartObj.ticks.push(0.1)
              // chartObj.ticks.push(0.01)
              // chartObj.ticks.push(0.001)
              // chartObj.ticks.push(0.0001)
            }
            // ,
            // afterTickToLabelConversion: function (chartObj) { //Build ticks labelling as per your need
            //   //let arr = [100, 10, 1, 0.1, 0.01, 0.001, 0.0001]
            //   let arr = [
            //     '', 100, '', '', '', '', '', '', '', '', 10, '', '', '', '', '', '', '', '', 1,
            //     '', '', '', '', '', '', '', '', 0.1,
            //     '', '', '', '', '', '', '', '', 0.01,
            //     '', '', '', '', '', '', '', '', 0.001,
            //     '', '', '', '', '', '', '', '', 0.0001
            //   ]
            //
            //   for(let i in chartObj.ticks){
            //     chartObj.ticks[i] = arr[i];
            //   }
            //
            //   // chartObj.ticks.map((tick, i) => {
            //   //   chartObj.ticks[i] = arr[i]
            //   // }
            //
            // }
          },
          {
            id: 'other',
            type: 'logarithmic',
            position: 'top',
            gridLines: {
               color: 'dodgerblue'
            },
            ticks: {
              reverse: true,
              min: 0.0001,
              max: 200,
              callback: function (value, index, values) {
                return Number(value.toString()) //pass tick values as a string into Number function
              }
            },
            afterBuildTicks: function (chartObj) { //Build ticks labelling as per your need
              chartObj.ticks = [152, 75, 50, 37.5, 25, 19, 12.5, 9.5, 4.75, 2, 0.850, 0.425, 0.250, 0.106, 0.075]

            },
            afterTickToLabelConversion: function (chartObj) { //Build ticks labelling as per your need

              let arr = ['6"', '3"', '2"', '1.5"', '1"', '3/4"', '1/2"', '3/8"', "4", "10", "20", "40", "60", "140", "200"]

              for(let i in chartObj.ticks){
                chartObj.ticks[i] = arr[i];
              }

              // chartObj.ticks.map((tick, i) => {
              //   chartObj.ticks[i] = arr[i]
              // }

            }
          }],
          yAxes: [{
            id: 'pass',
            type: 'linear',
            position: 'left',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              reverse: false,
              min: 0,
              max: 100
            }
          },
          {
            id: 'retain',
            type: 'linear',
            position: 'right',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              reverse: true,
              min: 0,
              max: 100
            }
          }]
        }
      }
    })
  }

  useEffect(() => {
    buildGraph()
  })

  let retain10MoistureInputs = isValidated.retain10MoistureTaken ?
  (
    <>
      <label>Tare No
        <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='retain10TareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10TareNo} required />
      </label><br />

      <label>Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='retain10TareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10TareWt} required />
      </label><br />

      <label>Wet Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='retain10WetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10WetSoilAndTareWt} required />
      </label><br />

      <label>Dry Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='retain10DrySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10DrySoilAndTareWt} required />
      </label><br />

      <label><b>Moisture Content (%)</b>
        <input style={{width: 75}} className='input' type="text" value={retain10MoistureContent} disabled />
      </label><br />
    </>
  ) :
  null

  let pass10MoistureInputs = isValidated.pass10MoistureTaken ?
  (
    <>
      <label>Tare No
        <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='pass10TareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10TareNo} required />
      </label><br />

      <label>Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='pass10TareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10TareWt} required />
      </label><br />

      <label>Wet Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='pass10WetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10WetSoilAndTareWt} required />
      </label><br />

      <label>Dry Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='pass10DrySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10DrySoilAndTareWt} required />
      </label><br />

      <label><b>Moisture Content (%)</b>
        <input style={{width: 75}} className='input' type="text" value={pass10MoistureContent} disabled />
      </label><br />
    </>
  ) :
  null

  return (

    <div style={{display: 'inline-block', overflowX: 'auto', width: '100%', height: '100%'}}>

      <div style={{textAlign: 'center', marginTop: 10}}>
        <h3>Total Weights</h3>
      </div>

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <label>Method
          <select className='select' pattern=".{1,}" name='method' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.method} required>
            <option value=""></option>
            <option value="D422">D422</option>
            <option value="C136">C136</option>
          </select>
        </label><br />

        {
          isValidated.method !== null && isValidated.method !== '' ?
          <>
            <label>Total Sample Weight (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='retain10TotalWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10TotalWt} required />
            </label><br />

            <label>
              {
                isValidated.method === 'D422' ?
                'Total Wt Passing #10 (g)' :
                isValidated.method === 'C136' ?
                'Total Wt Passing #4 (g)' : 'Error: method?'
              }
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='pass10TotalWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10TotalWt} required />
            </label><br />

          </> : null

        }

      </div>

      <div style={{textAlign: 'center', marginTop: 10}}>
        <h3>Moistures</h3>
      </div>

      <div style={{display: 'flex', overflowX: 'auto', justifyContent: 'center'}}>

        <div style={{flex: '0 1 auto'}}>

          {isValidated.retain10MoistureTaken || isValidated.pass10MoistureTaken ?

            <div style={{textAlign: 'right'}}>
              <label className='label'></label><br />
              <label className='label'>Tare No</label><br />
              <label className='label'>Tare Wt (g)</label><br />
              <label className='label'>Wet Soil and Tare Wt (g)</label><br />
              <label className='label'>Dry Soil and Tare Wt (g)</label><br />
              <label className='label'><b>Moisture Content (%)</b></label>
            </div> : null

          }

        </div>

        <div style={{flex: '0 1 auto'}}>

          <label style={{width: 80, margin: 10, display: 'inline-block'}}>Retain {isValidated.method === 'D422' ? '#10' : isValidated.method === 'C136' ? '#4' : '..method?'}</label>
          <input style={{display: 'inline-block', margin: 10, height: 20, width: 20}} type='checkbox' pattern="{1,}" name='retain10MoistureTaken' onInput={validate} onChange={props.changedSample} onClick={clearMoistureInputsRetain10} defaultChecked={isValidated.retain10MoistureTaken} /><br />
          {isValidated.retain10MoistureTaken ?
            <>
              <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='retain10TareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10TareNo} required /><br />
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='retain10TareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10TareWt} required /><br />
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='retain10WetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10WetSoilAndTareWt} required /><br />
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='retain10DrySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retain10DrySoilAndTareWt} required /><br />
              <input style={{width: 75}} className='input' type="text" value={retain10MoistureContent} disabled />
            </> : null
          }

        </div>

        <div style={{flex: '0 1 auto'}}>

          <label style={{width: 80, margin: 10, display: 'inline-block'}}>Pass {isValidated.method === 'D422' ? '#10' : isValidated.method === 'C136' ? '#4' : '..method?'}</label>
          <input style={{display: 'inline-block', margin: 10, height: 20, width: 20}} type='checkbox' pattern="{1,}" name='pass10MoistureTaken' onInput={validate} onChange={props.changedSample} onClick={clearMoistureInputsPass10} defaultChecked={isValidated.pass10MoistureTaken} /><br />
          {isValidated.pass10MoistureTaken ?
            <>
              <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='pass10TareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10TareNo} required /><br />
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='pass10TareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10TareWt} required /><br />
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='pass10WetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10WetSoilAndTareWt} required /><br />
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='pass10DrySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pass10DrySoilAndTareWt} required /><br />
              <input style={{width: 75}} className='input' type="text" value={pass10MoistureContent} disabled />
            </> : null
          }
        </div>

      </div>

      <div style={{display: 'flex', overflowX: 'auto', width: '100%'}}>
        <div style={{margin: 10, flex: '0 0 auto'}}>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Readings</h3>
          </div>

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            {
              isValidated.method !== null && isValidated.method !== '' ?
              <>
                <div style={{position: 'sticky', top: 35, backgroundColor: 'white', width: '100%'}}>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Incre. (g)</label>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Cumm. (g)</label>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Pass (%)</label>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Retain (%)</label>
                </div>

                <label>12"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s12' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s12} required />
                  <input style={{width: 75}} className='input' type="text" value={cum12} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass12} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain12} disabled />
                </label><br />

                <label>6"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s6' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s6} required />
                  <input style={{width: 75}} className='input' type="text" value={cum6} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass6} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain6} disabled />
                </label><br />

                <label>3"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s3' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s3} required />
                  <input style={{width: 75}} className='input' type="text" value={cum3} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass3} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain3} disabled />
                </label><br />

                <label>2"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s2} required />
                  <input style={{width: 75}} className='input' type="text" value={cum2} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass2} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain2} disabled />
                </label><br />

                <label>1.5"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s3_2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s3_2} required />
                  <input style={{width: 75}} className='input' type="text" value={cum3_2} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass3_2} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain3_2} disabled />
                </label><br />

                <label>1"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s1} required />
                  <input style={{width: 75}} className='input' type="text" value={cum1} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass1} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain1} disabled />
                </label><br />

                <label>3/4"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s3_4' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s3_4} required />
                  <input style={{width: 75}} className='input' type="text" value={cum3_4} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass3_4} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain3_4} disabled />
                </label><br />

                <label>1/2"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s1_2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s1_2} required />
                  <input style={{width: 75}} className='input' type="text" value={cum1_2} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass1_2} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain1_2} disabled />
                </label><br />

                <label>3/8"
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s3_8' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s3_8} required />
                  <input style={{width: 75}} className='input' type="text" value={cum3_8} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass3_8} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain3_8} disabled />
                </label><br />

                <label>#4
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s4' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s4} required />
                  <input style={{width: 75}} className='input' type="text" value={cum4} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass4} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain4} disabled />
                </label><br />

                {
                  isValidated.method === 'C136' ?
                  <>
                    <label>#8
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s8' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s8} required />
                      <input style={{width: 75}} className='input' type="text" value={cum8} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass8} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain8} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'D422' ?
                  <>
                    <label>#10
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s10' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s10} required />
                      <input style={{width: 75}} className='input' type="text" value={cum10} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass10} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain10} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'C136' ?
                  <>
                    <label>#16
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s16' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s16} required />
                      <input style={{width: 75}} className='input' type="text" value={cum16} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass16} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain16} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'D422' ?
                  <>
                    <label>#20
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s20' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s20} required />
                      <input style={{width: 75}} className='input' type="text" value={cum20} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass20} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain20} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'C136' ?
                  <>
                    <label>#30
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s30} required />
                      <input style={{width: 75}} className='input' type="text" value={cum30} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass30} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain30} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'D422' ?
                  <>
                    <label>#40
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s40' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s40} required />
                      <input style={{width: 75}} className='input' type="text" value={cum40} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass40} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain40} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'C136' ?
                  <>
                    <label>#50
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s50' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s50} required />
                      <input style={{width: 75}} className='input' type="text" value={cum50} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass50} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain50} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'D422' ?
                  <>
                    <label>#60
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s60' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s60} required />
                      <input style={{width: 75}} className='input' type="text" value={cum60} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass60} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain60} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'C136' ?
                  <>
                    <label>#100
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s100' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s100} required />
                      <input style={{width: 75}} className='input' type="text" value={cum100} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass100} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain100} disabled />
                    </label><br />
                  </> : null
                }

                {
                  isValidated.method === 'D422' ?
                  <>
                    <label>#140
                      <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s140' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s140} required />
                      <input style={{width: 75}} className='input' type="text" value={cum140} disabled />
                      <input style={{width: 75}} className='input' type="text" value={pass140} disabled />
                      <input style={{width: 75}} className='input' type="text" value={retain140} disabled />
                    </label><br />
                  </> : null
                }

                <label>#200
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='s200' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.s200} required />
                  <input style={{width: 75}} className='input' type="text" value={cum200} disabled />
                  <input style={{width: 75}} className='input' type="text" value={pass200} disabled />
                  <input style={{width: 75}} className='input' type="text" value={retain200} disabled />
                </label><br />

                <label>Pan
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='pan' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pan} required />
                  <input style={{width: 75}} className='input' type="text" value={cumPan} disabled />
                  <input style={{width: 75, display: 'hidden'}} className='input' type="text" disabled />
                  <input style={{width: 75}} className='input' type="text" value={retainPan} disabled />
                </label>

              </> : <p>Method?</p>

            }

          </div>

        </div>

        <div style={{margin: 10, flex: '1 1 auto'}}>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Graph</h3>
          </div>

          <div>
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>

      </div>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

        <SentOut data={data} onInput={validate} onChange={props.changedSample} />

        <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

        {editSampleIcon}

      </div>

    </div>

  )

}

export default Sa
