import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Loading from 'components/utils/Loading'
import Selects from 'components/utils/Selects'
import Modal from 'components/utils/Modal'

import { formatDateYMD, formatDateTime, getLocation } from 'scripts/common'
import 'styles/container.css'

const Add = (props) => {

  const [fetchedJobs, setFetchedJobs] = useState([])
  const [fetchedData, setFetchedData] = useState([])

  const [selectJobnumbers, setSelectJobnumbers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  //const [isAddSample, setIsAddSample] = useState(false)

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    plan: false,
    google: false,
    locate: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    id: null,
    sampleDate: '',
    sampleNo: null,
    location: '',
    represents: '',
    purpose: '',
    sampleType: '',
    depth: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    id: null,
    sampleDate: '',
    sampleNo: null,
    location: '',
    represents: '',
    purpose: '',
    sampleType: '',
    depth: ''
  })

  const fetchData = () => {
    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId

    if (jobnumber !== null && jobnumber !== '' && phase !== 'All') {

      fetch('/api/fetchAddJobs', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedJobs(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

      fetch('/api/fetchAddSamples', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedData(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

    fetch('/api/selectAddJobs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setSelectJobnumbers(result)
        setIsLoading(false)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

    if (phase === 'C') {

      fetch('/api/findNextSampleNo', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          console.log('samplno: ' + JSON.stringify(result))
          //setNextSampleNo(result[0].sampleno === null ? 1 : result[0].sampleno + 1)
          setIsValidated(prevState => ({
            ...prevState, sampleno: result[0].sampleno === null ? 1 : result[0].sampleno + 1
          }))
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // const selectChange = () => {
  //
  //   setIsAddSample(false)
  //   setIsValidated(prevState => ({
  //     ...prevState,
  //     labid: '',
  //     sampledate: '',
  //     sampletype: '',
  //     location: '',
  //     depth: '',
  //     sampleno: '',
  //     represents: '',
  //     purpose: ''
  //   }))
  //   fetchData()
  //
  // }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const addSample = () => {

    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId

    const add = () => {

      getLocation(function(latlng){

        let body = phase === 'P' ?
          JSON.stringify({
            jobnumber: jobnumber,
            phase: phase,
            sampleDate: isValidated.sampleDate,
            location: isValidated.location,
            depth: isValidated.depth,
            sampleType: isValidated.sampleType,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          }) :
          JSON.stringify({
            jobnumber: jobnumber,
            phase: phase,
            sampleDate: isValidated.sampleDate,
            sampleNo: isValidated.sampleNo,
            location: isValidated.location,
            represents: isValidated.represents,
            purpose: isValidated.purpose,
            sampleType: isValidated.sampleType,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })

        fetch('/api/addSample', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: body
        })
        .then(res=>res.json())
        .then(
          (result) => {
            fetchData()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

    if (phase === 'P') {

      if (isValidated.sampleDate === '' || isValidated.sampleDate === null) {
        alert('Please provide a sample date.')
      } else if (isValidated.location === '' || isValidated.location === null) {
        alert('Please provide a location.')
      } else if (isValidated.depth === '' || isValidated.depth === null) {
        alert('Please provide a depth.')
      } else if (isValidated.sampleType === '' || isValidated.sampleType === null) {
        alert('Please provide a sample type.')
      } else {

        add()

      }

    } else if (phase === 'C') {

      if (isValidated.sampleDate === '' || isValidated.sampleDate === null) {
        alert('Please provide a sample date.')
      } else if (isValidated.location === '' || isValidated.location === null) {
        alert('Please provide a location.')
      } else if (isValidated.purpose === '' || isValidated.purpose === null) {
        alert('Please provide a purpose.')
      } else if (isValidated.sampleType === '' || isValidated.sampleType === null) {
        alert('Please provide a sample type.')
      } else {

        add()

      }

    } else {
      alert('Error: phase not found. Contact an admin.')
    }

  }

  const editSample = () => {

    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId

    const edit = () => {

      getLocation(function(latlng){

        let body = phase === 'P' ?
          JSON.stringify({
            phase: 'P',
            id: isValidated.id,
            sampleDate: isValidated.sampleDate,
            location: isValidated.location,
            depth: isValidated.depth,
            sampleType: isValidated.sampleType,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          }) :
          JSON.stringify({
            phase: 'C',
            id: isValidated.id,
            sampleDate: isValidated.sampleDate,
            sampleNo: isValidated.sampleNo,
            location: isValidated.location,
            represents: isValidated.represents,
            purpose: isValidated.purpose,
            sampleType: isValidated.sampleType,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })

        fetch('/api/editSample', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: body
        })
        .then(res=>res.json())
        .then(
          (result) => {

            fetchData()
            setIsModal(false)
            setIsValidated(prevState => ({
              ...prevState,
              id: '',
              sampleDate: '',
              sampleType: '',
              location: '',
              depth: '',
              sampleNo: '',
              represents: '',
              purpose: ''
            }))

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

    if (phase === 'P') {

      if (isValidated.sampleDate === '' || isValidated.sampleDate === null) {
        alert('Please provide a sample date.')
      } else if (isValidated.location === '' || isValidated.location === null) {
        alert('Please provide a location.')
      } else if (isValidated.depth === '' || isValidated.depth === null) {
        alert('Please provide a depth.')
      } else if (isValidated.sampleType === '' || isValidated.sampleType === null) {
        alert('Please provide a sample type.')
      } else {

        edit()

      }

    } else if (phase === 'C') {

      if (isValidated.sampleDate === '' || isValidated.sampleDate === null) {
        alert('Please provide a sample date.')
      } else if (isValidated.location === '' || isValidated.location === null) {
        alert('Please provide a location.')
      } else if (isValidated.purpose === '' || isValidated.purpose === null) {
        alert('Please provide a purpose.')
      } else if (isValidated.sampleType === '' || isValidated.sampleType === null) {
        alert('Please provide a sample type.')
      } else {

        edit()

      }

    } else {
      alert('Error: phase not found. Contact an admin.')
    }

  }

  const deleteSample = (event) => {

    if (window.confirm('This will delete the sample permanently. Proceed?')) {

      fetch('/api/deleteSample', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: isValidated.id})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          closeModal()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      // entryby: fetchedData[i].entryby,
      // entrytime: fetchedData[i].entrytime,
      // entrylat: fetchedData[i].entrylat,
      // entrylng: fetchedData[i].entrylng,
      // modby: fetchedData[i].modby,
      // modtime: fetchedData[i].modtime,
      // modlat: fetchedData[i].modlat,
      // modlng: fetchedData[i].modlng,

      setIsValidated(prevState => ({...prevState,
        id: fetchedData[i].id,
        sampleDate: fetchedData[i].sampledate,
        sampleNo: fetchedData[i].sampleno,
        location: fetchedData[i].location,
        represents: fetchedData[i].represents,
        purpose: fetchedData[i].purpose,
        sampleType: fetchedData[i].sampletype,
        depth: fetchedData[i].depth
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let value = event.target.value

    event.target.reportValidity()
    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

  }

  let job
  if (props.filter.jobNumber !== null && props.filter.jobNumber !== '' && props.filter.phaseId !== 'All') {

    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId

    let samples = fetchedData.length === 0 ? null : fetchedData.map((sample, i) => {

      let style = {
        width: sample.phaseid === 'P' ? 50 : 100
      }

      let styleType = {
        color: 'white',
        backgroundColor:
          sample.sampletype === 'bulk' ? 'dodgerblue' :
          sample.sampletype === 'ca' ? 'orange':
          sample.sampletype === 'spt' ? 'mediumseagreen' :
          sample.sampletype === 'smbag' ? 'slateblue' :
          sample.sampletype === 'ac' ? 'gray' :
          sample.sampletype === 'shelby' ? 'violet' : 'white'
      }


      // let sampleTypes = (
      //   <td style={styleType}>{sample.sampletype}</td>
      // )

      // let editIcon = props.user.add_entry > 3 ?
      //   <Icon name='edit' id={sample.labid} onClick={selectSample} /> :
      //   props.user.add_entry > 1 && props.user.username === sample.entryby && (sample.status === 'sampled' || sample.status === 'delivered') ?
      //   <Icon name='edit' id={sample.labid} onClick={selectSample} /> :
      //   null
      //
      // let deleteIcon = props.user.add_entry > 3 ?
      //   <Icon name='delete' color='tomato' id={sample.labid} onClick={deleteSample} /> :
      //   props.user.add_entry > 2 && props.user.username === sample.entryby && (sample.status === 'sampled' || sample.status === 'delivered') ?
      //   <Icon name='delete' color='tomato' id={sample.labid} onClick={deleteSample} /> :
      //   null

      // {editIcon}
      // {deleteIcon}

      let rowList = sample.phaseid === 'P' ?
        (
          <tr key={sample.id.toString()} data-id={sample.id} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            <td style={style}>{sample.location}</td>
            <td style={style}>{sample.depth}</td>
            <td style={styleType}>{sample.sampletype}</td>
            <td>{formatDateYMD(new Date(sample.sampledate))}</td>
          </tr>
        ) :
        sample.phaseid === 'C' ?
        (
          <tr key={sample.id.toString()} data-id={sample.id} onClick={selectRow}>
            <td style={{display: 'none'}}>{i}</td>
            <td style={style}>{sample.location}</td>
            <td style={style}>{sample.represents}</td>
            <td style={style}>{sample.purpose}</td>
            <td style={styleType}>{sample.sampletype}</td>
            <td>{formatDateYMD(new Date(sample.sampledate))}</td>
          </tr>
        ) : null

      return (
        rowList
      )

    })

    let sampleTable = phase === 'P' ?
    (
      <table>
        <thead>
          <tr className='tableHeader_alt'>
            <th>Location</th>
            <th>Depth</th>
            <th>Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {samples}
        </tbody>
      </table>
    ) :
    (
      <table>
        <thead>
          <tr className='tableHeader_alt'>
            <th>SN</th>
            <th>Location</th>
            <th>Represents</th>
            <th>Purpose</th>
            <th>Type</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {samples}
        </tbody>
      </table>
    )

    let addSampleInputs =
    isModal.add && phase === 'P' ?
    (
      <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>
        <label>Sample Date
          <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : formatDateYMD(new Date(isValidated.sampleDate))} required />
        </label><br />

        <label>Location
          <input style={{width: 75}} className='input' type="text" placeholder='ie B-1' pattern="[A-Z]{1,3}-[0-9]{1,3}" name='location' onInput={validate} onChange={changedData} defaultValue={isValidated.location} required />
        </label><br />

        <label>Depth
          <input style={{width: 75}} className='input' type="text" placeholder='ie 1 or 0-5' pattern="\d{1,3}(\.\d{1})?(-\d{1,3})?(\.\d{1})?" name='depth' onInput={validate} onChange={changedData} defaultValue={isValidated.depth} required />
        </label><br />

        <label>Sample Type
          <select className="select" pattern="[a-z]{1,}" name="sampleType" onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
            <option value=""></option>
            <option value="ca">CA Rings</option>
            <option value="spt">SPT</option>
            <option value="bulk">Bulk</option>
            <option value="smbag">Small Bag</option>
            <option value="ac">Asphalt</option>
            <option value="shelby">Shelby Tube</option>
          </select>
        </label><br />

        <Icon name='check_circle' onClick={addSample} />

      </div>
    ) :
    isModal.add && phase === 'C' ?
    (
      <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>
        <label>Sample Date
          <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : formatDateYMD(new Date(isValidated.sampleDate))} required />
        </label><br />

        <label>SN
          <input style={{width: 50}} className='input' id="addSampleNo" type="text" value={isValidated.sampleNo} disabled/>
        </label><br />

        <label>Location
          <input className='input' type="text" placeholder='ie B-1' pattern="[A-Z]{1,3}-[0-9]{1,3}" name='location' onInput={validate} onChange={changedData} defaultValue={isValidated.location} required />
        </label><br />

        <label>Represents
          <input className='input' type="text" pattern="[A-Z]{1,3}-[0-9]{1,3}" name='represents' onInput={validate} onChange={changedData} defaultValue={isValidated.represents} required />
        </label><br />

        <label>Purpose
          <input className='input' type="text" pattern="[A-Z]{1,3}-[0-9]{1,3}" name='purpose' onInput={validate} onChange={changedData} defaultValue={isValidated.purpose} required />
        </label><br />

        <label>Sample Type
          <select className="select" pattern="[a-z]{1,}" name="sampleType" onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
            <option value=""></option>
            <option value="ca">CA Rings</option>
            <option value="spt">SPT</option>
            <option value="bulk">Bulk</option>
            <option value="smbag">Small Bag</option>
            <option value="ac">Asphalt</option>
            <option value="shelby">Shelby Tube</option>
          </select>
        </label><br />

        <Icon name='check_circle' onClick={addSample} />

      </div>
    ) :
    props.user.addLab > 1 ? <Icon name='add_circle' onClick={openAdd} /> : null

    job = fetchedJobs.length === 0 ? null : (
      <div>
        <div>
          <div className='jobHeader_alt'>
            <h2 style={{display: 'inline-block'}}>{fetchedJobs[0].company} - {fetchedJobs[0].city}</h2>
          </div>
          {sampleTable}
          {addSampleInputs}
        </div>
      </div>
    )

  }

  let editSampleInputs =
  props.filter.phaseId === 'P' ?
  (
    <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>
      <label>Sample Date
        <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : formatDateYMD(new Date(isValidated.sampleDate))} required />
      </label><br />

      <label>Location
        <input style={{width: 75}} className='input' type="text" placeholder='ie B-1' pattern="[A-Z]{1,3}-[0-9]{1,3}" name='location' onInput={validate} onChange={changedData} defaultValue={isValidated.location} required />
      </label><br />

      <label>Depth
        <input style={{width: 75}} className='input' type="text" placeholder='ie 1 or 0-5' pattern="\d{1,3}(\.\d{1})?(-\d{1,3})?(\.\d{1})?" name='depth' onInput={validate} onChange={changedData} defaultValue={isValidated.depth} required />
      </label><br />

      <label>Sample Type
        <select className="select" pattern="[a-z]{1,}" name="sampleType" onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
          <option value=""></option>
          <option value="ca">CA Rings</option>
          <option value="spt">SPT</option>
          <option value="bulk">Bulk</option>
          <option value="smbag">Small Bag</option>
          <option value="ac">Asphalt</option>
          <option value="shelby">Shelby Tube</option>
        </select>
      </label>

    </div>
  ) :
  props.filter.phaseId === 'C' ?
  (
    <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>
      <label>Sample Date
        <input className='input' type="date" pattern=".{1,}" name='sampleDate' onInput={validate} onChange={changedData} defaultValue={isValidated.sampleDate === '' || isValidated.sampleDate === null ? null : formatDateYMD(new Date(isValidated.sampleDate))} required />
      </label><br />

      <label>SN
        <input style={{width: 50}} className='input' type="text" onInput={validate} onChange={changedData} value={isValidated.sampleNo} disabled/>
      </label><br />

      <label>Location
        <input className='input' type="text" name="location" onInput={validate} onInput={validate} onChange={changedData} defaultValue={isValidated.location} required />
      </label><br />

      <label>Represents
        <input className='input' type="text" name="represents" onInput={validate} onInput={validate} onChange={changedData} defaultValue={isValidated.represents} />
      </label><br />

      <label>Purpose
        <input className='input' type="text" name="purpose" onInput={validate} onInput={validate} onChange={changedData} defaultValue={isValidated.purpose} required />
      </label><br />

      <label>Sample Type
        <select className="select" pattern="[a-z]{1,}" name="sampleType" onInput={validate} onInput={validate} onChange={changedData} defaultValue={isValidated.sampleType} required>
          <option value=""></option>
          <option value="ca">CA Rings</option>
          <option value="spt">SPT</option>
          <option value="bulk">Bulk</option>
          <option value="smbag">Small Bag</option>
          <option value="ac">Asphalt</option>
          <option value="shelby">Shelby Tube</option>
        </select>
      </label>

    </div>
  ) :
  <h3>Error: phase not found</h3>

  return (
    <div style={{margin: 10}}>
      {isModal.edit ? <Modal add={editSample} delete={deleteSample} content={editSampleInputs} closeModal={closeModal} width='none' height='none' /> : null}
      {isLoading ?
        <Loading /> :
        props.filter.jobNumber === null || props.filter.jobNumber === '' ? <h3>Please select a jobnumber</h3> :
        props.filter.phaseId === 'All' ? <h3>Please select a phase</h3> : props.filter.phaseId === 'C' ? <h3>Construction samples must be entered through Field App</h3> :
        job
      }
    </div>
  )
}

export default Add
