import React from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import 'styles/login.css'

function Login(props) {

  const styleLogo = {
    width: 'calc(100% - 10px)'
  }

  //<Modal content={editSampleInputs} closeModal={closeModal} /> : null

  return (

    <div className="modal">

      <div className="modal-content animate">

        <div className="fadein" style={{textAlign: 'center'}}>

          <div className="logoSize">

            <img style={styleLogo} src="logo500.png" />

          </div>

            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexWrap: 'wrap'}}>

              <div>

                <input type="text" className="input" placeholder="Enter Username" id="loginname" />

                <input type="password" className="input" placeholder="Enter Password" id="loginpw" />

              </div>

              <Icon name='check_circle' onClick={props.onClick} />

            </div>

          {props.isError ? <p>Incorrect login credentials.</p> : null}

        </div>

      </div>

    </div>

  )

}

export default Login
