import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import TestModal from 'components/utils/TestModal'
import Selects from 'components/utils/Selects'
import ViewByJob from './ViewByJob'
import ViewByDate from './ViewByDate'
import ViewByList from './ViewByList'
import SentOut from 'components/menu/SentOut'
import SearchBar from 'components/utils/SearchBar'

import { formatDateTime, getLocation } from 'scripts/common'
import 'styles/container.css'

const Todo = (props) => {

  const [fetchedDueDates, setFetchedDueDates] = useState([])
  const [fetchedJobs, setFetchedJobs] = useState([])
  const [fetchedSamples, setFetchedSamples] = useState([])
  const [selectSample, setSelectSample] = useState({id: null, field: null})

  //const [selectView, setSelectView] = useState('dueDate')

  const [isModal, setIsModal] = useState({
    add: false,
    list: false,
    sentOut: false,
    loading: true,
    tech: true
  })

  const [searchValue, setSearchValue] = useState('')

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let id = td[0].textContent
    let field = tr.getAttribute('data-field')

    if (id === '' || id === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      setSelectSample({id: id, field: field})
      openAdd()

    }

  }

  const addLab = (event) => {

    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let field = el.getAttribute('data-field')
    setSelectSample({id: id, field: field})
    openAdd()

  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openList = () => setIsModal(prevState => ({...prevState, list: true}))

  const openSentOut = () => setIsModal(prevState => ({...prevState, sentOut: true}))

  const closeAdd = () => setIsModal(prevState => ({...prevState, add: false}))

  const closeList = () => setIsModal(prevState => ({...prevState, list: false}))

  const closeSentOut = () => setIsModal(prevState => ({...prevState, sentOut: false}))

  const toggleTech = () => setIsModal(prevState => ({...prevState, tech: isModal.tech ? false : true}))

  const updateDisposed = (event) => {

    if (window.confirm('Are you sure you want to throw away this sample?')) {

      let el = event.target
      //console.log('el: ' + el.nodeName)
      let id = parseInt(el.getAttribute('data-id'))
      //console.log('dispose id: ' + id)
      getLocation(function(latlng){

        fetch('/api/todoDisposed', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            fetchData()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const fetchData = () => {
    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId

    fetch('/api/distinctDueDates', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: jobnumber, phase: phase, tech: isModal.tech})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(`result dates: ${JSON.stringify(result)}`)
        result.unshift({duedate: 'priority'})
        setFetchedDueDates(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

    fetch('/api/todoJobs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: jobnumber, phase: phase, tech: isModal.tech})
    })
    .then(res=>res.json())
    .then(
      (result) => {

        //console.log(`result Jobs: ${JSON.stringify(result)}`)
        setFetchedJobs(result[0])
        setFetchedSamples(result[1])
        setIsModal(prevState => ({...prevState, loading: false}))
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [isModal.tech, props.filter])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      console.log(`updated`)
    }, 60000) // time in milliseconds (ie 1 mins)
    return () => clearInterval(interval);
  }, [props.filter, isModal.tech])

  const permissionError = () => alert('You do not have sufficient privileges')

  //<SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

  return (
    <>
      {isModal.add ?
        <TestModal
          id={selectSample.id}
          field={selectSample.field}
          user={props.user}
          who={isModal.tech ? 'todoTech' : 'todoManager'}
          onClick={closeAdd}
          updateOrigin={fetchData}
        /> : null}
        {isModal.list ? <ViewByList filter={props.filter} fetchedJobs={fetchedJobs} fetchData={fetchData} tech={isModal.tech} user={props.user} closeModal={closeList} /> : null}
        {isModal.sentOut ? <SentOut fetchData={fetchData} user={props.user} closeModal={closeSentOut} /> : null}
        {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>
                {props.user.todo > 3 ? <Icon name='fact_check' color={isModal.tech ? 'gray' : 'dodgerblue'} onClick={toggleTech} /> : null}
                <Icon name='list_alt' onClick={openList} />
                <Icon name='delivery_dining' onClick={openSentOut} />
                <Icon name='refresh' onClick={fetchData} />
              </div>

              {fetchedSamples.length > 0 ?

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  {
                    props.filter.view === 'dueDate' ?
                    <ViewByDate
                      who={isModal.tech ? 'todoTech' : 'todoManager'}
                      fetchedDueDates={fetchedDueDates}
                      fetchedJobs={fetchedJobs}
                      fetchedSamples={fetchedSamples}
                      filter={props.filter}
                      addLab={props.user.todo > 0 ? addLab : permissionError}
                      updateDisposed={props.user.todo > 1 ? updateDisposed : permissionError}
                    /> :
                    <ViewByJob
                      who={isModal.tech ? 'todoTech' : 'todoManager'}
                      fetchedJobs={fetchedJobs}
                      fetchedSamples={fetchedSamples}
                      filter={props.filter}
                      addLab={props.user.todo > 0 ? addLab : permissionError}
                      updateDisposed={props.user.todo > 1 ? updateDisposed : permissionError}
                    />
                  }

                </div> :
                <p style={{margin: 10}}>No tests found.</p>

              }

            </div>

          </div>

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>
  )
}

export default Todo
