import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

const Job = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchText, setSearchText] = useState('')

  const fetchData = () => {

    fetch('/api/selectMenuJob', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuJob --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => setSearchText(e.target.value)

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchText('')
  }

  let listOfData = fetchedData.map(data => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let company = data.company === null ? '' : data.company
    let city = data.city === null ? '' : data.city

    if (
      searchText === '' ||
      jn.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
      company.toLowerCase().indexOf(searchText.toLowerCase()) >= 0 ||
      city.toLowerCase().indexOf(searchText.toLowerCase()) >= 0
    ) {
      return (
        <tr key={data.id.toString()} onClick={props.selectJob}>
          <td>{jn}</td>
          <td>{company}</td>
          <td>{city}</td>
        </tr>
      )
    }

  })

  let stylePhase = {
    borderRadius: 5,
    margin: 10,
    padding: 5,
    cursor: 'pointer'
  }

  let styleActivePhase = {
    borderRadius: 5,
    margin: 10,
    padding: 5,
    cursor: 'pointer',
    backgroundColor: 'dodgerblue',
    color: 'white'
  }

  let content = (
    <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

      <div style={{textAlign: 'center'}}>

        <div style={{display: 'inline-block', margin: 10}}>
          <span>Phase:</span>
          <span className='menuOptions' style={props.filter.phaseId === 'All' ? styleActivePhase : stylePhase} onClick={props.selectPhase}>All</span>
          <span style={{borderRight: '1px solid gray'}}></span>
          <span className='menuOptions'  style={props.filter.phaseId === 'P' ? styleActivePhase : stylePhase} onClick={props.selectPhase}>Invest</span>
          <span style={{borderRight: '1px solid gray'}}></span>
          <span className='menuOptions' style={props.filter.phaseId === 'C' ? styleActivePhase : stylePhase} onClick={props.selectPhase}>Const</span>
        </div>

        <div style={{display: 'inline-block', margin: 10}}>
          <span>View Tests:</span>
          <span className='menuOptions' style={props.filter.view === 'status' ? styleActivePhase : stylePhase} onClick={props.selectView}>By Status</span>
          <span style={{borderRight: '1px solid gray'}}></span>
          <span className='menuOptions' style={props.filter.view === 'dueDate' ? styleActivePhase : stylePhase} onClick={props.selectView}>By Due Date</span>
          <span style={{borderRight: '1px solid gray'}}></span>
          <span className='menuOptions'  style={props.filter.view === 'normal' ? styleActivePhase : stylePhase} onClick={props.selectView}>By Job</span>
        </div>

      </div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{textAlign: 'center'}}>
        <div style={{
          border: '2px solid tomato',
          borderRadius: 5,
          color: 'tomato',
          cursor: 'pointer',
          padding: 5,
          margin: 10
        }} onClick={props.clearJob}>Clear Job Number
        </div>
      </div>

      <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

        <div style={{textAlign: 'center'}}>

          <div style={{display: 'inline-block', margin: 10}}>

            <table>

              <thead>
                <tr>
                    <th>JN</th>
                    <th>Client</th>
                    <th>City</th>
                </tr>
              </thead>

              <tbody>
                {listOfData}
              </tbody>

            </table>

          </div>

        </div>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default Job
