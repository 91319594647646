export const showNotification = (id) => {

  if (Notification.permission !== 'granted') {
    Notification.requestPermission()
  } else {
    findNotification(id)
  }
}

export const test = () => alert('hey')

const findNotification = (id) => {

  fetch('/api/findNotification', {
    method: 'post',
    headers: {
      'Accept': 'application/json, text/plain, */*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({id: id})
  })
  .then(res=>res.json())
  .then(
    (result) => {
      //console.log('result: ' + result)

      if (result.length > 0) {
        let body
        result.map((job, i) => {
          i === 0 ?
          body = job.jobnumber + '\n' :
          body += job.jobnumber + '\n'
        })
        let notification = new Notification("New lab samples", {
           body: body
        })
      }

    },
    (error) => {
      console.log('error: ' + error)
    }
  )

}
