import React, { useState} from 'react'

import Home from 'components/manage/Home'
import Permissions from 'components/manage/Permissions'
import History from 'components/manage/History'
import Descriptions from 'components/manage/Descriptions'
import Colors from 'components/manage/Colors'
import Users from 'components/manage/Users'

const Manage = (props) => {

  return (
    <>
      {props.componentManage === 'Home' ? <Home user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Permissions' ? <Permissions user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'History' ? <History user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Colors' ? <Colors user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Descriptions' ? <Descriptions user={props.user} filter={props.filter} /> : null}
      {props.componentManage === 'Users' ? <Users user={props.user} filter={props.filter} /> : null}
    </>
  )

}

export default Manage
