import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD } from 'scripts/common'

const User = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectMenuUser', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   id: result[0].userid,
      //   time: formatDateTime(new Date())
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectUserMenu --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  let listOfData = fetchedData.map(data => {

    let name = data.fullname === null ? '' : data.fullname
    let level = data.userlevel === null ? '' : data.userlevel
    let status = data.status === null ? '?' : data.status === 1 ? 'Yes' : 'No'
    let jn = data.guestaccess === null ? '' : data.guestaccess
    let lastLogin = data.lastLogin === null || data.lastLogin === '' ? '' : formatDateYMD(data.lastLogin, true)

    if (
      searchValue === '' ||
      name.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      level.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      status.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr key={data.id.toString()} onClick={props.selectUser}>
          <td style={{display: 'none'}}>{data.username}</td>
          <td>{level}</td>
          <td>{name}</td>
          <td style={{color: status === 'Yes' ? 'dodgerblue' : 'tomato'}}>{status}</td>
          <td>{lastLogin}</td>
          <td style={{maxWidth: 200}}>{jn}</td>
        </tr>
      )
    }

  })

  let content = (
    <div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          <table>

            <thead>
              <tr>
                  <th>Level</th>
                  <th>Name</th>
                  <th>Active</th>
                  <th>Last Login</th>
                  <th>Job Access</th>
              </tr>
            </thead>

            <tbody>
              <tr onClick={props.selectUser}>
                <td style={{display: 'none'}}></td>
                <td></td>
                <td style={{color: 'tomato'}}>Clear User</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              {listOfData}
            </tbody>

          </table>

        </div>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default User
