import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateYMD, formatTime, formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Se = (props) => {

  const data = props.data[0]
  //const [isChangedSample, setIsChangedSample] = useState(false)

  const [isValidated, setIsValidated] = useState({
    startDate: data.startDate,
    start1: data.start1,
    clay1: data.clay1,
    sand1: data.sand1,
    start2: data.start2,
    clay2: data.clay2,
    sand2: data.sand2,
    start3: data.start3,
    clay3: data.clay3,
    sand3: data.sand3,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateSe', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            startDate: isValidated.startDate,
            start1: isValidated.start1,
            clay1: isValidated.clay1,
            sand1: isValidated.sand1,
            start2: isValidated.start2,
            clay2: isValidated.clay2,
            sand2: isValidated.sand2,
            start3: isValidated.start3,
            clay3: isValidated.clay3,
            sand3: isValidated.sand3
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'SE', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusse',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.startDate === '' || isValidated.startDate === null) {
        alert('Please provide a start date.')
      } else if (isValidated.start1 === '' || isValidated.start1 === null) {
        alert('Please provide a start soak time 1.')
      } else if (isValidated.clay1 === '' || isValidated.clay1 === null) {
        alert('Please provide a clay reading 1.')
      } else if (isValidated.sand1 === '' || isValidated.sand1 === null) {
        alert('Please provide a sand reading 1.')
      } else if (isValidated.start2 === '' || isValidated.start2 === null) {
        alert('Please provide a start soak time 2.')
      } else if (isValidated.clay2 === '' || isValidated.clay2 === null) {
        alert('Please provide a clay reading 2.')
      } else if (isValidated.sand2 === '' || isValidated.sand2 === null) {
        alert('Please provide a sand reading 2.')
      } else if (isValidated.start3 === '' || isValidated.start3 === null) {
        alert('Please provide a start soak time 3.')
      } else if (isValidated.clay3 === '' || isValidated.clay3 === null) {
        alert('Please provide a clay reading 3.')
      } else if (isValidated.sand3 === '' || isValidated.sand3 === null) {
        alert('Please provide a sand reading 3.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

    let d1, start1, shake1, irrigate1, read1, se1
    let d2, start2, shake2, irrigate2, read2, se2
    let d3, start3, shake3, irrigate3, read3, se3, avgSe, startDate

    //console.log('date: ' + isValidated.startDate)
    //console.log('time: ' + isValidated.start1)

    if (isValidated.startDate === null || isValidated.startDate === '') {

    }

    start1 = new Date(formatDateYMD(new Date(isValidated.startDate)) + ' ' + isValidated.start1)
    d1 = new Date(start1)

    //console.log('start1: ' + start1)
    //console.log('d1: ' + d1)

    start2 = new Date(formatDateYMD(new Date(isValidated.startDate)) + ' ' + isValidated.start2)
    d2 = new Date(start2)

    //console.log('start2: ' + start2)
    //console.log('d2: ' + d2)

    start3 = new Date(formatDateYMD(new Date(isValidated.startDate)) + ' ' + isValidated.start3)
    d3 = new Date(start3)

    //console.log('start3: ' + start3)
    //console.log('d3: ' + d3)

    shake1 = formatTime(new Date(d1.setMinutes(d1.getMinutes()+10)))
    irrigate1 = formatTime(new Date(d1.setMinutes(d1.getMinutes()+2)))
    read1 = formatTime(new Date(d1.setMinutes(d1.getMinutes()+20)))
    se1 = Math.ceil((isValidated.sand1 / isValidated.clay1) * 100)

    shake2 = formatTime(new Date(d2.setMinutes(d2.getMinutes()+10)))
    irrigate2 = formatTime(new Date(d2.setMinutes(d2.getMinutes()+2)))
    read2 = formatTime(new Date(d2.setMinutes(d2.getMinutes()+20)))
    se2 = Math.ceil((isValidated.sand2 / isValidated.clay2) * 100)

    shake3 = formatTime(new Date(d3.setMinutes(d3.getMinutes()+10)))
    irrigate3 = formatTime(new Date(d3.setMinutes(d3.getMinutes()+2)))
    read3 = formatTime(new Date(d3.setMinutes(d3.getMinutes()+20)))
    se3 = Math.ceil((isValidated.sand3 / isValidated.clay3) * 100)

    avgSe = Math.ceil((se1 + se2 + se3) / 3)

  return (

    <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>

      <div>
        <label>Start Date
          <input
          className='input'
          type="date"
          pattern="{1,}"
          name='startDate'
          onInput={validate}
          onChange={props.changedSample}
          defaultValue={
            isValidated.startDate === null || isValidated.startDate === '' ?
            null :
            formatDateYMD(new Date(isValidated.startDate))
          }
          required />
        </label>
      </div>

      {
        isValidated.startDate === null || isValidated.startDate === '' ?
        <h3>Provide a start date.</h3> :
        <>
          <div style={{textAlign: 'right'}}>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>1</label>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>2</label>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>3</label>
          </div>

          <div>
            <label>Start Soak</label>
            <input className='input' type="time" pattern="{1,}" name='start1' onInput={validate} onChange={props.changedSample} defaultValue={formatTime(start1)} required />
            <input className='input' type="time" pattern="{1,}" name='start2' onInput={validate} onChange={props.changedSample} defaultValue={formatTime(start2)} required />
            <input className='input' type="time" pattern="{1,}" name='start3' onInput={validate} onChange={props.changedSample} defaultValue={formatTime(start3)} required />
          </div>

          <div>
            <label>Start Shake</label>
            <input style={{width: 75}} className='input' type="text" value={shake1} disabled />
            <input style={{width: 75}} className='input' type="text" value={shake2} disabled />
            <input style={{width: 75}} className='input' type="text" value={shake3} disabled />
          </div>

          <div>
            <label>End Irrigate</label>
            <input style={{width: 75}} className='input' type="text" value={irrigate1} disabled />
            <input style={{width: 75}} className='input' type="text" value={irrigate2} disabled />
            <input style={{width: 75}} className='input' type="text" value={irrigate3} disabled />
          </div>

          <div>
            <label>Read</label>
            <input style={{width: 75}} className='input' type="text" value={read1} disabled />
            <input style={{width: 75}} className='input' type="text" value={read2} disabled />
            <input style={{width: 75}} className='input' type="text" value={read3} disabled />
          </div>

          <div>
            <label>Clay</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='clay1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.clay1} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='clay2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.clay2} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='clay3' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.clay3} required />
          </div>

          <div>
            <label>Sand</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='sand1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.sand1} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='sand2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.sand2} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='sand3' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.sand3} required />
          </div>

          <div>
            <label>SE</label>
            <input style={{width: 75}} className='input' type="text" value={se1} disabled />
            <input style={{width: 75}} className='input' type="text" value={se2} disabled />
            <input style={{width: 75}} className='input' type="text" value={se3} disabled />
          </div>

          <div>
            <label>Avg. SE</label>
            <input style={{width: 75}} className='input' type="text" value={avgSe} disabled />
          </div>
        </>
      }

      <div style={{textAlign: 'center', marginTop: 10}}>

        <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

        <SentOut data={data} onInput={validate} onChange={props.changedSample} />

        <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

        {editSampleIcon}

      </div>

    </div>

  )

}

export default Se
