import React from 'react'
import Icon from 'components/utils/Icon'

const LeftPanel = (props) => {

  let activeDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    backgroundColor: 'dodgerblue',
    color: 'white'
  }

  let styleDiv = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }

  let styleSpan = {
    marginRight: 10
  }

  return (
    <div style={{backgroundColor: 'whitesmoke', height: '100%', overflowY: 'auto'}}>

      <div className='hoverLink' style={props.componentManage === 'Home' ? activeDiv : styleDiv} data-id='Home' onClick={props.selectComponent}><Icon name='home' color={props.componentManage === 'Home' ? 'white' : 'gray'} /><span style={styleSpan}>Home</span></div>

      {props.user.permission > 0 ? <div className='hoverLink' style={props.componentManage === 'Permissions' ? activeDiv : styleDiv} data-id='Permissions' onClick={props.selectComponent}><Icon name='vpn_key' color={props.componentManage === 'Permissions' ? 'white' : 'gray'} /><span style={styleSpan}>Permissions</span></div> : null}

      {props.user.history > 0 ? <div className='hoverLink' style={props.componentManage === 'History' ? activeDiv : styleDiv} data-id='History' onClick={props.selectComponent}><Icon name='history' color={props.componentManage === 'History' ? 'white' : 'gray'} /><span style={styleSpan}>History</span></div> : null}

      {props.user.description > 0 ? <div className='hoverLink' style={props.componentManage === 'Descriptions' ? activeDiv : styleDiv} data-id='Descriptions' onClick={props.selectComponent}><Icon name='list' color={props.componentManage === 'Descriptions' ? 'white' : 'gray'} /><span style={styleSpan}>Descriptions</span></div> : null}

      {props.user.color > 0 ? <div className='hoverLink' style={props.componentManage === 'Colors' ? activeDiv : styleDiv} data-id='Colors' onClick={props.selectComponent}><Icon name='color_lens' color={props.componentManage === 'Colors' ? 'white' : 'gray'} /><span style={styleSpan}>Colors</span></div> : null}

      {props.user.user > 0 ? <div className='hoverLink' style={props.componentManage === 'Users' ? activeDiv : styleDiv} data-id='Users' onClick={props.selectComponent}><Icon name='people' color={props.componentManage === 'Users' ? 'white' : 'gray'} /><span style={styleSpan}>Users</span></div> : null}

    </div>
  )
}

export default LeftPanel
