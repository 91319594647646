import React, { useState } from 'react'
import Icon from 'components/utils/Icon'

const TestNotes = (props) => {

  const [isNotes, setIsNotes] = useState(false)

  const addNotes = () => setIsNotes(true)

  let notes = isNotes || (props.notes !== null && props.notes !== '') ?
    <div style={{borderBottom: '1px solid #d3d3d3', margin: 10}}>
      <label>Notes</label>
      <div>
        <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='notes' onInput={props.onInput} onChange={props.onChange}>{props.notes}</textarea>
      </div>
    </div> :
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: 5,
      borderBottom: '1px solid #d3d3d3',
      borderTop: '1px solid #d3d3d3', 
      margin: 10
    }}>
      <span><b>Add Note?</b></span><Icon name='add_comment' onClick={addNotes} />
    </div>

  return notes
}

export default TestNotes
