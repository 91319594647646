import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const So4 = (props) => {

  const data = props.data[0]
  //const [isChangedSample, setIsChangedSample] = useState(false)

  const [isValidated, setIsValidated] = useState({
    dilution0: data.dilution0,
    dilution10: data.dilution10,
    dilution100: data.dilution100,
    dilution1000: data.dilution1000,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateSo4', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            dilution0: isValidated.dilution0,
            dilution10: isValidated.dilution10,
            dilution100: isValidated.dilution100,
            dilution1000: isValidated.dilution1000
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'so4', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusso4',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.dilution0 === '' || isValidated.dilution0 === null) {
        alert('Please provide at least a dilution for none.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let dilution0 = isValidated.dilution0/1000
  let dilution10 = isValidated.dilution10/100
  let dilution100 = isValidated.dilution100/10
  let dilution1000 = isValidated.dilution1000

  let sulfateExposure

  if (isValidated.dilution1000 !== null && isValidated.dilution1000 !== '') {
    console.log(`1`)
    sulfateExposure = dilution1000 < 0.1 ? 'Negligible' : dilution1000 < 0.2 ? 'Moderate' : dilution1000 < 2 ? 'Severe' : 'Very Severe'

  } else if (isValidated.dilution100 !== null && isValidated.dilution100 !== '') {
console.log(`2`)
    sulfateExposure = dilution100 < 0.1 ? 'Negligible' : dilution100 < 0.2 ? 'Moderate' : dilution100 < 2 ? 'Severe' : 'Very Severe'

  } else if (isValidated.dilution10 !== null && isValidated.dilution10 !== '') {
console.log(`3`)
    sulfateExposure = dilution10 < 0.1 ? 'Negligible' : dilution10 < 0.2 ? 'Moderate' : dilution10 < 2 ? 'Severe' : 'Very Severe'

  } else if (isValidated.dilution0 !== null && isValidated.dilution0 !== '') {
console.log(`4`)
    sulfateExposure = dilution0 < 0.1 ? 'Negligible' : dilution0 < 0.2 ? 'Moderate' : dilution0 < 2 ? 'Severe' : 'Very Severe'

  } else if (data.so4 !== null && data.so4 !== '') {
console.log(`5`)
    sulfateExposure = data.so4 < 0.1 ? 'Negligible' : data.so4 < 0.2 ? 'Moderate' : data.so4 < 2 ? 'Severe' : 'Very Severe'

  } else {
console.log(`6`)
    sulfateExposure = ''

  }

  return (

    <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>

      <div style={{textAlign: 'center', marginTop: 10}}>
        <h3>Dilutions</h3>
      </div>

      <label>None</label>
      <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='dilution0' onInput={validate} onChange={props.changedSample} defaultValue={data.dilution0} required />
      <span> / 1000 = </span>
      <input style={{width: 75}} className='input' type="text" value={dilution0} disabled />
      <span> % </span>
      <br />

      <label>1:10</label>
      <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='dilution10' onInput={validate} onChange={props.changedSample} defaultValue={data.dilution10} required />
      <span> / 100 = </span>
      <input style={{width: 75}} className='input' type="text" value={dilution10} disabled />
      <span> % </span>
      <br />

      <label>1:100</label>
      <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='dilution100' onInput={validate} onChange={props.changedSample} defaultValue={data.dilution100} required />
      <span> / 10 = </span>
      <input style={{width: 75}} className='input' type="text" value={dilution100} disabled />
      <span> % </span>
      <br />

      <label>1:1000</label>
      <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}" name='dilution1000' onInput={validate} onChange={props.changedSample} defaultValue={data.dilution1000} required />
      <span> / 1 = </span>
      <input style={{width: 75}} className='input' type="text" value={dilution1000} disabled />
      <span> % </span>
      <br />

      {(isValidated.dilution0 === null || isValidated.dilution0 === '') && data.so4 !== null && data.so4 !== '' ?
        <div>
          <label><b>Sulfate Reading</b>
            <input className='input' type="text" value={data.so4} disabled />
          </label>
          <div><small>Prior to app input change</small></div>
        </div> : null
      }

      <label><b>Sulfate Exposure</b>
        <input className='input' type="text" value={sulfateExposure} disabled />
      </label>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

        <SentOut data={data} onInput={validate} onChange={props.changedSample} />

        <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

        {editSampleIcon}

      </div>

    </div>

  )

}

export default So4
