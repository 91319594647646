import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateYMD, formatTime, formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Ds = (props) => {

  const data = props.data[0]
  //const [isChangedSample, setIsChangedSample] = useState(false)
  const canvasRef = useRef(null)

  const [isValidated, setIsValidated] = useState({
    totalWt: data.totalwt,
    startDate: data.startdate,
    startTime: data.starttime,
    wetSoilAndRingWt1: data.wetsoilandringwt1,
    wetSoilAndRingWt2: data.wetsoilandringwt2,
    wetSoilAndRingWt3: data.wetsoilandringwt3,
    ringWt1: data.ringwt1,
    ringWt2: data.ringwt2,
    ringWt3: data.ringwt3,
    afterSoakingWetSoilAndRingWt1: data.aftersoakingwetsoilandringwt1,
    afterSoakingWetSoilAndRingWt2: data.aftersoakingwetsoilandringwt2,
    afterSoakingWetSoilAndRingWt3: data.aftersoakingwetsoilandringwt3,
    wetSoilAndTareWtBefore: data.wetsoilandtarewtbefore,
    drySoilAndTareWtBefore: data.drysoilandtarewtbefore,
    tareNo: data.tareNo,
    tareWtBefore: data.tarewtbefore,
    load1VertDis0: data.load1VertDis0,
    load1VertDis2: data.load1VertDis2,
    load1VertDis5: data.load1VertDis5,
    load1VertDis15: data.load1VertDis15,
    load1VertDis30: data.load1VertDis30,
    load1VertDis60: data.load1VertDis60,
    load2VertDis0: data.load2VertDis0,
    load2VertDis2: data.load2VertDis2,
    load2VertDis5: data.load2VertDis5,
    load2VertDis15: data.load2VertDis15,
    load2VertDis30: data.load2VertDis30,
    load2VertDis60: data.load2VertDis60,
    load2VertDis120: data.load2VertDis120,
    load3VertDis0: data.load3VertDis0,
    load3VertDis2: data.load3VertDis2,
    load3VertDis5: data.load3VertDis5,
    load3VertDis15: data.load3VertDis15,
    load3VertDis30: data.load3VertDis30,
    load3VertDis60: data.load3VertDis60,
    load3VertDis120: data.load3VertDis120,
    load3VertDis180: data.load3VertDis180,
    status: data.status,
    notes: data.notes,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateDs', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            totalWt: isValidated.totalWt,
            startDate: isValidated.startDate,
            startTime: isValidated.startTime,
            wetSoilAndRingWt1: isValidated.wetSoilAndRingWt1,
            wetSoilAndRingWt2: isValidated.wetSoilAndRingWt2,
            wetSoilAndRingWt3: isValidated.wetSoilAndRingWt3,
            ringWt1: isValidated.ringWt1,
            ringWt2: isValidated.ringWt2,
            ringWt3: isValidated.ringWt3,
            afterSoakingWetSoilAndRingWt1: isValidated.afterSoakingWetSoilAndRingWt1,
            afterSoakingWetSoilAndRingWt2: isValidated.afterSoakingWetSoilAndRingWt2,
            afterSoakingWetSoilAndRingWt3: isValidated.afterSoakingWetSoilAndRingWt3,
            wetSoilAndTareWtBefore: isValidated.wetSoilAndTareWtBefore,
            drySoilAndTareWtBefore: isValidated.drySoilAndTareWtBefore,
            tareNo: isValidated.tareNo,
            tareWtBefore: isValidated.tareWtBefore,
            load1VertDis0: isValidated.load1VertDis0,
            load1VertDis2: isValidated.load1VertDis2,
            load1VertDis5: isValidated.load1VertDis5,
            load1VertDis15: isValidated.load1VertDis15,
            load1VertDis30: isValidated.load1VertDis30,
            load1VertDis60: isValidated.load1VertDis60,
            load2VertDis0: isValidated.load2VertDis0,
            load2VertDis2: isValidated.load2VertDis2,
            load2VertDis5: isValidated.load2VertDis5,
            load2VertDis15: isValidated.load2VertDis15,
            load2VertDis30: isValidated.load2VertDis30,
            load2VertDis60: isValidated.load2VertDis60,
            load2VertDis120: isValidated.load2VertDis120,
            load3VertDis0: isValidated.load3VertDis0,
            load3VertDis2: isValidated.load3VertDis2,
            load3VertDis5: isValidated.load3VertDis5,
            load3VertDis15: isValidated.load3VertDis15,
            load3VertDis30: isValidated.load3VertDis30,
            load3VertDis60: isValidated.load3VertDis60,
            load3VertDis120: isValidated.load3VertDis120,
            load3VertDis180: isValidated.load3VertDis180
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'ds', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusds',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')

      } else if (color === '' || color === null) {
        alert('Please provide a color.')

      } else if (isValidated.startDate === '' || isValidated.startDate === null) {
        alert('Please provide a start date.')

      } else if (isValidated.startTime === '' || isValidated.startTime === null) {
        alert('Please provide a start time.')

      } else if ((data.dsload1 !== '' && data.dsload1 !== null) && (isValidated.wetSoilAndRingWt1 === '' || isValidated.wetSoilAndRingWt1 === null)) {
        alert('Please provide a before test wet soil and ring weight.')

      } else if ((data.dsload2 !== '' && data.dsload2 !== null) && (isValidated.wetSoilAndRingWt2 === '' || isValidated.wetSoilAndRingWt2 === null)) {
        alert('Please provide a before test wet soil and ring weight.')

      } else if ((data.dsload3 !== '' && data.dsload3 !== null) && (isValidated.wetSoilAndRingWt3 === '' || isValidated.wetSoilAndRingWt3 === null)) {
        alert('Please provide a before test wet soil and ring weight.')

      } else if ((data.dsload1 !== '' && data.dsload1 !== null) && (isValidated.ringWt1 === '' || isValidated.ringWt1 === null)) {
        alert('Please provide a before test ring weight.')

      } else if ((data.dsload2 !== '' && data.dsload2 !== null) && (isValidated.ringWt2 === '' || isValidated.ringWt2 === null)) {
        alert('Please provide a before test ring weight.')

      } else if ((data.dsload3 !== '' && data.dsload3 !== null) && (isValidated.ringWt3 === '' || isValidated.ringWt3 === null)) {
        alert('Please provide a before test ring weight.')

      } else if ((data.dsload3 !== '' && data.dsload3 !== null) && (isValidated.afterSoakingWetSoilAndRingWt1 === '' || isValidated.afterSoakingWetSoilAndRingWt1 === null)) {
        alert('Please provide a before test ring weight.')

      } else if ((data.dsload3 !== '' && data.dsload3 !== null) && (isValidated.afterSoakingWetSoilAndRingWt2 === '' || isValidated.afterSoakingWetSoilAndRingWt2 === null)) {
        alert('Please provide a before test ring weight.')

      } else if ((data.dsload3 !== '' && data.dsload3 !== null) && (isValidated.afterSoakingWetSoilAndRingWt3 === '' || isValidated.afterSoakingWetSoilAndRingWt3 === null)) {
        alert('Please provide a before test ring weight.')

      } else if (isValidated.tareNo === '' || isValidated.tareNo === null) {
        alert('Please provide a tare number.')

      } else if (isValidated.tareWtBefore === '' || isValidated.tareWtBefore === null) {
        alert('Please provide a tare weight.')

      } else if (isValidated.wetSoilAndTareWtBefore === '' || isValidated.wetSoilAndTareWtBefore === null) {
        alert('Please provide a wet soil and tare weight.')

      } else if (isValidated.drySoilAndTareWtBefore === '' || isValidated.drySoilAndTareWtBefore === null) {
        alert('Please provide a dry soil and tare weight.')

      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let time2, time5, time15, time30, time60, time120, time180

  // let startDate = isValidated.startDate === '' || isValidated.startDate === null ? '' :
  // new Date(formatDate(new Date(isValidated.startDate)) + ' ' + isValidated.startTime)
  //
  // let startTime = isValidated.startTime === '' || isValidated.startTime === null ? '' :
  // formatTime(startDate)
  let startDate = new Date(formatDateYMD(new Date(isValidated.startDate)) + ' ' + isValidated.startTime)
  let d = new Date(startDate)

  time2 = formatTime(new Date(d.setMinutes(d.getMinutes()+2)))
  d = new Date(startDate)

  time5 = formatTime(new Date(d.setMinutes(d.getMinutes()+5)))
  d = new Date(startDate)

  time15 = formatTime(new Date(d.setMinutes(d.getMinutes()+15)))
  d = new Date(startDate)

  time30 = formatTime(new Date(d.setMinutes(d.getMinutes()+30)))
  d = new Date(startDate)

  time60 = formatTime(new Date(d.setMinutes(d.getMinutes()+60)))
  d = new Date(startDate)

  time120 = formatTime(new Date(d.setMinutes(d.getMinutes()+120)))
  d = new Date(startDate)

  time180 = formatTime(new Date(d.setMinutes(d.getMinutes()+180)))

  let ringFactor = 0.828
  let sg = 2.7

  let wtOfSoil1 = Number(isValidated.wetSoilAndRingWt1) - Number(isValidated.ringWt1)
  let wtOfSoil2 = Number(isValidated.wetSoilAndRingWt2) - Number(isValidated.ringWt2)
  let wtOfSoil3 = Number(isValidated.wetSoilAndRingWt3) - Number(isValidated.ringWt3)

  let wetDensity1 = Math.round(10*(wtOfSoil1 * ringFactor))/10
  let wetDensity2 = Math.round(10*(wtOfSoil2 * ringFactor))/10
  let wetDensity3 = Math.round(10*(wtOfSoil3 * ringFactor))/10

  let wtOfWater = Number(isValidated.wetSoilAndTareWtBefore) - Number(isValidated.drySoilAndTareWtBefore)
  let wtOfDrySoil = Number(isValidated.drySoilAndTareWtBefore) - Number(isValidated.tareWtBefore)
  let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

  let dryDensity1 = Math.round(10*wetDensity1/(1+(moistureContent/100)))/10
  let dryDensity2 = Math.round(10*wetDensity2/(1+(moistureContent/100)))/10
  let dryDensity3 = Math.round(10*wetDensity3/(1+(moistureContent/100)))/10

  let saturation1 = Math.round(10*(moistureContent/((62.4/dryDensity1)-(1/sg))))/10
  let saturation2 = Math.round(10*(moistureContent/((62.4/dryDensity2)-(1/sg))))/10
  let saturation3 = Math.round(10*(moistureContent/((62.4/dryDensity3)-(1/sg))))/10

  let moistureContent1 = Math.round(10*(((Number(isValidated.afterSoakingWetSoilAndRingWt1) - Number(isValidated.ringWt1)) * ringFactor / dryDensity1) - 1) * 100)/10
  let moistureContent2 = Math.round(10*(((Number(isValidated.afterSoakingWetSoilAndRingWt2) - Number(isValidated.ringWt2)) * ringFactor / dryDensity2) - 1) * 100)/10
  let moistureContent3 = Math.round(10*(((Number(isValidated.afterSoakingWetSoilAndRingWt3) - Number(isValidated.ringWt3)) * ringFactor / dryDensity3) - 1) * 100)/10

  return (

    <div>

      <div>

        <div style={{borderBottom: '1px solid #d3d3d3', display: 'inline-block',textAlign: 'center', marginTop: 10}}>

          <span>
            <h3>
              {data.undistremold === 'u' ? 'Undisturbed' : data.undistremold === 'r' ? 'Remolded' : '?Undisturbed/Remolded?, '}
            </h3>
            <h3>
              {data.satfieldm === 's' ? 'Saturated' : data.satfieldm === 'f' ? 'Insitu' : '?Saturated/Insitu?'}
            </h3>
            <h3>
              Load (KSF): {data.dsload1}, {data.dsload2}, {data.dsload3}
            </h3>
            <h3>
              Rate (in/min): {data.rate}
            </h3>
            <h3>
              No. of Reshears: {data.reshear}
            </h3>
          </span>

        </div>

      </div>

      <div>

        <div style={{textAlign: 'center', marginTop: 10}}>
          <h3>Moisture</h3>
        </div>

        <div style={{display: 'inline-block', textAlign: 'right'}}>

          <label>Tare No
            <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='tareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.tareNo} required />
          </label><br />

          <label>Tare Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='tareWtBefore' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.tareWtBefore} required />
          </label><br />

          <label>Wet Soil and Tare Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='wetSoilAndTareWtBefore' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.wetSoilAndTareWtBefore} required />
          </label><br />

          <label>Dry Soil and Tare Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='drySoilAndTareWtBefore' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.drySoilAndTareWtBefore} required />
          </label><br />

          <label><b>Moisture Content (%)</b>
            <input style={{width: 75}} className='input' type="text" value={moistureContent} disabled />
          </label><br />

        </div>

      </div>

      <div>

        <div style={{display: 'inline-block', textAlign: 'right'}}>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Before Test</h3>
          </div>

          <label>Load (ksf)
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={data.dsload1} disabled />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={data.dsload2} disabled />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={data.dsload3} disabled />
            }
          </label><br />

          <label>Wet Soil and Ring Wt (g)
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='wetSoilAndRingWt1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.wetSoilAndRingWt1} required />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='wetSoilAndRingWt2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.wetSoilAndRingWt2} required />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='wetSoilAndRingWt3' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.wetSoilAndRingWt3} required />
            }
          </label><br />

          <label>Ring Wt (g)
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='ringWt1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ringWt1} required />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='ringWt2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ringWt2} required />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='ringWt3' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ringWt3} required />
            }
          </label><br />

          <label><b>Wet Density (pcf)</b>
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={wetDensity1} disabled />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={wetDensity2} disabled />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={wetDensity3} disabled />
            }
          </label><br />

          <label><b>Dry Density (pcf)</b>
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={dryDensity1} disabled />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={dryDensity2} disabled />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={dryDensity3} disabled />
            }
          </label><br />

          <label><b>Saturation (%)</b>
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={saturation1} disabled />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={saturation2} disabled />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={saturation3} disabled />
            }
          </label>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>After Soaking</h3>
          </div>

          <label>Wet Soil and Ring Wt (g)
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='afterSoakingWetSoilAndRingWt1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.afterSoakingWetSoilAndRingWt1} required />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='afterSoakingWetSoilAndRingWt2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.afterSoakingWetSoilAndRingWt2} required />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='afterSoakingWetSoilAndRingWt3' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.afterSoakingWetSoilAndRingWt3} required />
            }
          </label><br />

          <label><b>Moisture Content (%)</b>
            {data.dsload1 === '' || data.dsload1 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={moistureContent1} disabled />
            }
            {data.dsload2 === '' || data.dsload2 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={moistureContent2} disabled />
            }
            {data.dsload3 === '' || data.dsload3 === null ? null :
              <input style={{width: 75}} className='input' type="text" value={moistureContent3} disabled />
            }
          </label>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Readings</h3>
          </div>

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            <label>Start Date
              <input className='input' type="date" pattern="{1,}" name='startDate' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.startDate === '' || isValidated.startDate === null ? null : formatDateYMD(new Date(isValidated.startDate))} required />
            </label><br />

            <label>Start Time
              <input className='input' type="time" pattern="{1,}" name='startTime' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.startTime} required />
            </label>

            {
              isValidated.startDate === null || isValidated.startDate === '' ?
              <h3>Provide a start date.</h3> :
              isValidated.startTime === null || isValidated.startTime === '' ?
              <h3>Provide a start time.</h3> :
              <>
                <div>
                  <label>Height Change</label>
                </div>

                <label>{isValidated.startTime}
                  {data.dsload1 === '' || data.dsload1 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load1VertDis0' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load1VertDis0} required />
                  }
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis0' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis0} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis0' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis0} required />
                  }
                </label><br />

                <label>{time2}
                  {data.dsload1 === '' || data.dsload1 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load1VertDis2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load1VertDis2} required />
                  }
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis2} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis2} required />
                  }
                </label><br />

                <label>{time5}
                  {data.dsload1 === '' || data.dsload1 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load1VertDis5' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load1VertDis5} required />
                  }
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis5' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis5} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis5' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis5} required />
                  }
                </label><br />

                <label>{time15}
                  {data.dsload1 === '' || data.dsload1 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load1VertDis15' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load1VertDis15} required />
                  }
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis15' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis15} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis15' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis15} required />
                  }
                </label><br />

                <label>{time30}
                  {data.dsload1 === '' || data.dsload1 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load1VertDis30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load1VertDis30} required />
                  }
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis30} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis30} required />
                  }
                </label><br />

                <label>{time60}
                  {data.dsload1 === '' || data.dsload1 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load1VertDis60' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load1VertDis60} required />
                  }
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis60' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis60} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis60' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis60} required />
                  }
                </label><br />

                <label>{time120}
                  {data.dsload2 === '' || data.dsload2 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load2VertDis120' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load2VertDis120} required />
                  }
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis120' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis120} required />
                  }
                </label><br />

                <label>{time180}
                  {data.dsload3 === '' || data.dsload3 === null ? null :
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{1,}" name='load3VertDis180' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.load3VertDis180} required />
                  }
                </label>
              </>
            }

          </div>

        </div>

      </div>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

        <SentOut data={data} onInput={validate} onChange={props.changedSample} />

        <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

        {editSampleIcon}

      </div>

    </div>

  )

}

export default Ds
