import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import SampleDescription from 'components/utils/SampleDescription'
import Modal from 'components/utils/Modal'

import {
  formatDateYMD,
  formatDateTime,
  getLocation,
  viewLocation,
  missingLocation
} from 'scripts/common'

import Mds from 'components/Tests/Mds'
import Ei from 'components/Tests/Ei'
import So4 from 'components/Tests/So4'
import Max from 'components/Tests/Max'
import Consol from 'components/Tests/Consol'
import Passing200 from 'components/Tests/Passing200'
import Sa from 'components/Tests/Sa'
import Hydro from 'components/Tests/Hydro'
import Ds from 'components/Tests/Ds'
import Ph from 'components/Tests/Ph'
import Ch from 'components/Tests/Ch'
import Resist from 'components/Tests/Resist'
import Rval from 'components/Tests/Rval'
import Sval from 'components/Tests/Sval'
import Oc from 'components/Tests/Oc'
import Av from 'components/Tests/Av'
import Acsa from 'components/Tests/Acsa'
import Att from 'components/Tests/Att'
import Se from 'components/Tests/Se'

const TestModal = (props) => {

  const [fetchedSample, setFetchedSample] = useState([])
  const [fetchedConsolReadings, setFetchedConsolReadings] = useState([])
  const [isChangedSample, setIsChangedSample] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const fetchData = () => {

    fetch('/api/selectModalSample', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: props.id, field: props.field})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedSample(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const updateDisposed = () => {

    if (window.confirm('Are you sure you want to throw away this sample?')) {

      let id = fetchedSample[0].labid

      getLocation(function(latlng){

        fetch('/api/todoDisposed', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: id,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            fetchData()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const changedSample = () => setIsChangedSample(true)

  const savedSample = () => setIsChangedSample(false)

  const dispose = () => {
    if (window.confirm("Are you sure you threw away all the material?")) {
    }
  }

  const closeModal = () => {

    const close = () => props.onClick()

    if (isChangedSample) {
      if (window.confirm('You have unsaved data. Close anyways?')) {
        close()
      }
    } else {
      close()
    }
  }

  let modal = {
    position: 'fixed',
    zIndex: 4,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.4)'
  }

  let modalContent = {
    borderRadius: 10,
    border: '1px solid #d3d3d3',
    backgroundColor: 'white',
    margin: '5px auto',
    overflow: 'auto',
    height: 'calc(100% - 30px)',
    width: 'calc(100% - 30px)'
  }

  const styleAddTest = {

    backgroundColor: 'dodgerblue',
    border: 'none',
    borderRadius: '50%',
    padding: 15,
    height: 54,
    width: 54,
    margin: 10,
    color: 'white',
    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19)'

  }

  // who determines if todo_entry, todo_manager, etc
  // user determines rights and entry,mod, start, etc
  // updateOrigin updates todo is status change
  // updateSample updates the data displayed in the modal

  //, , , , , statushydro, status200, statusatt, statusph, statusrval, statusresist, statusch, statusse, statussval, statusoc, statusacsa, statusav, "
  let contentTest
  switch (props.field) {
    case 'statusmds':
      contentTest = <Mds data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusmax':
      contentTest = <Max data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusei':
       contentTest = <Ei data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusso4':
      contentTest = <So4 data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusconsol':
      contentTest = <Consol data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statussa':
      contentTest = <Sa data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statushydro':
      contentTest = <Hydro data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'status200':
      contentTest = <Passing200 data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusds':
      contentTest = <Ds data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusph':
      contentTest = <Ph data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusch':
      contentTest = <Ch data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusresist':
      contentTest = <Resist data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusrval':
      contentTest = <Rval data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statussval':
      contentTest = <Sval data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusoc':
      contentTest = <Oc data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusav':
      contentTest = <Av data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusacsa':
      contentTest = <Acsa data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusatt':
      contentTest = <Att data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    case 'statusse':
      contentTest = <Se data={fetchedSample} who={props.who} user={props.user} isChangedSample={isChangedSample} changedSample={changedSample} savedSample={savedSample} updateOrigin={props.updateOrigin} updateSample={fetchData} />
      break;
    default:
      contentTest = <h3>Test component not found...</h3>
  }

  let sampled = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].entryby !== null && fetchedSample[0].entryby !== '' ?
        <>
          {fetchedSample[0].entrylat !== null && fetchedSample[0].entrylat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].entrylat} lng={fetchedSample[0].entrylng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Sampled by: {fetchedSample[0].entryby} @ {formatDateTime(new Date(fetchedSample[0].entrytime))}</small>
        </> :
        <small>Not sampled</small>
      }
    </div>
  )

  let delivered = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].deliveredby !== null && fetchedSample[0].deliveredby !== '' ?
        <>
          {fetchedSample[0].deliveredlat !== null && fetchedSample[0].deliveredlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].deliveredlat} lng={fetchedSample[0].deliveredlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Delivered by: {fetchedSample[0].deliveredby} @ {formatDateTime(new Date(fetchedSample[0].deliveredtime))}</small>
        </> :
        <small>Not delivered</small>
      }
    </div>
  )

  let assigned = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].assignedby !== null && fetchedSample[0].assignedby !== '' ?
        <>
          {fetchedSample[0].assignedlat !== null && fetchedSample[0].assignedlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].assignedlat} lng={fetchedSample[0].assignedlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Assigned by: {fetchedSample[0].assignedby} @ {formatDateTime(new Date(fetchedSample[0].assignedtime))}</small>
        </> :
        <small>Not assigned?</small>
      }
    </div>
  )

  let started = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].startedby !== null && fetchedSample[0].startedby !== '' ?
        <>
          {fetchedSample[0].startedlat !== null && fetchedSample[0].startedlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].startedlat} lng={fetchedSample[0].startedlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Started by: {fetchedSample[0].startedby} @ {formatDateTime(new Date(fetchedSample[0].startedtime))}</small>
        </> :
        <small>Not started</small>
      }
    </div>
  )

  let completed = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].completedby !== null && fetchedSample[0].completedby !== '' ?
        <>
          {fetchedSample[0].completedlat !== null && fetchedSample[0].completedlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].completedlat} lng={fetchedSample[0].completedlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Completed by: {fetchedSample[0].completedby} @ {formatDateTime(new Date(fetchedSample[0].completedtime))}</small>
        </> :
        <small>Not completed</small>
      }
    </div>
  )

  let reviewed = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].reviewedby !== null && fetchedSample[0].reviewedby !== '' ?
        <>
          {fetchedSample[0].reviewedlat !== null && fetchedSample[0].reviewedlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].reviewedlat} lng={fetchedSample[0].reviewedlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Reviewed by: {fetchedSample[0].reviewedby} @ {formatDateTime(new Date(fetchedSample[0].reviewedtime))}</small>
        </> :
        <small>Not reviewed</small>
      }
    </div>
  )

  let toArchive = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].archiveby !== null && fetchedSample[0].archiveby !== '' ?
        <>
          {fetchedSample[0].archivelat !== null && fetchedSample[0].archivelat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].archivelat} lng={fetchedSample[0].archivelng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>To archive by: {fetchedSample[0].archiveby} @ {formatDateTime(new Date(fetchedSample[0].archivetime))}</small>
        </> :
        <small>Not assigned to archive</small>
      }
    </div>
  )

  let archived = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].archivedby !== null && fetchedSample[0].archivedby !== '' ?
        <>
          {fetchedSample[0].archivedlat !== null && fetchedSample[0].archivedlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].archivedlat} lng={fetchedSample[0].archivedlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>
            Archived by: {fetchedSample[0].archivedby} @ {formatDateTime(new Date(fetchedSample[0].archivedtime))}<br />
            Rack: {fetchedSample[0].archiveRack}, Row: {fetchedSample[0].archiveRow}, Col: {fetchedSample[0].archiveCol}
          </small>
        </> :
        <small style={{margin: 5}}>Not archived</small>
      }
    </div>
  )

  let toDispose = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].disposeby !== null && fetchedSample[0].disposeby !== '' ?
        <>
          {fetchedSample[0].disposelat !== null && fetchedSample[0].disposelat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].disposelat} lng={fetchedSample[0].disposelng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>To Dispose by: {fetchedSample[0].disposeby} @ {formatDateTime(new Date(fetchedSample[0].disposetime))}</small>
        </> :
        <small style={{margin: 5}}>Not assigned to dispose</small>
      }
    </div>
  )

  let disposed = fetchedSample.length < 1 ? null :
  (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

      {fetchedSample[0].disposedby !== null && fetchedSample[0].disposedby !== '' ?
        <>
          {fetchedSample[0].disposedlat !== null && fetchedSample[0].disposedlat !== '' ?
            <Icon name='location_on' lat={fetchedSample[0].disposedlat} lng={fetchedSample[0].disposedlng} onClick={viewLocation} /> :
            <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
          }
          <small>Disposed by: {fetchedSample[0].disposedby} @ {formatDateTime(new Date(fetchedSample[0].disposedtime))}</small>
        </> :
        <small style={{margin: 5}}>Not disposed</small>
      }
    </div>
  )

  // let archived = fetchedSample.length < 1 ?
  // null :
  // fetchedSample[0].archivedtime !== null && fetchedSample[0].archivedtime !== '' ?
  // 'Archived by: ' + fetchedSample[0].archivedby + ' @ ' + formatDateTime(new Date(fetchedSample[0].archivedtime)) : 'Not Archived'
  //
  // if (fetchedSample.length > 0 ) {
  //
  //   if (fetchedSample[0].archivedtime !== null && fetchedSample[0].archivedtime !== '') {
  //     archived += '\nRack: ' + fetchedSample[0].archiveRack
  //     archived += '\nRow: ' + fetchedSample[0].archiveRow
  //     archived += '\nCol: ' + fetchedSample[0].archiveCol
  //   }
  //
  // }

  // let toDispose = fetchedSample.length < 1 ?
  // null :
  // fetchedSample[0].disposetime !== null && fetchedSample[0].disposetime !== '' ?
  // 'to dispose by: ' + fetchedSample[0].disposeby + ' @ ' + formatDateTime(new Date(fetchedSample[0].disposetime)) : 'Not assigned to dispose'
  //
  // let disposed = fetchedSample.length < 1 ?
  // null :
  // fetchedSample[0].disposedtime !== null && fetchedSample[0].disposedtime !== '' ?
  // 'Disposed by: ' + fetchedSample[0].disposedby + ' @ ' + formatDateTime(new Date(fetchedSample[0].disposedtime)) : 'Not disposed'

  let statuses =
  <>
    {sampled}
    {delivered}
    {assigned}
    {started}
    {completed}
    {reviewed}
    {fetchedSample.length > 0 ? <h3>Due Date: {formatDateYMD(new Date(fetchedSample[0].duedate))}</h3> :null}
    {toArchive}
    {archived}
    {toDispose}
    {disposed}
  </>

  let jn = fetchedSample.length > 0 ? <h3>{fetchedSample[0].jobnumber}</h3> : null

  let title = fetchedSample.length > 0 ?
    fetchedSample[0].phaseid === 'P' ?
    <h3>{fetchedSample[0].location} @ {fetchedSample[0].depth}ft</h3> :
    <h3>SN {fetchedSample[0].sampleno}, Location: {fetchedSample[0].location}</h3> :
    null

  let content = fetchedSample.length === 1 ?
    contentTest :
    isLoading ?
    <h3>Loading...</h3> :
    fetchedSample.length === 0 ?
    <h3>Error: no record found</h3> :
    <h3>Error: multiple records found</h3>

  let sampleDescription = fetchedSample.length > 0 ?
    <SampleDescription data={fetchedSample} user={props.user} field={props.field} /> :
    null

  let noRemainingMaterial =
    fetchedSample.length > 0 ?
    fetchedSample[0].sampleStatus !== 'disposed' ?
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderBottom: '1px solid #d3d3d3',
      margin: 10
    }}>
      <span><b>No Remaining Material?</b></span><Icon name='delete' color='tomato' iconClass='material-icons-outlined' onClick={updateDisposed} />
    </div>
   : null : null

  return (
    <Modal
      maxWidth='none'
      content={
        <div style={{textAlign: 'center'}}>
          {jn}
          {title}
          {statuses}
          {sampleDescription}
          {noRemainingMaterial}
          {content}
        </div>
      }
      closeModal={closeModal}
    />
  )
}

export default TestModal
