import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import 'styles/navBar.css';

function NavBar(props) {

  const [isModal, setIsModal] = useState({
    profile: false,
    menu: false
  })
  const minimumScreenSize = 600
  const [screenSizeNormal, setScreenSizeNormal] = useState(null)

  const resizeScreen = () => {

    if (window.innerWidth > minimumScreenSize && screenSizeNormal === null) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth <= minimumScreenSize && screenSizeNormal === null) {
      setScreenSizeNormal(false)
    } else if (window.innerWidth > minimumScreenSize && !screenSizeNormal) {
      setIsModal(prevState => ({...prevState, menu: false}))
      setScreenSizeNormal(true)
    } else if (window.innerWidth < minimumScreenSize && screenSizeNormal) {
      setScreenSizeNormal(false)
    }

  }

  window.addEventListener("resize", resizeScreen)

  useEffect(() => {
    resizeScreen()
  }, [])

  //const openAppMenu = () => setIsAppMenu(isAppMenu ? false : true)

  const openProfile = () => setIsModal(prevState => ({...prevState, profile: true}))
  const openMenu = () => setIsModal(prevState => ({...prevState, menu: true}))
  const closeProfile = () => setIsModal(prevState => ({...prevState, profile: false}))
  const closeMenu = () => setIsModal(prevState => ({...prevState, menu: false}))

  const selectComponent = (e) => {
    props.onClick(e.target)
    closeMenu()
  }

  const selectRow = (e) => {
    props.onClick(e.target.parentNode)
    closeMenu()
  }

  const styleNormal = {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto'
  }

  const styleSmall = {
    backgroundColor: '#383838',
    padding: 5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }

  const styleAppMenu = {
    backgroundColor: '#383838',
    padding: 5,
    overflowX: 'auto',
    textAlign: 'right'
  }

  const titleStyle = {
    display: 'inline-block',
    width: 50,
    height: 'auto',
    objectFit: 'contain',
    marginRight: 10
  }

  // {props.user.drawing > 0 ? <tr className={props.component === 'Drawings' ? 'activeMenu' : 'nonActive2'} data-name='Drawings' onClick={selectRow}><td>Drawings</td></tr> : null}

  let menuContent = (
    <div style={{textAlign: 'center'}}>
      <div style={{display: 'inline-block'}}>
        <table>

          <thead>
            <tr>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr className={props.component === 'Home' ? 'activeMenu' : 'nonActive2'} data-name='Home' onClick={selectRow}><td>Home</td></tr>
            <tr className={props.component === 'Add' ? 'activeMenu' : 'nonActive2'} data-name='Add' onClick={selectRow}><td>Add</td></tr>
            <tr className={props.component === 'Assign' ? 'activeMenu' : 'nonActive2'} data-name='Assign' onClick={selectRow}><td>Assign</td></tr>
            <tr className={props.component === 'Todo' ? 'activeMenu' : 'nonActive2'} data-name='Todo' onClick={selectRow}><td>Todo</td></tr>
            <tr className={props.component === 'Results' ? 'activeMenu' : 'nonActive2'} data-name='Results' onClick={selectRow}><td>Results</td></tr>
            <tr className={props.component === 'Archive' ? 'activeMenu' : 'nonActive2'} data-name='Archive' onClick={selectRow}><td>Archive</td></tr>
            {props.user.manage > 0 ? <tr className={props.component === 'Manage' ? 'activeMenu' : 'nonActive2'} data-name='Manage' onClick={selectRow}><td>Manage</td></tr> : null}
          </tbody>

        </table>
      </div>
    </div>
  )

  return (
    <div>
      {isModal.profile ?
        <Modal
          content={
            <div>
              <h3>Profile</h3>
              <div>{props.user.username}</div>
              <div><small>Version 25</small></div>
            </div>
          }
          closeModal={closeProfile}
        /> : null
      }
      {isModal.menu ? <Modal content={menuContent} closeModal={closeMenu} /> : null}
      {screenSizeNormal ?
        <div style={styleNormal}>
          <img style={titleStyle} src="logo192.png" />
          <div style={{display: 'inline-block'}} className={props.component === 'Home' ? 'active' : 'nonActive'} data-name='Home' onClick={selectComponent}>Home</div>
          <div style={{display: 'inline-block'}} className={props.component === 'Add' ? 'active' : 'nonActive'} data-name='Add' onClick={selectComponent}>Add</div>
          <div style={{display: 'inline-block'}} className={props.component === 'Assign' ? 'active' : 'nonActive'} data-name='Assign' onClick={selectComponent}>Assign</div>
          <div style={{display: 'inline-block'}} className={props.component === 'Todo' ? 'active' : 'nonActive'} data-name='Todo' onClick={selectComponent}>Todo</div>
          <div style={{display: 'inline-block'}} className={props.component === 'Results' ? 'active' : 'nonActive'} data-name='Results' onClick={selectComponent}>Results</div>
          <div style={{display: 'inline-block'}} className={props.component === 'Archive' ? 'active' : 'nonActive'} data-name='Archive' onClick={selectComponent}>Archive</div>
          {props.user.manage > 0 ? <div style={{display: 'inline-block'}} className={props.component === 'Manage' ? 'active' : 'nonActive'} data-name='Manage' onClick={selectComponent}>Manage</div> : null}
          <div>
            <Icon name='account_circle' onClick={openProfile} />
          </div>
        </div> :
      !screenSizeNormal ?
        <div>
          <div style={styleSmall}>
            <img style={titleStyle} src="logo192.png" />
            <Icon name='apps' onClick={openMenu} />
            <Icon name='account_circle' onClick={openProfile} />
          </div>
        </div> :null
      }
    </div>
  )

}

export default NavBar
