import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Att = (props) => {

  const data = props.data[0]
  const [fetchedTrials, setFetchedTrials] = useState([])
  const [isTrial, setIsTrial] = useState(false)
  const [isModal, setIsModal] = useState(false)
  //const [isChangedSample, setIsChangedSample] = useState(false)
  const [isChangedTrial, setIsChangedTrial] = useState(false)

  const canvasRef = useRef(null)

  const [isValidated, setIsValidated] = useState({
    id: null,
    liquidOrPlastic: null,
    blowCount: null,
    tareNo: null,
    tareWt: null,
    wetSoilAndTareWt: null,
    drySoilAndTareWt: null,
    ll: data.ll,
    pl: data.pl,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const fetchData = () => {

    fetch('/api/selectAttTrials', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({attId: data.id})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedTrials(result)
        setIsTrial(false)
        setIsChangedTrial(false)
        setIsModal(false)
        setIsValidated(prevState => ({...prevState, id: null, liquidOrPlastic: null, blowCount: null, tareNo: null, tareWt: null, wetSoilAndTareWt: null, drySoilAndTareWt: null}))

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    buildGraph()
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateAtt', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            ll: isValidated.ll,
            pl: isValidated.pl
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` : `SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'att', 'update', description, props.user.username)

            // if (isValidated.status === 'completed') {
            //   props.closeTestModal()
            // }

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusatt',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
                // if (isValidated.status === 'completed') {
                //   props.closeTestModal()
                // }
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.ll === '' || isValidated.ll === null) {
        alert('Please provide a liquid limit.')
      } else if (isValidated.pl === '' || isValidated.pl === null) {
        alert('Please provide a plastic limit.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const selectTrial = (event) => {

    let el = event.target
    let id = el.getAttribute('data-id')

    fetch('/api/selectAttTrial', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id})
    })
    .then(res=>res.json())
    .then(
      (result) => {

        setIsValidated(prevState => ({
          ...prevState,
          id: id,
          liquidOrPlastic: result[0].liquidOrPlastic,
          blowCount: result[0].blowCount,
          tareNo: result[0].tareNo,
          tareWt: result[0].tareWt,
          wetSoilAndTareWt: result[0].wetSoilAndTareWt,
          drySoilAndTareWt: result[0].drySoilAndTareWt
        }))

        setIsModal(true)

      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  const addTrial = () => {

    if (isValidated.liquidOrPlastic === '' || isValidated.liquidOrPlastic === null) {
      alert('Please specify if this is liquid or plastic.')
    } else if (isValidated.blowCount === '' || isValidated.blowCount === null) {
      alert('Please provide a blow count.')
    } else if (isValidated.tareNo === '' || isValidated.tareNo === null) {
      alert('Please provide a tare number.')
    } else if (isValidated.tareWt === '' || isValidated.tareWt === null) {
      alert('Please provide a tare weight.')
    } else if (isValidated.wetSoilAndTareWt === '' || isValidated.wetSoilAndTareWt === null) {
      alert('Please provide a wet soil and tare weight.')
    // } else if (isValidated.drySoilAndTareWt === '' || isValidated.drySoilAndTareWt === null) {
    //   alert('Please provide a dry soil and tare weight.')
    } else {

      getLocation(function(latlng){

        fetch('/api/addAttTrial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            attId: data.id,
            liquidOrPlastic: isValidated.liquidOrPlastic,
            blowCount: isValidated.blowCount,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            wetSoilAndTareWt: isValidated.wetSoilAndTareWt,
            drySoilAndTareWt: isValidated.drySoilAndTareWt,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            fetchData()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` : `SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'att trial', 'add', description, props.user.username)
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const editTrial = () => {

    getLocation(function(latlng){

      fetch('/api/editAttTrial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id,
          liquidOrPlastic: isValidated.liquidOrPlastic,
          blowCount: isValidated.blowCount,
          tareNo: isValidated.tareNo,
          tareWt: isValidated.tareWt,
          wetSoilAndTareWt: isValidated.wetSoilAndTareWt,
          drySoilAndTareWt: isValidated.drySoilAndTareWt,
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: latlng.lat,
          lng: latlng.lng
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          fetchData()

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` : `SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'att trial', 'edit', description, props.user.username)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    })

  }

  const deleteTrial = (event) => {

    if (!props.user.todo > 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (window.confirm('This will delete the trial permanently. Proceed?')) {

      let el = event.target
      let id = el.getAttribute('data-id')

      fetch('/api/deleteAttTrial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: id})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          fetchData()

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` : `SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'att trial', 'delete', description, props.user.username)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const showTrialInputs = () => setIsTrial(true)

  //const changedSample = () => setIsChangedSample(true)

  const changedTrial = () => setIsChangedTrial(true)

  const closeModal = () => {

    const update = () => {
      setIsChangedTrial(false)
      setIsTrial(false)
      setIsModal(false)
      setIsValidated(prevState => ({...prevState,
        id: null,
        addedWater: null,
        resist: null
      }))
    }

    if (isChangedTrial) {
      if (window.confirm('You have unsaved data. Close anyways?')) {
        update()
      }
    } else {
      update()
    }
  }

  const buildGraph = () => {
    let canvas, ctx, chart
    let backgroundColor = 'dodgerblue'
    let borderColor = '#000000'
    let pointBackgroundColor = []
    let pointBorderColor = []

    let graphData = fetchedTrials.map(trial => {

      if (trial.liquidOrPlastic === 'liquid') {

        let wtOfWater, wtOfDrySoil
        wtOfWater = trial.wetSoilAndTareWt - trial.drySoilAndTareWt
        wtOfDrySoil = trial.drySoilAndTareWt - trial.tareWt
        let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

        pointBackgroundColor.push(backgroundColor)
        pointBorderColor.push(borderColor)
        return ({x: trial.blowCount, y: moistureContent})

      }

    })

    // // plots data before submit for review purposes
    // if (isValidated.dialReading !== '' && isValidated.dialReading !== null && isValidated.loadApplied !== '' && isValidated.loadApplied !== null) {
    //   if (data.length > 0) {
    //     pointBackgroundColor.push('dodgerblue')
    //     pointBorderColor.push(borderColor)
    //
    //     data.push({x: loadAccum, y: percentConsol})
    //   }
    // }

    canvas = canvasRef.current
    ctx = canvas.getContext('2d')
    // // must destroy previous charts to prevent data carried over
    // ctx ? ctx.destory() : canvas.getContext('2d')
    chart = new window.Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [{
          label: data.phaseid === 'P' ? data.location + ' @ '+ data.depth : 'SN: ' + data.sampleno,
          data: graphData,
          borderColor: 'black',
          borderWidth: 1,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBorderColor,
          pointRadius: 5,
          pointHoverRadius: 5,
          fill: false,
          //lineTension: 0.5,
          showLine: false
        }]
      },
      options: {
        maintainAspectRatio: true,
        legend: {display: true},
        scales: {
          xAxes: [{
            type: 'linear',
            position: 'bottom',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              // min: 100,
              // max: 100000,
              callback: function (value, index, values) {
                return Number(value.toString()) //pass tick values as a string into Number function
              }
            }
            // ,
            // afterBuildTicks: function (chartObj) { //Build ticks labelling as per your need
            //   chartObj.ticks = []
            //   chartObj.ticks.push(100)
            //   chartObj.ticks.push(1000)
            //   chartObj.ticks.push(10000)
            //   chartObj.ticks.push(100000)
            // }
          }],
          yAxes: [{
            type: 'linear',
            position: 'left',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              reverse: false,
              //min: 0
            }
          }]
        }
      }
    })
  }

  let liquidTrials = fetchedTrials.map(trial => {

    if (trial.liquidOrPlastic === 'liquid') {

      let wtOfWater, wtOfDrySoil
      wtOfWater = trial.wetSoilAndTareWt - trial.drySoilAndTareWt
      wtOfDrySoil = trial.drySoilAndTareWt - trial.tareWt
      let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

      let editIcon = props.user.todo > 1 ?
        <Icon name='edit' id={trial.id} onClick={selectTrial} /> :
        null

      let deleteIcon = props.user.todo > 2 ?
        <Icon name='delete' color='tomato' id={trial.id} onClick={deleteTrial} /> :
        null

      return (
        <tr>
          <td>Liquid</td>
          <td>{trial.tareNo}</td>
          <td>{trial.blowCount}</td>
          <td>{moistureContent}</td>
          {editIcon}
          {deleteIcon}
        </tr>
      )

    }

  })

  let plasticTrials = fetchedTrials.map(trial => {

    if (trial.liquidOrPlastic === 'plastic') {

      let wtOfWater, wtOfDrySoil
      wtOfWater = trial.wetSoilAndTareWt - trial.drySoilAndTareWt
      wtOfDrySoil = trial.drySoilAndTareWt - trial.tareWt
      let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

      let editIcon = props.user.todo > 1 ?
        <Icon name='edit' id={trial.id} onClick={selectTrial} /> :
        null

      let deleteIcon = props.user.todo > 2 ?
        <Icon name='delete' color='tomato' id={trial.id} onClick={deleteTrial} /> :
        null

      return (
        <tr>
          <td>Plastic</td>
          <td>{trial.tareNo}</td>
          <td>{trial.blowCount}</td>
          <td>{moistureContent}</td>
          {editIcon}
          {deleteIcon}
        </tr>
      )

    }

  })

  let tableOfTrials =
  liquidTrials.length > 0 && plasticTrials.length > 0 ?
  (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Tare No</th>
          <th>Blow Count</th>
          <th>MC (%)</th>
        </tr>
      </thead>
      <tbody>
        {liquidTrials}
        {plasticTrials}
      </tbody>
    </table>
  ) :
  liquidTrials.length > 0 ?
  (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Tare No</th>
          <th>Blow Count</th>
          <th>MC (%)</th>
        </tr>
      </thead>
      <tbody>
        {liquidTrials}
        <h4>No plastic trials</h4>
      </tbody>
    </table>
  ) :
  plasticTrials.length > 0 ?
  (
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Tare No</th>
          <th>Blow Count</th>
          <th>MC (%)</th>
        </tr>
      </thead>
      <tbody>
        <h4>No liquid trials</h4>
        {plasticTrials}
      </tbody>
    </table>
  ) :
  <h4>No Trials started.</h4>

  let wtOfWater, wtOfDrySoil
  wtOfWater = isValidated.wetSoilAndTareWt - isValidated.drySoilAndTareWt
  wtOfDrySoil = isValidated.drySoilAndTareWt - isValidated.tareWt
  let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

  let modalContent = (
    <div style={{display: 'inline-block', textAlign: 'right'}}>

      <label>Liquid or Plastic?
        <select className='select' pattern=".{1,}" name='liquidOrPlastic' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.liquidOrPlastic} required>
          <option value=""></option>
          <option value="liquid">Liquid</option>
          <option value="plastic">Plastic</option>
        </select>
      </label><br />

      <label>Blow Count
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}" name='blowCount' onInput={validate} onChange={changedTrial} defaultValue={isValidated.blowCount} required />
      </label><br />

      <label>Tare No
        <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='tareNo' onInput={validate} onChange={changedTrial} defaultValue={isValidated.tareNo} required />
      </label><br />

      <label>Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1,2})?" name='tareWt' onInput={validate} onChange={changedTrial} defaultValue={isValidated.tareWt} required />
      </label><br />

      <label>Wet Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1,2})?" name='wetSoilAndTareWt' onInput={validate} onChange={changedTrial} defaultValue={isValidated.wetSoilAndTareWt} required />
      </label><br />

      <label>Dry Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1,2})?" name='drySoilAndTareWt' onInput={validate} onChange={changedTrial} defaultValue={isValidated.drySoilAndTareWt} required />
      </label><br />

      <label><b>Moisture Content (%)</b>
        <input style={{width: 75}} className='input' type="text" value={moistureContent} disabled />
      </label>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <Icon name='check_circle' color={isChangedTrial ? 'dodgerblue' : 'gray'} onClick={isChangedTrial ? editTrial : nothingChanged} />

      </div>

    </div>
  )

  let pi = isValidated.ll === null || isValidated.ll === '' || isValidated.pl === null || isValidated.pl === '' ? '' : isValidated.ll - isValidated.pl



  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let addTrialIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={isChangedTrial ? 'dodgerblue' : 'gray'} onClick={isChangedTrial ? addTrial : nothingChanged} /> :
    null

  return (

    <div style={{display: 'inline-block', overflowX: 'auto', width: '100%', height: '100%'}}>

      {isModal ? <Modal content={modalContent} closeModal={closeModal} /> : null}

      <div style={{display: 'flex', overflowX: 'auto', width: '100%'}}>
        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div>
            <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>
              <h3>Trials</h3>
              {tableOfTrials}
            </div>
          </div>

          {isTrial ?

            <div style={{display: 'inline-block', textAlign: 'right', marginTop: 10}}>

              <label>Liquid or Plastic?
                <select className='select' pattern=".{1,}" name='liquidOrPlastic' onInput={validate} onChange={props.changedSample} required>
                  <option value=""></option>
                  <option value="liquid">Liquid</option>
                  <option value="plastic">Plastic</option>
                </select>
              </label><br />

              <label>Blow Count
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}" name='blowCount' onInput={validate} onChange={changedTrial} required />
              </label><br />

              <label>Tare No
                <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='tareNo' onInput={validate} onChange={changedTrial} required />
              </label><br />

              <label>Tare Wt (g)
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1,2})?" name='tareWt' onInput={validate} onChange={changedTrial} required />
              </label><br />

              <label>Wet Soil and Tare Wt (g)
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1,2})?" name='wetSoilAndTareWt' onInput={validate} onChange={changedTrial} required />
              </label><br />

              <label>Dry Soil and Tare Wt (g)
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1,2})?" name='drySoilAndTareWt' onInput={validate} onChange={changedTrial} required />
              </label><br />

              <label><b>Moisture Content (%)</b>
                <input style={{width: 75}} className='input' type="text" value={moistureContent} disabled />
              </label>

              <div style={{textAlign: 'center', marginTop: 10}}>

                {addTrialIcon}

              </div>

            </div> :

            <div style={{textAlign: 'center', marginTop: 10}}>
              <Icon name='add_circle' onClick={showTrialInputs} />
            </div>

          }

          <div>

            <div style={{display: 'inline-block', textAlign: 'right', marginTop: 10}}>

              <label>Liquid Limit
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='ll' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ll} required />
              </label><br />

              <label>Plastic Limit
                <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='pl' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.pl} required />
              </label><br />

              <label><b>Plasticity Index</b>
                <input style={{width: 75}} className='input' type="text" value={pi} disabled />
              </label>

            </div>

          </div>

          <div style={{textAlign: 'center', marginTop: 10}}>

            <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

            <SentOut data={data} onInput={validate} onChange={props.changedSample} />

            <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

            {editSampleIcon}

          </div>

        </div>

        <div style={{margin: 10, flex: '1 1 auto'}}>
          <h3>Chart</h3>
          <div>
            <img src="atterberg.png" alt="atterberg" />
          </div>
        </div>

        <div style={{margin: 10, flex: '1 1 auto'}}>
          <h3>Graph</h3>
          <div>
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>

    </div>
  )

}

export default Att
