import React from 'react'
import AssignLab from './AssignLab'
import Icon from 'components/utils/Icon'

import { formatDateTime, formatDate, formatDateMD } from 'scripts/common'

const ViewByDate = (props) => {

  const showStatusInfo = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let content, sampled, delivered, dispose, disposed, archive, archived, location
    //console.log('samples: ' + JSON.stringify(fetchedSamples))
    let msg = props.fetchedSamples.map(sample => {

      if (sample.labid === id) {

        sampled = sample.entrytime !== null && sample.entrytime !== '' ?
        'Sampled by: ' + sample.entryby + ' @ ' + formatDateTime(new Date(sample.entrytime)) + '\n' : 'Not sampled\n'

        delivered = sample.deliveredtime !== null && sample.deliveredtime !== '' ?
        'Delivered by: ' + sample.deliveredby + ' @ ' + formatDateTime(new Date(sample.deliveredtime)) : 'Not delivered'

        content = sampled + delivered

        if ((sample.disposetime !== null && sample.disposetime !== '') || (sample.disposedtime !== null && sample.disposedtime !== '')) {

          dispose = sample.disposetime !== null && sample.disposetime !== '' ?
          '\nTo dispose by: ' + sample.disposeby + ' @ ' + formatDateTime(new Date(sample.disposetime)) : '\nNot assigned to dispose'

          disposed = sample.disposedtime !== null && sample.disposedtime !== '' ?
          '\nDisposed by: ' + sample.disposedby + ' @ ' + formatDateTime(new Date(sample.disposedtime)) : '\nNot disposed'


          content += dispose + disposed

        } else if ((sample.archivetime !== null && sample.archivetime !== '') || (sample.archivedtime !== null && sample.archivedtime !== '')) {

          archive = sample.archivetime !== null && sample.archivetime !== '' ?
          '\nTo archive by: ' + sample.archiveby + ' @ ' + formatDateTime(new Date(sample.archivetime)) : '\nNot assigned to archive'

          content += archive

          if (sample.archivedtime !== null && sample.archivedtime !== '') {
            archived = '\nArchived by: ' + sample.archivedby + ' @ ' + formatDateTime(new Date(sample.archivedtime))
            location = '\nRack: ' + sample.archiveRack
            location += '\nRow: ' + sample.archiveRow
            location += '\nCol: ' + sample.archiveCol

            content += archived + location
          }

        }

        return (
          content
        )
      }

    })

    alert(content)
  }

  let countPriorities = 0

  console.log(`length: ${props.fetchedDueDates.length}`)

  let dueDates = props.fetchedDueDates.length === 1 ? <h3 style={{margin: '10px 0px 0px'}}>nothing found  ͡° ͜ʖ ͡°)</h3> : props.fetchedDueDates.map(dueDate => {

    let phaseIds = ['P','C']
    let phases = phaseIds.map(phase => {

      let jobs = props.fetchedJobs.map(job => {
        let countSamples = 0

        let samples = props.fetchedSamples.map(sample => {

          // &&
          // (sample.duedatemds === dueDate.duedate || sample.duedatemax  === dueDate.duedate || sample.duedateei === dueDate.duedate || sample.duedateso4 === dueDate.duedate || sample.duedateconsol === dueDate.duedate || sample.duedateds === dueDate.duedate || sample.duedatesa === dueDate.duedate || sample.duedatehydro === dueDate.duedate || sample.duedate200 === dueDate.duedate || sample.duedateatt === dueDate.duedate || sample.duedateph === dueDate.duedate || sample.duedaterval === dueDate.duedate || sample.duedateresist === dueDate.duedate || sample.duedatech === dueDate.duedate || sample.duedatese=== dueDate.duedate || sample.duedatesval === dueDate.duedate || sample.duedateoc === dueDate.duedate || sample.duedateacsa === dueDate.duedate || sample.duedateav === dueDate.duedate)

          // if (job.jobnumber === sample.jobnumber && phase === sample.phaseid && dueDate.duedate === 'priority' &&
          //     (sample.prioritymds === 1 || sample.prioritymax === 1 || sample.priorityei === 1 || sample.priorityso4 === 1 || sample.priorityconsol === 1 || sample.priorityds === 1 || sample.prioritysa === 1 || sample.priorityhydro === 1 || sample.priority200 === 1 || sample.priorityatt === 1 || sample.priorityph === 1 || sample.priorityrval === 1 || sample.priorityresist === 1 || sample.prioritych === 1 || sample.priorityse=== 1 || sample.prioritysval === 1 || sample.priorityoc === 1 || sample.priorityacsa === 1 || sample.priorityav === 1)
          //   ) {
          //     console.log('priority? ' + JSON.stringify(sample))
          //   }

          if (
              (
                job.jobnumber === sample.jobnumber && phase === sample.phaseid && dueDate.duedate === 'priority' &&
                (sample.prioritymds === 1 || sample.prioritymax === 1 || sample.priorityei === 1 || sample.priorityso4 === 1 || sample.priorityconsol === 1 || sample.priorityds === 1 || sample.prioritysa === 1 || sample.priorityhydro === 1 || sample.priority200 === 1 || sample.priorityatt === 1 || sample.priorityph === 1 || sample.priorityrval === 1 || sample.priorityresist === 1 || sample.prioritych === 1 || sample.priorityse=== 1 || sample.prioritysval === 1 || sample.priorityoc === 1 || sample.priorityacsa === 1 || sample.priorityav === 1)
              ) ||
              (
                job.jobnumber === sample.jobnumber && phase === sample.phaseid && dueDate.duedate !== 'priority' &&
                (sample.prioritymds !== 1 || sample.prioritymax  !== 1 || sample.priorityei !== 1 || sample.priorityso4 !== 1 || sample.priorityconsol !== 1 || sample.priorityds !== 1 || sample.prioritysa !== 1 || sample.priorityhydro !== 1 || sample.priority200 !== 1 || sample.priorityatt !== 1 || sample.priorityph !== 1 || sample.priorityrval !== 1 || sample.priorityresist !== 1 || sample.prioritych !== 1 || sample.priorityse!== 1 || sample.prioritysval !== 1 || sample.priorityoc !== 1 || sample.priorityacsa !== 1 || sample.priorityav !== 1) &&
                (sample.duedatemds === dueDate.duedate || sample.duedatemax  === dueDate.duedate || sample.duedateei === dueDate.duedate || sample.duedateso4 === dueDate.duedate || sample.duedateconsol === dueDate.duedate || sample.duedateds === dueDate.duedate || sample.duedatesa === dueDate.duedate || sample.duedatehydro === dueDate.duedate || sample.duedate200 === dueDate.duedate || sample.duedateatt === dueDate.duedate || sample.duedateph === dueDate.duedate || sample.duedaterval === dueDate.duedate || sample.duedateresist === dueDate.duedate || sample.duedatech === dueDate.duedate || sample.duedatese=== dueDate.duedate || sample.duedatesval === dueDate.duedate || sample.duedateoc === dueDate.duedate || sample.duedateacsa === dueDate.duedate || sample.duedateav === dueDate.duedate)
              )
            ) {

            countSamples++

            if (dueDate.duedate === 'priority') {
              countPriorities++
            }

            let style = {
              width: sample.phaseid === 'P' ? 50 : 100
            }

            let styleType = {
              color: 'white',
              backgroundColor:
                sample.sampletype === 'bulk' ? 'dodgerblue' :
                sample.sampletype === 'ca' ? 'orange':
                sample.sampletype === 'spt' ? 'mediumseagreen' :
                sample.sampletype === 'smbag' ? 'slateblue' :
                sample.sampletype === 'ac' ? 'gray' :
                sample.sampletype === 'shelby' ? 'violet' : 'tomato',
              width: sample.phaseid === 'P' ? 50 : 100
            }

            //<td><Icon name='local_shipping' iconClass={sample.deliveredtime !== '' && sample.deliveredtime !== null ? 'material-icons' : 'material-icons-outlined'} id={sample.labid} onClick={showStatusInfo} /></td>
            let iconName, iconClass, iconColor

            if (props.who === 'todoTech' || props.who === 'todoManager') {

              iconName =
              sample.status === 'archived' ? 'archive' : 'local_shipping'

              iconClass =
              sample.status === 'archived' ? 'material-icons' :
              sample.deliveredtime === '' || sample.deliveredtime === null ? 'material-icons-outlined' : 'material-icons'

              iconColor = 'dodgerblue'

            } else {

              iconName =
              sample.status === 'sampled' || sample.status === 'delivered' ? 'local_shipping' :
              sample.status === 'assigned' ? 'assignment' : // phased out
              sample.status === 'archive' || sample.status === 'archived' ? 'archive' :
              sample.status === 'dispose' || sample.status === 'disposed' ? 'delete' : 'not_listed_location'

              iconClass =
              sample.status === 'sampled' || sample.status === 'assigned' || sample.status === 'archive' || sample.status === 'dispose' ? 'material-icons-outlined' : 'material-icons'

              iconColor =
              sample.status === 'dispose' || sample.status === 'disposed' ? 'tomato' : 'dodgerblue'

            }

            let rowList = sample.phaseid === 'P' ?
              (
                <tr key={sample.labid.toString()}>
                  {
                    props.who === 'todoTech' || props.who === 'todoManager' ?
                    <td><Icon name='local_shipping' iconClass={sample.deliveredtime !== '' && sample.deliveredtime !== null ? 'material-icons' : 'material-icons-outlined'} id={sample.labid} onClick={showStatusInfo} /></td> :
                    <td><Icon name={iconName} iconClass={iconClass} color={iconColor} id={sample.labid} onClick={showStatusInfo} /></td>
                  }
                  <td style={style}>{sample.location}</td>
                  <td style={style}>{sample.depth}</td>
                  <td style={styleType}>{sample.sampletype}</td>
                  <td>
                    <AssignLab
                      key={sample.labid.toString()}
                      who={props.who}
                      dueDate={dueDate.duedate}
                      sample={sample}
                      view={props.filter.view}
                      isTodo={props.todo}
                      onClick={props.addLab}
                      updateDisposed={props.updateDisposed}
                    />
                  </td>
                  <td>{sample.comments}</td>
                </tr>
              ) :
              sample.phaseid === 'C' ?
              (
                <tr key={sample.labid.toString()}>
                  {
                    props.who === 'todoTech' || props.who === 'todoManager' ?
                    <td><Icon name='local_shipping' iconClass={sample.deliveredtime !== '' && sample.deliveredtime !== null ? 'material-icons' : 'material-icons-outlined'} id={sample.labid} onClick={showStatusInfo} /></td> :
                    <td><Icon name={iconName} iconClass={iconClass} color={iconColor} id={sample.labid} onClick={showStatusInfo} /></td>
                  }
                  <td style={style}>{sample.sampleno}</td>
                  <td style={style}>{sample.location}</td>
                  <td style={style}>{sample.represents}</td>
                  <td style={style}>{sample.purpose}</td>
                  <td style={styleType}>{sample.sampletype}</td>
                  <td>
                    <AssignLab
                      key={sample.labid.toString()}
                      who={props.who}
                      dueDate={dueDate.duedate}
                      sample={sample}
                      view={props.filter.view}
                      isTodo={props.todo}
                      onClick={props.addLab}
                      updateDisposed={props.updateDisposed}
                    />
                  </td>
                  <td>{sample.comments}</td>
                </tr>
              ) : null

            return (
              rowList
            )
          }
        })

        let sampleList = phase === 'P' ?
          (
            <table>
              <tbody>
                <tr className='tableHeader'>
                  <th></th>
                  <th>Location</th>
                  <th>Depth</th>
                  <th>Type</th>
                  <th></th>
                  <th></th>
                </tr>
                {samples}
              </tbody>
            </table>
          ) :
          (
            <table>
              <tbody>
                <tr className='tableHeader'>
                  <th></th>
                  <th>SN</th>
                  <th>Location</th>
                  <th>Represents</th>
                  <th>Purpose</th>
                  <th>Type</th>
                  <th></th>
                  <th></th>
                </tr>
                {samples}
              </tbody>
            </table>
          )

        let foundSamples = countSamples > 0 ?
          (
            <div style={{marginLeft: 50}}>
              <div className='jobHeader'>
                <h3 style={{display: 'inline-block', margin: '10px 0px 0px'}}>{job.jobnumber} {job.company} - {job.city}</h3>
              </div>
              {sampleList}
            </div>
          ) : null

        return (
          foundSamples
        )

      })

      return (
        <div>
          {jobs}
        </div>
      )

      // return (
      //   <div className='phaseHeader'>
      //     <div>
      //       <h1>{phase === 'P' ? 'Investigation' : 'Construction'}</h1>
      //     </div>
      //     {jobs}
      //   </div>
      // )

    })
    // if (dueDate.duedate === 'priority') {
    //   console.log('phase: ' + phases.length)
    // }
    //console.log('countPriorities: ' + countPriorities)
    return (
      <div>
        <div className='phaseHeader'>
          <h2 style={{margin: '10px 0px 0px'}}>{dueDate.duedate === 'priority' ? 'Priorities' : formatDateMD(new Date(dueDate.duedate))}</h2>
        </div>
        {dueDate.duedate === 'priority' && countPriorities === 0 ?
          <div style={{marginLeft: 50}}>
            <div className='jobHeader'>
              <h3 style={{margin: '10px 0px 0px'}}>nothing found  ͡° ͜ʖ ͡°)</h3>
            </div>
          </div> : phases
        }
      </div>
    )

    //return phases

  })

  return dueDates

}

export default ViewByDate
