import React from  'react'

const WhatsNew = () => {
  return (
    <div style={{margin: 10}}>
      <h2>What's new?</h2>

      <h3 style={{margin: 10}}>ಠ_ಠ</h3>

    </div>
  )
}

export default WhatsNew
