import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import TestModal from 'components/utils/TestModal'
import Selects from 'components/utils/Selects'
import ViewByJob from './ViewByJob'
import ViewByList from './ViewByList'
import SentOut from 'components/menu/SentOut'

import { formatDateTime, getLocation } from 'scripts/common'
import 'styles/container.css'

const Results = (props) => {

  const [fetchedJobs, setFetchedJobs] = useState([])
  const [fetchedSamples, setFetchedSamples] = useState([])
  const [selectSample, setSelectSample] = useState({
    id: null,
    field: null,
    data: null
  })

  //const [selectView, setSelectView] = useState('status')
  const [selectJobnumber, setSelectJobnumber] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    list: false,
    sentOut: false,
    loading: true
  })

  const [isSelectedJob, setIsSelectedJob] = useState(false)

  //const changeView = () => selectView === 'normal' ? setSelectView('status') : setSelectView('normal')

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let id = td[0].textContent
    let field = tr.getAttribute('data-field')

    if (id === '' || id === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      setSelectSample({id: id, field: field})
      openAdd()

    }

  }

  const addLab = (event) => {

    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))
    let field = el.getAttribute('data-field')

    if (id === '' || id === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      setSelectSample({id: id, field: field})
      openAdd()

    }

  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openList = () => setIsModal(prevState => ({...prevState, list: true}))

  const openSentOut = () => setIsModal(prevState => ({...prevState, sentOut: true}))

  const closeAdd = () => setIsModal(prevState => ({...prevState, add: false}))

  const closeList = () => setIsModal(prevState => ({...prevState, list: false}))

  const closeSentOut = () => setIsModal(prevState => ({...prevState, sentOut: false}))

  const fetchSamples = () => {
    let jobnumber = props.filter.jobNumber
    let phase = props.filter.phaseId
    //let status = document.getElementById('selectStatus').value

    if (jobnumber !== '' && jobnumber !== null) {

      setIsSelectedJob(true)

      fetch('/api/fetchResultsJobs', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase}) //, status: status})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedJobs(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

      fetch('/api/fetchResultsSamples', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: jobnumber, phase: phase}) //, status: status})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedSamples(result)
          setIsModal(prevState => ({...prevState, loading: false}))
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    } else {
      setIsSelectedJob(false)
    }

  }

  const fetchData = () => {

    fetchSamples()

    fetch('/api/selectResultsJobs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setSelectJobnumber(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      console.log(`updated`)
    }, 60000) // time in milliseconds (ie 1 mins)
    return () => clearInterval(interval);
  }, [props.filter])

  const permissionError = () => alert('You do not have sufficient privileges')

  return (
    <>
      {props.filter.jobNumber !== '' ?
        <>
          {isModal.add ?
            <TestModal
              id={selectSample.id}
              field={selectSample.field}
              user={props.user}
              who='results'
              onClick={closeAdd}
              updateOrigin={fetchData}
            /> : null}
            {isModal.list ? <ViewByList filter={props.filter} fetchedJobs={fetchedJobs} fetchData={fetchData} user={props.user} closeModal={closeList} /> : null}
            {isModal.sentOut ? <SentOut fetchData={fetchData} user={props.user} closeModal={closeSentOut} /> : null}
            {!isModal.loading ?
            <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

              <div style={{margin: 10, flex: '1 0 auto'}}>

                <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                  <div>
                    <Icon name='list_alt' onClick={openList} />
                    <Icon name='delivery_dining' onClick={openSentOut} />
                    <Icon name='refresh' onClick={fetchData} />
                  </div>

                  {fetchedSamples.length > 0 ?

                    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                      <ViewByJob
                        who={'results'}
                        fetchedJobs={fetchedJobs}
                        fetchedSamples={fetchedSamples}
                        addLab={props.user.results > 0 ? addLab : permissionError}
                        filter={props.filter}
                      />

                    </div> :
                    <p style={{margin: 10}}>No tests found.</p>

                  }

                </div>

              </div>

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }
        </> :
        <>
          <p style={{margin: 10}}>Pretty plz <b style={{fontSize: 30, color: 'tomato'}}>&hearts;</b> Select a Job Number</p>
          <img src="http://i.stack.imgur.com/SBv4T.gif" alt="this slowpoke moves" width="250" />
        </>
      }
    </>
  )
}

export default Results
