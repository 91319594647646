import React, {useState, useEffect} from 'react'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import Icon from 'components/utils/Icon'
import TestModal from 'components/utils/TestModal'

import { formatDateYMD } from 'scripts/common'

const SentOut = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isMissing, setIsMissing] = useState(false)

  const [selectSample, setSelectSample] = useState({
    id: null,
    field: null,
    data: null
  })

  const [isModal, setIsModal] = useState({
    add: false
  })

  const fetchData = () => {

    fetch('/api/selectMenuSentOut', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMenuSentOut --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const updateOrigin = () => {
    fetchData()
    props.fetchData()
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let id = td[0].textContent
    let field = tr.getAttribute('data-field')

    if (id === '' || id === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      setSelectSample({id: id, field: field})
      openAdd()

    }

  }

  const missingResults = () => setIsMissing(isMissing ? false : true)

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))
  const closeAdd = () => setIsModal(prevState => ({...prevState, add: false}))

  let listOfData = fetchedData.length === 0 ? null : fetchedData.map(data => {

    //jobnumber, phaseid, sampleno, location, represents, depth, sampletype, sentOutCompany, sentOutDeliverDate, sentOutReceiveDate

    let jobNumber = data.jobnumber === null ? '' : data.jobnumber
    let phase = data.phaseid === 'P' ? 'Invest' : data.phaseid === 'C' ? 'Const' : ''
    let sampleNo = data.sampleno === null ? '' : data.sampleno.toString()
    let location = data.location === null ? '' : data.location
    let depth = data.depth === null ? '' : data.depth
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let test = data.test === null ? '' : data.test.replace("tbllab_", "")
    let sentOutCompany = data.sentOutCompany === null ? '' : data.sentOutCompany
    let sentOutDeliverDate = data.sentOutDeliverDate === null || data.sentOutDeliverDate === '' ? '' : formatDateYMD(new Date(data.sentOutDeliverDate))
    let sentOutReceiveDate = data.sentOutReceiveDate === null || data.sentOutReceiveDate === '' ? '' : formatDateYMD(new Date(data.sentOutReceiveDate))

    if (
      ((isMissing && sentOutReceiveDate === '') || !isMissing) &&
      (searchValue === '' ||
      jobNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      phase.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleNo.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      location.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      depth.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleType.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      test.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sentOutCompany.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sentOutDeliverDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sentOutReceiveDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
    ) {
      return (
        <tr key={data.id.toString()} data-field={`status${test}`} onClick={selectRow}>
          <td style={{display: 'none'}}>{data.labid}</td>
          <td>{jobNumber} {phase}</td>
          <td>{phase === 'Invest' ? `${location} @ ${depth}` : phase === 'Const' ? `SN ${sampleNo},  ${location}` : `phaseId?`}</td>
          <td>{sampleType}</td>
          <td>{test}</td>
          <td>{sentOutCompany}</td>
          <td>{sentOutDeliverDate}</td>
          <td>{sentOutReceiveDate}</td>
        </tr>
      )
    }

  })

  let content = (
    <div>
      {isModal.add ?
        <TestModal
          id={selectSample.id}
          field={selectSample.field}
          user={props.user}
          who='results'
          onClick={closeAdd}
          updateOrigin={updateOrigin}
        /> : null}

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Icon name='error' color={isMissing ? 'tomato' : 'gray'} onClick={missingResults} /><span>Missing</span>
      </div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          {fetchedData.length > 0 ?
            <table>

              <thead>
                <tr>
                  <th>JN</th>
                  <th>Location</th>
                  <th>Type</th>
                  <th>Test</th>
                  <th>Company</th>
                  <th>Sent</th>
                  <th>Receive</th>
                </tr>
              </thead>

              <tbody>
                {listOfData}
              </tbody>

            </table> : <p>Nothing has been sent out.</p>
          }

        </div>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default SentOut
