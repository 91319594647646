import React from 'react'

const SampleStatus = (props) => {

  let style = {
    fontSize: 20
  }

  // let select = props.who === 'todoTech' || props.who === 'todoManager' || props.who === 'results' ?
  //   (
  //     <div>
  //       <label>Status
  //         <select style={style} className="select"  pattern="[a-zA-Z0-9]{1,}" name='status' onInput={props.onInput} onChange={props.onChange} defaultValue={props.status}>
  //           <option value="started">Started</option>
  //           <option value="completed">Completed</option>
  //           {props.who === 'todoManager' || props.who === 'results' ? <option value="reviewed">Reviewed</option> : null}
  //         </select>
  //       </label>
  //     </div>
  //   ) :
  //   <div>
  //     <h3>Error: who unknown. Status not rendered.</h3>
  //   </div>

  return (
    <div>
      <label>Status
        <select style={style} className="select"  pattern="[a-zA-Z0-9]{1,}" name='status' onInput={props.onInput} onChange={props.onChange} defaultValue={props.status}>
          <option value="started">Started</option>
          <option value="completed">Completed</option>
          {props.who === 'todoManager' || props.who === 'results' ? <option value="reviewed">Reviewed</option> : null}
          <option value="notRun">Not Run</option>
        </select>
      </label>
    </div>
  )
}

export default SampleStatus
