import React, { useState } from 'react'
import Icon from 'components/utils/Icon'

import { formatDateYMD } from 'scripts/common'

const SentOut = (props) => {

  const [isSentOut, setIsSentOut] = useState(false)

  const addSentOut = () => setIsSentOut(true)

  let content = isSentOut || (props.data.sentOutCompany !== null && props.data.sentOutCompany !== '') ?
    <div style={{borderBottom: '1px solid #d3d3d3', margin: 10}}>

      <div>
        <label>Sent To</label>
        <div>
          <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='sentOutCompany' onInput={props.onInput} onChange={props.onChange}>{props.data.sentOutCompany}</textarea>
        </div>
      </div>

      <div>
        <label>Sent Date</label>
        <div>
          <input className='input' type="date" pattern="{1,}" name='sentOutDeliverDate' onInput={props.onInput} onChange={props.onChange} defaultValue={props.data.sentOutDeliverDate === '' || props.data.sentOutDeliverDate === null ? null : formatDateYMD(new Date(props.data.sentOutDeliverDate))} required />
        </div>
      </div>

      <div>
        <label>Received Date</label>
        <div>
          <input className='input' type="date" pattern="{1,}" name='sentOutReceiveDate' onInput={props.onInput} onChange={props.onChange} defaultValue={props.data.sentOutReceiveDate === '' || props.data.sentOutReceiveDate === null ? null : formatDateYMD(new Date(props.data.sentOutReceiveDate))} required />
        </div>
      </div>     

    </div> :
    <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white', borderRadius: 5, borderBottom: '1px solid #d3d3d3', margin: 10}}>
      <span><b>Sent Out?</b></span><Icon name='local_shipping' onClick={addSentOut} />
    </div>

  return content
}

export default SentOut
