import React, {useState, useEffect} from 'react'
import Description from 'components/menu/Description'
import Color from 'components/menu/Color'

import { formatDateTime, getLocation } from 'scripts/common'

const SampleDescription = (props) => {

  const data = props.data[0]
  const [fetchedDescriptions, setFetchedDescriptions] = useState([])
  const [fetchedColors, setFetchedColors] = useState([])

  const [isModal, setIsModal] = useState({
    description: false,
    color: false
  })

  // const [isValidated, setIsValidated] = useState({
  //   description: '',
  //   color: ''
  // })

  const fetchData = () => {

    fetch('/api/selectDescription', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setFetchedDescriptions(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

    fetch('/api/selectColor', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setFetchedColors(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const updateDescription = () => {

    let d = document
    let description = d.getElementById('sampleDescription').value
    // //let uscs = d.getElementById('sampleUscs').value
    let color = d.getElementById('sampleColor').value
    // //let additionalColor = d.getElementById('sampleAdditionalColor').value

    getLocation(function(latlng){

      fetch('/api/updateDescription', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          labid: data.labid,
          description: description,
          color: color,
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: latlng.lat,
          lng: latlng.lng
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    })
  }

  const openDescription = () => setIsModal(prevState => ({...prevState, description: true}))

  const openColor = () => setIsModal(prevState => ({...prevState, color: true}))

  const closeDescription = () => setIsModal(prevState => ({...prevState, description: false}))

  const closeColor = () => setIsModal(prevState => ({...prevState, color: false}))

  const selectDescription = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    document.getElementById('sampleDescription').value = td[0].textContent

    //setIsValidated(prevState => ({...prevState, description: td[0].textContent}))
    updateDescription()
    closeDescription()

  }

  const selectColor = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    document.getElementById('sampleColor').value = td[0].textContent

    //setIsValidated(prevState => ({...prevState, color: td[0].textContent}))
    updateDescription()
    closeColor()

  }

  // let descriptionOptions = fetchedDescriptions.map(description => (
  //   <option
  //     value={description.description}
  //     disabled={
  //       data.sampletype === 'ac' && description.description === 'Asphalt' ? false :
  //       data.sampletype !== 'ac' && description.description !== 'Asphalt' ? false : true
  //     }
  //   >{description.description}</option>
  // ))
  //
  // let description = fetchedDescriptions.length > 0 ?
  //   <label>Description
  //     <select id="sampleDescription" className="select" onChange={updateDescription} defaultValue={data.description}>
  //       <option value=""></option>
  //       {descriptionOptions}
  //     </select>
  //   </label> :
  //   null
  //
  // let colorOptions = fetchedColors.map(color => (
  //   <option value={color.color}>{color.color}</option>
  // ))
  //
  // let color = fetchedColors.length > 0 ?
  //   <label>Color
  //     <select id="sampleColor" className="select" onChange={updateDescription} defaultValue={data.color}>
  //       <option value=""></option>
  //       {colorOptions}
  //     </select>
  //   </label> :
  //   null

  let description = (
    <label>Description
      <input id="sampleDescription" className='input' type="text" defaultValue={data.description} onClick={openDescription} />
    </label>
  )

  let color = (
    <label>Color
      <input id="sampleColor" className='input' type="text" defaultValue={data.color} onClick={openColor} />
    </label>
  )

  //let uscs = null

  // <label>USCS
  //   <select id="sampleUscs" className="select" onChange={updateDescription} defaultValue={props.data.uscs}>
  //     <option value=""></option>
  //     <option value="SM">SM</option>
  //     <option value="CL">CL</option>
  //   </select>
  // </label>

  // <select id="sampleAdditionalColor" className="select" onChange={updateDescription} defaultValue={props.data.additionalcolor}>
  //   <option value=""></option>
  //   <option value="to Grayish Brown">to Grayish Brown</option>
  //   <option value="to Brown">to Brown</option>
  // </select>

  let comments = props.data.comments !== null && props.data.comments !== '' ?
    (
      <div>
        <label>Comments</label>
        <div>
          <textarea id="comments" className='textArea' disabled>{data.comments}</textarea>
        </div>
      </div>
    ) : null

  return (
    <div>

      {isModal.description ? <Description selectDescription={selectDescription} closeModal={closeDescription} /> : null}
      {isModal.color ? <Color selectColor={selectColor} closeModal={closeColor} /> : null}

      {description}

      {color}

      {comments}

    </div>
  )
}

export default SampleDescription
