import React from 'react'

const Loading = () => {

  const styleLoading = {
    display: 'block',
    position: 'fixed',
    zIndex: 9,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'transparent'
  }

  const styleLoadingCircle = {
    display: 'block',
    fontSize: 30,
    //border: '16px solid #f3f3f3',
    //borderRadius: '50%',
    //borderTop: '16px solid #3498db',
    //width: '60px',
    //height: '60px',
    position: 'fixed',
    left: '50%',
    top: '50%',
    //animation: 'spin 2s linear infinite',
    zIndex: 10
  }

  return (
    <div style={styleLoading}><div style={styleLoadingCircle}>Loading...</div></div>
  )
}
export default Loading
