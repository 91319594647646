import React, {useState, useEffect} from 'react';
import LeftPanel from 'components/utils/LeftPanel'
import Login from 'components/Login'
import NavBar from 'components/utils/NavBar'
import Container from 'components/main/Container'
import Icon from 'components/utils/Icon'
import Job from 'components/menu/Job'
import User from 'components/menu/User'
import DateRange from 'components/menu/DateRange'

import './styles/app.css';
//import '../node_modules/material-icons/iconfont/material-icons.css';

import { formatDateTime, determineDate, getLocation, addActivity, getDeviceType } from 'scripts/common'
import { showNotification, test } from 'scripts/notification'

function App() {

  let officeLat = 33.84789303492371 // albus office
  let officeLng = -117.86229291534426

  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [userDetails, setUserDetails] = useState(null)
  const [isError, setIsError] = useState(false)
  const [isModal, setIsModal] = useState({
    job: false,
    user: false,
    date: false
  })

  const [filter, setFilter] = useState({
    jobNumber: '',
    phaseId: 'All',
    view: 'dueDate',
    user: '',
    startDate: '',
    endDate: '',
    dateName: '', // for display purposes below
    lat: '',
    lng: ''
  })

  useEffect(() => {
    const interval = setInterval(() => {
      //console.log(`user: ${JSON.stringify(userDetails)}`)
      if (userDetails !== null) showNotification(userDetails.id)

    }, 3600000) // time in milliseconds (ie 1 hr)
    return () => clearInterval(interval);
  }, [userDetails])

  const [component, setComponent] = useState('Home')
  const [componentManage, setComponentManage] = useState('Home')

  const selectComponent = (o) => {
    //console.log(`o: ${o.getAttribute('data-name')}`);
    setComponent(o.getAttribute('data-name'))
  }

  const selectComponentManage = (e) => {
    //console.log(`derp: ${e.currentTarget.getAttribute('data-id')}`)
    setComponentManage(e.currentTarget.getAttribute('data-id'))
  }
  const login = () => {

    const user = document.getElementById("loginname").value
    const pw = document.getElementById("loginpw").value

    fetch('/api/login', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({user: user, pw: pw})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(JSON.stringify(result))
        if (result.length === 0) {
          //alert('Incorrect login credentials.')
          setIsError(true)
        }
        else {
          setUserDetails({...result[0], device: getDeviceType()})
          setIsLoggedIn(true)
          setIsError(false)

          //addActivity('login', '', result[0].id)

          fetch('/api/updateLastLogin', {
            method: 'post',
            headers: {
              'Accept': 'application/json, text/plain, */*',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              id: result[0].id,
              time: formatDateTime(new Date())
            })
          })
          .then(res=>res.json())
          .then(
            (result) => {
              //console.log('result: ' + result)
            },
            (error) => {
              console.log('Error: updateLastLogin --> ' + error)
            }
          )

        }

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setFilter(prevState => ({...prevState, [name]: state ? value : null}))

    //if (filter.startDate !== null && filter.startDate !== '' && filter.endDate !== null && filter.endDate !== '') closeModal()
  }

  const selectJob = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (td[1].textContent === '' || td[1].textContent === null) {
      alert('Info has not been setup. Contact a manager.')
    } else {
      setFilter(prevState =>({...prevState,jobNumber: td[0].textContent}))
      closeModal()
    }

  }

  const selectPhase = (e) => setFilter(prevState => ({...prevState, phaseId: e.target.textContent === 'Invest' ? 'P' : e.target.textContent === 'Const' ? 'C' : 'All'}))

  const selectView = (e) => setFilter(prevState => ({...prevState, view: e.target.textContent === 'By Status' ? 'status' : e.target.textContent === 'By Due Date' ? 'dueDate' : 'normal'}))

  const clearJob = () => {

    //let lat = 33.84789303492371 // albus office
    //let lng = -117.86229291534426

    setFilter(prevState =>
      ({...prevState,
        jobNumber: '',
        proposal: '',
        lat: officeLat,
        lng: officeLng
      })
    )
    closeModal()

  }

  const selectUser = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    setFilter(prevState => ({...prevState, user: td[0].textContent}))
    closeModal()

  }

  const selectDate = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let filter = determineDate(td[0].textContent)

    setFilter(prevState => ({...prevState, startDate: filter.startDate, endDate: filter.endDate, dateName: td[0].textContent}))
    closeModal()

  }

  const selectDateAlt = (start, end) => { // og the date picker had validate, which setFilter onInput. so chart, management tools, etc updated on input :/ better to confirm

    setFilter(prevState => ({...prevState, startDate: start, endDate: end, dateName: ''}))
    closeModal()

  }

  const closeModal = () => setIsModal(prevState => ({...prevState, job: false, user: false, date: false}))
  const openJob = () => setIsModal(prevState => ({...prevState, job: true}))
  const openUser = () => setIsModal(prevState => ({...prevState, user: true}))
  const openDate = () => setIsModal(prevState => ({...prevState, date: true}))

  return (
    <div>
      {isLoggedIn ?

        <div style={{display: 'flex', flexFlow: 'column', height: '100vh'}}>

          {isModal.job ? <Job selectJob={selectJob} clearJob={clearJob} selectPhase={selectPhase} selectView={selectView} filter={filter} closeModal={closeModal} /> : null}
          {isModal.user ? <User selectUser={selectUser} closeModal={closeModal} /> : null}
          {isModal.date ? <DateRange selectDate={selectDate} selectDateAlt={selectDateAlt} closeModal={closeModal} filter={filter} /> : null}

          <NavBar component={component} user={userDetails} onClick={selectComponent} />

          <div style={{flex: '1', overflow: 'auto'}}>

            <div style={{display: 'flex', height: '100%', width: '100%', overflow: 'auto'}}>

              {component === 'Manage' ?

              <div style={{flex: '0 1 auto', height: '100%'}}>
                <LeftPanel selectComponent={selectComponentManage} componentManage={componentManage} user={userDetails} />
              </div> : null

              }

              <div style={{flex: '1 0 auto', overflow: 'auto'}}>

                <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

                  <div style={{display: 'flex'}}>

                    <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openJob}>
                      <Icon name='search' />
                      <span style={{verticalAlign: 'base-line'}}>
                        {filter.jobNumber !== '' ? filter.jobNumber : 'Select a Job,'} {filter.phaseId === 'P' ? 'Investigation,' : filter.phaseId === 'C' ? 'Construction,' : 'All,'} {filter.view === 'status' ? 'Tests By Status' : 'Tests By Group'}
                      </span>
                    </div>

                    {component === 'Manage' ?

                      <>

                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openUser}>
                          <Icon name='person_search' />
                          {
                            filter.user !== '' ?
                            <span style={{verticalAlign: 'base-line'}}>{filter.user}</span> :
                            'Select a user'
                          }
                        </div>

                        <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openDate}>
                          <Icon name='calendar_today' />
                          {
                            filter.startDate !== '' || filter.endDate !== '' ?
                            <span style={{verticalAlign: 'base-line'}}>
                              {
                                filter.dateName !== '' ?
                                `${filter.dateName}` :
                                filter.startDate === filter.endDate ?
                                `${filter.startDate}` :
                                `${filter.startDate} - ${filter.endDate}`
                              }
                            </span> :
                            'Select a Date or Date Range'
                          }

                        </div>

                      </> : null

                    }

                  </div>

                  <div style={{flex: '1', overflow: 'auto'}}>

                    <Container component={component} componentManage={componentManage} user={userDetails} filter={filter} />

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div> : <Login isError={isError} onClick={login} />
      }
    </div>
  )
}

export default App;
