import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Ei = (props) => {

  const data = props.data[0]
  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    wetSoilAndRingWt: null,
    ringWt: null,
    moistureContent: null,
    initialTareNo: data.initialTareNo,
    initialTareWt: data.initialTareWt,
    initialWetSoilAndTareWt: data.initialWetSoilAndTareWt,
    initialDrySoilAndTareWt: data.initialDrySoilAndTareWt,
    finalTareNo: data.finalTareNo,
    finalTareWt: data.finalTareWt,
    finalWetSoilAndTareWt: data.finalWetSoilAndTareWt,
    finalDrySoilAndTareWt: data.finalDrySoilAndTareWt,
    rack: data.rack,
    initialDialReading: data.initialDialReading,
    finalDialReading: data.finalDialReading,
    totalWt: data.totalWt,
    soilWt: data.soilWt,
    status: data.status,
    status: data.status,
    notes: data.notes,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const fetchData = () => {

    fetch('/api/selectEiTrials', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({eiId: data.id})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
        setIsValidated(prevState => ({...prevState, id: null, wetSoilAndRingWt: null, ringWt: null, moistureContent: null}))
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

    if (name === 'initialDialReading' && fetchedData.length === 0) alert('Please add the trial for this sample.')
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateEi', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            initialTareNo: isValidated.initialTareNo,
            initialTareWt: isValidated.initialTareWt,
            initialWetSoilAndTareWt: isValidated.initialWetSoilAndTareWt,
            initialDrySoilAndTareWt: isValidated.initialDrySoilAndTareWt,
            finalTareNo: isValidated.finalTareNo,
            finalTareWt: isValidated.finalTareWt,
            finalWetSoilAndTareWt: isValidated.finalWetSoilAndTareWt,
            finalDrySoilAndTareWt: isValidated.finalDrySoilAndTareWt,
            rack: isValidated.rack,
            initialDialReading: isValidated.initialDialReading,
            finalDialReading: isValidated.finalDialReading,
            totalWt: isValidated.totalWt,
            soilWt: isValidated.soilWt
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'ei', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusei',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
                // if (isValidated.status === 'completed') {
                //   props.closeTestModal()
                // }
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (fetchedData.length < 1) { // check if trials exist
        alert('You have not added any trials...')
      }else if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.initialTareNo === '' || isValidated.initialTareNo === null) {
        alert('Please provide an initial tare Number.')
      } else if (isValidated.initialTareWt === '' || isValidated.initialTareWt === null) {
        alert('Please provide an initial tare weight.')
      } else if (isValidated.initialWetSoilAndTareWt === '' || isValidated.initialWetSoilAndTareWt === null) {
        alert('Please provide an initial wet soil weight.')
      } else if (isValidated.initialDrySoilAndTareWt === '' || isValidated.initialDrySoilAndTareWt === null) {
        alert('Please provide an initial dry soil weight.')
      } else if (isValidated.finalTareNo === '' || isValidated.finalTareNo === null) {
        alert('Please provide an final tare Number.')
      } else if (isValidated.finalTareWt === '' || isValidated.finalTareWt === null) {
        alert('Please provide an final tare weight.')
      } else if (isValidated.finalWetSoilAndTareWt === '' || isValidated.finalWetSoilAndTareWt === null) {
        alert('Please provide an final wet soil weight.')
      } else if (isValidated.finalDrySoilAndTareWt === '' || isValidated.finalDrySoilAndTareWt === null) {
        alert('Please provide an final dry soil weight.')
      } else if (isValidated.rack === '' || isValidated.rack === null) {
        alert('Please provide a rack number.')
      } else if (isValidated.initialDialReading === '' || isValidated.initialDialReading === null) {
        alert('Please provide an initial dial reading.')
      } else if (isValidated.finalDialReading === '' || isValidated.finalDialReading === null) {
        alert('Please provide a final dial reading.')
      } else if (isValidated.totalWt === '' || isValidated.totalWt === null) {
        alert('Please provide a total weight.')
      } else if (isValidated.soilWt === '' || isValidated.soilWt === null) {
        alert('Please provide a soil weight.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const addTrial = () => {

    if (!props.user.todo > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.wetSoilAndRingWt === '' || isValidated.wetSoilAndRingWt === null) {
      alert('Please provide a wet soil weight.')
    } else if (isValidated.ringWt === '' || isValidated.ringWt === null) {
      alert('Please provide a ring weight.')
    } else if (isValidated.moistureContent === '' || isValidated.moistureContent === null) {
      alert('Please provide a moisture content.')
    } else {

      getLocation(function(latlng){

        fetch('/api/addEiTrial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            eiId: data.id,
            wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
            ringWt: isValidated.ringWt,
            moistureContent: isValidated.moistureContent,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'ei trial', 'add', description, props.user.username)

            fetchData()
            isChanged.current = false
            closeModal()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const editTrial = () => {

    if (!isChanged.current) {
      nothingChanged()
    } else if (!props.user.todo > 1) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isValidated.wetSoilAndRingWt === '' || isValidated.wetSoilAndRingWt === null) {
      alert('Please provide a wet soil weight.')
    } else if (isValidated.ringWt === '' || isValidated.ringWt === null) {
      alert('Please provide a ring weight.')
    } else if (isValidated.moistureContent === '' || isValidated.moistureContent === null) {
      alert('Please provide a moisture content.')
    } else {

      getLocation(function(latlng){

        fetch('/api/editEiTrial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id,
            wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
            ringWt: isValidated.ringWt,
            moistureContent: isValidated.moistureContent,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //fetchData()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'ei trial', 'edit', description, props.user.username)

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
                ringWt: isValidated.ringWt,
                moistureContent: isValidated.moistureContent
              } :
              data
            ))
            isChanged.current = false
            closeModal()

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const deleteTrial = (event) => {

    if (!props.user.todo > 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (window.confirm('This will delete the trial permanently. Proceed?')) {

      fetch('/api/deleteEiTrial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: isValidated.id})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //fetchData()

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'ei trial', 'delete', description, props.user.username)

          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        id: fetchedData[i].id,
        wetSoilAndRingWt: fetchedData[i].wetSoilAndRingWt,
        ringWt: fetchedData[i].ringWt,
        moistureContent: fetchedData[i].moistureContent
      }))
      openEdit()

    }

  }

  const missingLocation = () => alert('Missing latitude and longitude.')

  const viewEntryLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.entrylat},${isValidated.entrylng}`)

  const viewModLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.modlat},${isValidated.modlng}`)

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))
  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const changedData = () => isChanged.current = true

  const closeModal = () => {

    const update = () => {
      isChanged.current = false
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      setIsValidated(prevState => ({...prevState,
        id: null,
        wetSoilAndRingWt: null,
        ringWt: null,
        moistureContent: null
      }))
    }

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Close anyways?')) {
        update()
      }
    } else {
      update()
    }
  }

  let trials = fetchedData.map((data, i) => {

    let ringFactor = 0.3016
    let sg = 2.7
    let wtOfSoil = data.wetSoilAndRingWt - data.ringWt
    let wetDensity = Math.round(10*(wtOfSoil * ringFactor))/10
    let dryDensity = Math.round(10*wetDensity/(1+(data.moistureContent/100)))/10
    let saturation = Math.round(10*(data.moistureContent/100)/((62.4/dryDensity)-(1/sg))*100)/10

    return (
      <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
        <td style={{display: 'none'}}>{i}</td>
        <td>{data.wetSoilAndRingWt}</td>
        <td>{data.ringWt}</td>
        <td>{data.moistureContent}</td>
        <td>{wetDensity}</td>
        <td>{dryDensity}</td>
        <td>{saturation}</td>
        <td>{data.entryby}</td>
      </tr>
    )
  })

  let tableOfTrials = trials.length > 0 ?
  (
    <table>
      <thead>
        <tr>
          <th>Wet Soil and Ring Wt</th>
          <th>Ring Wt</th>
          <th>Moisture Content</th>
          <th>Wet Density</th>
          <th>Dry Density</th>
          <th>Saturation</th>
          <th>By</th>
        </tr>
      </thead>
      <tbody>
        {trials}
      </tbody>
    </table>
  ) :
  <h4>No Trials started.</h4>

  let entry = isValidated.entryby !== null && isValidated.entryby !== '' ?
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    {isValidated.entrylat !== null && isValidated.entrylat !== '' ?
      <Icon name='location_on' onClick={viewEntryLocation} /> :
      <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
    }
    <small>Entry by: {isValidated.entryby} @ {formatDateTime(new Date(isValidated.entrytime))}</small>
  </div> :
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <small>Not entered</small>
  </div>

  let mod = isValidated.modby !== null && isValidated.modby !== '' ?
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    {isValidated.modlat !== null && isValidated.modlat !== '' ?
      <Icon name='location_on' onClick={viewModLocation} /> :
      <Icon name='wrong_location' color='tomato' onClick={missingLocation} />
    }
    <small>Mod by: {isValidated.modby} @ {formatDateTime(new Date(isValidated.modtime))}</small>
  </div> :
  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
    <small>Not modified</small>
  </div>

  let ringFactor = 0.3016
  let sg = 2.7
  let wtOfSoil = isValidated.wetSoilAndRingWt - isValidated.ringWt
  let wetDensity = Math.round(10*(wtOfSoil * ringFactor))/10
  let dryDensity = Math.round(10*wetDensity/(1+(isValidated.moistureContent/100)))/10
  let saturation = Math.round(10*(isValidated.moistureContent/100)/((62.4/dryDensity)-(1/sg))*100)/10

  let percentOfRock = Math.round(10*((isValidated.totalWt - isValidated.soilWt) / isValidated.totalWt) * 100)/10

  let wtOfWater, wtOfDrySoil
  wtOfWater = isValidated.initialWetSoilAndTareWt - isValidated.initialDrySoilAndTareWt
  wtOfDrySoil = isValidated.initialDrySoilAndTareWt - isValidated.initialTareWt
  let initialMoistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

  wtOfWater = isValidated.finalWetSoilAndTareWt - isValidated.finalDrySoilAndTareWt
  wtOfDrySoil = isValidated.finalDrySoilAndTareWt - isValidated.finalTareWt
  let finalMoistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

  let expansionIndex = isValidated.finalDialReading !== null && isValidated.finalDialReading !== '' && isValidated.initialDialReading !== null && isValidated.initialDialReading !== '' ?
  Math.round((isValidated.finalDialReading - isValidated.initialDialReading) * 1000) : data.ei !== null && data.ei !== '' ? data.ei : ''
  let expansionPotential = expansionIndex !== '' ?
  expansionIndex < 21 ? 'Very Low' : expansionIndex < 51 ? 'Low' : expansionIndex < 91 ? 'Medium' : expansionIndex < 131 ? 'High' : 'Very High' : ''

  let modalContent = (
    <div>

      {isModal.edit ?
        <>
          {entry}
          {mod}
        </> : null
      }

      <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <Icon name='info' iconClass='material-icons-outlined' color='orange' />
        <small>SG 2.7 (1/SG = 0.3745)</small>
      </div>

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <label>Wet Soil and Ring Wt (g)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='wetSoilAndRingWt' onInput={validate} onChange={changedData} defaultValue={isValidated.wetSoilAndRingWt} required />
        </label><br />

        <label>Ring Wt (g)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='ringWt' onInput={validate} onChange={changedData} defaultValue={isValidated.ringWt} required />
        </label><br />

        <label>Moisture Content (%)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='moistureContent' onInput={validate} onChange={changedData} defaultValue={isValidated.moistureContent} required />
        </label><br />

        <label><b>Wet Density (pcf)</b>
          <input style={{width: 75}} className='input' type="text" value={wetDensity} disabled />
        </label><br />

        <label><b>Dry Density (pcf)</b>
          <input style={{width: 75}} className='input' type="text" value={dryDensity} disabled />
        </label><br />

        <label><b>Saturation (%)</b>
          <input style={{width: 75}} className='input' type="text" value={saturation} disabled />
        </label>

      </div>

    </div>
  )

  return (

    <div style={{display: 'inline-block', overflowX: 'auto', width: '100%', height: '100%'}}>

      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addTrial : isModal.edit ? editTrial : null} delete={isModal.edit ? deleteTrial : null} content={modalContent} closeModal={closeModal} /> : null}

      <div style={{display: 'inline-block', textAlign: 'center', margin: 10, overflowX: 'auto', verticalAlign: 'top'}}>

        <div>
          <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>
            <h3>Trials</h3>
            {tableOfTrials}
          </div>
        </div>

        <div style={{textAlign: 'center', marginTop: 10}}>
          <Icon name='add_circle' onClick={openAdd} />
        </div>

      </div>

      <div style={{display: 'inline-block', textAlign: 'center', margin: 10, overflowX: 'auto', verticalAlign: 'top'}}>

        <div style={{display: 'inline-block', textAlign: 'right'}}>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Rock (#4 sieve)</h3>
          </div>

          <label>Total Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='totalWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.totalWt} required />
          </label><br />

          <label>Soil Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='soilWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.soilWt} required />
          </label><br />

          <label><b>% of Rock</b>
            <input style={{width: 75}} className='input' type="text" value={percentOfRock} disabled />
          </label><br />

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Moistures</h3>
          </div>

          <div style={{textAlign: 'right'}}>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>Initial</label>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>Final</label>
          </div>

          <div>
            <label>Tare No</label>
            <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='initialTareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.initialTareNo} required />
            <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='finalTareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.finalTareNo} required />
          </div>

          <label>Tare Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='initialTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.initialTareWt} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='finalTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.finalTareWt} required />
          </label><br />

          <label>Wet Soil and Tare Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='initialWetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.initialWetSoilAndTareWt} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='finalWetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.finalWetSoilAndTareWt} required />
          </label><br />

          <label>Dry Soil and Tare Wt (g)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='initialDrySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.initialDrySoilAndTareWt} required />
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='finalDrySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.finalDrySoilAndTareWt} required />
          </label><br />

          <label><b>Moisture Content (%)</b>
            <input style={{width: 75}} className='input' type="text" value={initialMoistureContent} disabled />
            <input style={{width: 75}} className='input' type="text" value={finalMoistureContent} disabled />
          </label><br />

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Expansion</h3>
          </div>

          <label>Rack
            <select style={{width: 50}} className='select' pattern=".{1,}" name='rack' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.rack} required>
              <option value={null}></option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
            </select>
          </label><br />

          <div style={{textAlign: 'right'}}>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>Initial</label>
            <label style={{width: 80, margin: 10, display: 'inline-block'}}>Final</label>
          </div>

          <div>
            <label>Dial Reading</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{3}" name='initialDialReading' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.initialDialReading} required />
            <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{3}" name='finalDialReading' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.finalDialReading} required />
          </div>

          <label><b>Expansion Index</b>
            <input style={{width: 75}} className='input' type="text" value={expansionIndex} disabled />
          </label><br />

          <label><b>Expansion Potential</b>
            <input className='input' type="text" value={expansionPotential} disabled />
          </label>

        </div>

        <div style={{textAlign: 'center', marginTop: 10}}>

          <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

          <SentOut data={data} onInput={validate} onChange={props.changedSample} />

          <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

          {props.user.todo > 1 ?
            <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
            null
          }

        </div>

      </div>

    </div>

  )
}

export default Ei
