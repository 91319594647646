import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Max = (props) => {

  const data = props.data[0]
  const [fetchedData, setFetchedData] = useState([])

  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })

  const isChanged = useRef(false)

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    moistureAdded: null,
    wetSoilAndRingWt: null,
    ringWt: null,
    tareNo: null,
    tareWt: null,
    wetSoilAndTareWt: null,
    drySoilAndTareWt: null,
    isCheckPoint: data.isCheckPoint === 1 ? true : false,
    isOtherCompany: data.isOtherCompany === 1 ? true : false,
    isFieldUse: data.isFieldUse === 1 ? true : false,
    method: data.method,
    totalWt: data.totalWt,
    retainWt4: data.retainWt4,
    retainWt38: data.retainWt38,
    retainWt34: data.retainWt34,
    curve: data.curve,
    optd: data.optd,
    optm: data.optm,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const canvasRef = useRef(null)
  // const loadAppliedRef = useRef(null)
  // const dialReadingRef = useRef(null)

  const fetchData = () => {

    fetch('/api/selectMaxTrials', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({maxId: data.id})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)

        setIsValidated(prevState => ({...prevState,
          id: null,
          moistureAdded: null,
          wetSoilAndRingWt: null,
          ringWt: null,
          tareNo: null,
          tareWt: null,
          wetSoilAndTareWt: null,
          drySoilAndTareWt: null,
        }))

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    buildGraph()
  })

  const addTrial = () => {

    if (isValidated.moistureAdded === '' || isValidated.moistureAdded === null) {
      alert('Please provide the moisture added.')
    } else if (isValidated.wetSoilAndRingWt === '' || isValidated.wetSoilAndRingWt === null) {
      alert('Please provide a wet soil and ring weight.')
    } else if (isValidated.ringWt === '' || isValidated.ringWt === null) {
      alert('Please provide a ring weight.')
    } else {

      getLocation(function(latlng){

        fetch('/api/addMaxTrial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            maxId: data.id,
            moistureAdded: isValidated.moistureAdded,
            wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
            ringWt: isValidated.ringWt,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            wetSoilAndTareWt: isValidated.wetSoilAndTareWt,
            drySoilAndTareWt: isValidated.drySoilAndTareWt,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'max', 'update', description, props.user.username)

            fetchData()
            isChanged.current = false
            closeModal()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const editTrial = () => {

    if (isValidated.moistureAdded === '' || isValidated.moistureAdded === null) {
      alert('Please provide the moisture added.')
    } else if (isValidated.wetSoilAndRingWt === '' || isValidated.wetSoilAndRingWt === null) {
      alert('Please provide a wet soil and ring weight.')
    } else if (isValidated.ringWt === '' || isValidated.ringWt === null) {
      alert('Please provide a ring weight.')
    } else {

      getLocation(function(latlng){

        fetch('/api/editMaxTrial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id,
            moistureAdded: isValidated.moistureAdded,
            wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
            ringWt: isValidated.ringWt,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            wetSoilAndTareWt: isValidated.wetSoilAndTareWt,
            drySoilAndTareWt: isValidated.drySoilAndTareWt,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'max trial', 'edit', description, props.user.username)

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moistureAdded: isValidated.moistureAdded,
                wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
                ringWt: isValidated.ringWt,
                tareNo: isValidated.tareNo,
                tareWt: isValidated.tareWt,
                wetSoilAndTareWt: isValidated.wetSoilAndTareWt,
                drySoilAndTareWt: isValidated.drySoilAndTareWt
              } :
              data
            ))
            isChanged.current = false
            closeModal()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const deleteTrial = (event) => {

    if (!props.user.todo > 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (window.confirm('This will delete the trial permanently. Proceed?')) {

      fetch('/api/deleteMaxTrial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: isValidated.id})
      })
      .then(res=>res.json())
      .then(
        (result) => {

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'max trial', 'delete', description, props.user.username)

          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateMax', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            isCheckPoint: isValidated.isCheckPoint,
            isOtherCompany: isValidated.isOtherCompany,
            isFieldUse: isValidated.isFieldUse,
            method: isValidated.method,
            totalWt: isValidated.totalWt,
            retainWt4: isValidated.retainWt4,
            retainWt38: isValidated.retainWt38,
            retainWt34: isValidated.retainWt34,
            curve: isValidated.curve,
            optd: isValidated.optd,
            optm: isValidated.optm,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'max', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusmax',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
                // if (isValidated.status === 'completed') {
                //   props.closeTestModal()
                // }
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started' || isValidated.status === 'notRun') {
      update()
    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      //console.log('other: ' + isValidated.isOtherCompany)
      //console.log('cp: ' + isValidated.isCheckPoint)
      if (isValidated.isFieldUse && data.phaseid === 'C' && (isValidated.curve === '' || isValidated.curve === null)) {
        alert('Error: curve not found.')
      } else if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')

      } else if (isValidated.isCheckPoint) {

        if (isValidated.method === '' || isValidated.method === null) {
          alert('Please provide an ASTM method.')
        } else if (isValidated.totalWt === '' || isValidated.totalWt === null) {
          alert('Please provide a total weight.')
        } else if (isValidated.retainWt4 === '' || isValidated.retainWt4 === null) {
          alert('Please provide a retained on the #4 weight.')
        } else if (isValidated.retainWt38 === '' || isValidated.retainWt38 === null) {
          alert('Please provide a retained on the #3/8 weight.')
        } else if (isValidated.retainWt34 === '' || isValidated.retainWt34 === null) {
          alert('Please provide a retained on the #3/4 weight.')

        } else {
          update()
        }

      } else if (isValidated.isOtherCompany) {

        if (isValidated.optd === '' || isValidated.optd === null) {
          alert('Please provide an optimum density.')
        } else if ((isValidated.optm === '' || isValidated.optm === null) && description !== 'Asphalt') {
          alert('Please provide an optimum moisture.')

        } else {
          update()
        }

      } else if (isValidated.method === '' || isValidated.method === null) {
        alert('Please provide an ASTM method.')
      } else if (isValidated.totalWt === '' || isValidated.totalWt === null) {
        alert('Please provide a total weight.')
      } else if (isValidated.retainWt4 === '' || isValidated.retainWt4 === null) {
        alert('Please provide a retained on the #4 weight.')
      } else if (isValidated.retainWt38 === '' || isValidated.retainWt38 === null) {
        alert('Please provide a retained on the #3/8 weight.')
      } else if (isValidated.retainWt34 === '' || isValidated.retainWt34 === null) {
        alert('Please provide a retained on the #3/4 weight.')

      } else if (isValidated.optd === '' || isValidated.optd === null) {
        alert('Please provide an optimum density.')
      } else if ((isValidated.optm === '' || isValidated.optm === null) && description !== 'Asphalt') {
        alert('Please provide an optimum moisture.')

      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const buildGraph = () => {
    let canvas, ctx, chart //, percentConsol, loadAccum
    let backgroundColor = '#d3d3d3'
    let borderColor = '#000000'
    let pointBackgroundColor = []
    let pointBorderColor = []

    let ringFactor =
    isValidated.method === 'a' || isValidated.method === 'b' ? 30 :
    isValidated.method === 'c' ? 13.333 : null

    let wtOfSoil, wetDensity, wtOfWater, wtOfDrySoil, moistureContent, dryDensity

    let graphData = fetchedData.map(data => {

      wtOfSoil = data.wetSoilAndRingWt - data.ringWt
      wetDensity =
      isValidated.method === '' || isValidated.method === null ?
      'method?' : Math.round(10*(wtOfSoil * ringFactor))/10

      wtOfWater = data.wetSoilAndTareWt - data.drySoilAndTareWt
      wtOfDrySoil = data.drySoilAndTareWt - data.tareWt
      moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

      dryDensity =
        isValidated.method === '' || isValidated.method === null ?
        'method?' : Math.round(10*wetDensity/(1+(moistureContent/100)))/10

      pointBackgroundColor.push(backgroundColor)
      pointBorderColor.push(borderColor)
      return ({x: moistureContent, y: dryDensity})

    })

    // // plots data before submit for review purposes
    // if (isValidated.dialReading !== '' && isValidated.dialReading !== null && isValidated.loadApplied !== '' && isValidated.loadApplied !== null) {
    //   if (data.length > 0) {
    //     pointBackgroundColor.push('dodgerblue')
    //     pointBorderColor.push(borderColor)
    //
    //     data.push({x: loadAccum, y: percentConsol})
    //   }
    // }

    canvas = canvasRef.current
    ctx = canvas.getContext('2d')
    // // must destroy previous charts to prevent data carried over
    // ctx ? ctx.destory() : canvas.getContext('2d')
    chart = new window.Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [{
          label: data.phaseid === 'P' ? data.location + ' @ '+ data.depth : 'SN: ' + data.sampleno,
          data: graphData,
          borderColor: 'black',
          borderWidth: 1,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBorderColor,
          pointRadius: 5,
          pointHoverRadius: 5,
          fill: false,
          //lineTension: 0.5,
          showLine: true
        }]
      },
      options: {
        maintainAspectRatio: true,
        legend: {display: true},
        scales: {
          xAxes: [{
            type: 'linear',
            position: 'bottom',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              // min: 100,
              // max: 100000,
              callback: function (value, index, values) {
                return Number(value.toString()) //pass tick values as a string into Number function
              }
            }
            // ,
            // afterBuildTicks: function (chartObj) { //Build ticks labelling as per your need
            //   chartObj.ticks = []
            //   chartObj.ticks.push(100)
            //   chartObj.ticks.push(1000)
            //   chartObj.ticks.push(10000)
            //   chartObj.ticks.push(100000)
            // }
          }],
          yAxes: [{
            type: 'linear',
            position: 'left',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              reverse: false,
              //min: 0
            }
          }]
        }
      }
    })
  }

  const assignCurve = () => {

    if (data.phaseid === 'C' && (data.curve !== '' && data.curve !== null)) {

      setIsValidated(prevState => ({...prevState, curve: data.curve}))

    } else if (data.phaseid === 'C' && (isValidated.curve === '' || isValidated.curve === null)) {

      fetch('/api/findNextCurve', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({jobnumber: data.jobnumber})
      })
      .then(res=>res.json())
      .then(
        (result) => {

          //console.log('curves: ' + JSON.stringify(result))
          let newCurve = Math.max(...result.map(o => isNaN(o.curve) ? 0 : Number(o.curve)), 0) + 1
          //console.log('new curve: ' + newCurve)
          setIsValidated(prevState => ({...prevState, curve: newCurve}))

        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const doesCurveExistInField = (target, name, state, value) => {

    fetch('/api/doesCurveExistInField', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: data.jobnumber, curve: isValidated.curve})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('curve exists: ' +  result.length > 0 ? true : false)

        if (result.length > 0) {
          alert('This curve has been used already in the field. Speak to Lab Admin.')
          target.checked = true
        } else {
          setIsValidated(prevState => ({...prevState, [name]: state ? value : null, curve: ''}))
        }

        return (
          result.length > 0 ? true : false
        )

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    if (name === 'isCheckPoint' && isValidated.isFieldUse) {

      alert('This has been assigned for use in the Field. Speak to Lab Manager.')
      event.target.checked = event.target.checked ? false : true

    } else if (name === 'isCheckPoint' && isValidated.curve !== '' && isValidated.curve !== null) {

      alert('A curve has already been assigned. Speak to Lab Manager.')
      event.target.checked = event.target.checked ? false : true

    } else if (name === 'isFieldUse' && event.target.checked === false) {

      doesCurveExistInField(event.target, name, state, value)

    } else if (name === 'isFieldUse' && event.target.checked === true) {

      assignCurve()
      setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))

    } else {
      setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
    }

  }

  const selectRow = (e) => {

    let tr = e.target.nodeName === 'I' ? e.target.parentNode.parentNode.parentNode : e.target.nodeName === 'Button' ? e.target.parentNode.parentNode : e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //console.log(`selected: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        id: fetchedData[i].id,
        moistureAdded: fetchedData[i].moistureAdded,
        wetSoilAndRingWt: fetchedData[i].wetSoilAndRingWt,
        ringWt: fetchedData[i].ringWt,
        tareNo: fetchedData[i].tareNo,
        tareWt: fetchedData[i].tareWt,
        wetSoilAndTareWt: fetchedData[i].wetSoilAndTareWt,
        drySoilAndTareWt: fetchedData[i].drySoilAndTareWt
      }))

      openEdit()

    }

  }

  const missingLocation = () => alert('Missing latitude and longitude.')

  const viewEntryLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.entrylat},${isValidated.entrylng}`)

  const viewModLocation = () => window.open(`https://www.google.com/maps/search/?api=1&query=${isValidated.modlat},${isValidated.modlng}`)

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))
  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const changedData = () => isChanged.current = true

  const closeModal = () => {

    const update = () => {
      isChanged.current = false
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      setIsValidated(prevState => ({...prevState,
        id: null,
        moistureAdded: null,
        wetSoilAndRingWt: null,
        ringWt: null,
        tareNo: null,
        tareWt: null,
        wetSoilAndTareWt: null,
        drySoilAndTareWt: null
      }))
    }

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Close anyways?')) {
        update()
      }
    } else {
      update()
    }
  }

  let trials = fetchedData.map((data, i) => {

    let ringFactor =
    isValidated.method === 'a' || isValidated.method === 'b' ? 30 :
    isValidated.method === 'c' ? 13.333 : null

    let wtOfSoil = data.wetSoilAndRingWt - data.ringWt
    let wetDensity =
    isValidated.method === '' || isValidated.method === null ?
    'method?' : Math.round(10*(wtOfSoil * ringFactor))/10

    let wtOfWater = data.wetSoilAndTareWt - data.drySoilAndTareWt
    let wtOfDrySoil = data.drySoilAndTareWt - data.tareWt
    let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

    let dryDensity =
      isValidated.method === '' || isValidated.method === null ?
      'method?' : Math.round(10*wetDensity/(1+(moistureContent/100)))/10

    return (
      <tr key={data.id.toString()} data-id={data.id} onClick={selectRow}>
        <td style={{display: 'none'}}>{i}</td>
        <td>{data.tareNo}</td>
        <td>{data.moistureAdded}</td>
        <td>{moistureContent}</td>
        <td>{wetDensity}</td>
        <td>{dryDensity}</td>
        <td>{data.entryby}</td>
      </tr>
    )
  })

  let tableOfTrials = trials.length > 0 ?
  (
    <table>
      <thead>
        <tr>
          <th>Tare No</th>
          <th>+ M (%)</th>
          <th>MC (%)</th>
          <th>WD (pcf)</th>
          <th>DD (pcf)</th>
          <th>By</th>
        </tr>
      </thead>
      <tbody>
        {trials}
      </tbody>
    </table>
  ) :
  <h4>No trials taken.</h4>

  let ringFactor =
  isValidated.method === 'a' || isValidated.method === 'b' ? 30 :
  isValidated.method === 'c' ? 13.333 : null

  let wtOfSoil = isValidated.wetSoilAndRingWt - isValidated.ringWt
  let wetDensity = Math.round(10*(wtOfSoil * ringFactor))/10

  let wtOfWater = isValidated.wetSoilAndTareWt - isValidated.drySoilAndTareWt
  let wtOfDrySoil = isValidated.drySoilAndTareWt - isValidated.tareWt
  let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

  let dryDensity = Math.round(10*wetDensity/(1+(moistureContent/100)))/10
  //let saturation = Math.round(10*(isValidated.moistureContent/100)/((62.4/dryDensity)-(1/sg))*100)/10

  let percentOfRock4 = Math.round(10*((isValidated.retainWt4) / isValidated.totalWt) * 100)/10
  let percentOfRock38 = Math.round(10*((isValidated.retainWt38) / isValidated.totalWt) * 100)/10
  let percentOfRock34 = Math.round(10*((isValidated.retainWt34) / isValidated.totalWt) * 100)/10

  let modalContent = (
    <div style={{display: 'inline-block', textAlign: 'right'}}>

      <label>Moisture Added (%)
        <input style={{width: 75}} className='input' type="text" pattern="-?\d{1,2}" name='moistureAdded' onInput={validate} onChange={changedData} defaultValue={isValidated.moistureAdded} required />
      </label><br />

      <label>Wet Soil and Mold Wt (lb)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='wetSoilAndRingWt' onInput={validate} onChange={changedData} defaultValue={isValidated.wetSoilAndRingWt} required />
      </label><br />

      <label>Mold Wt (lb)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1,2})?" name='ringWt' onInput={validate} onChange={changedData} defaultValue={isValidated.ringWt} required />
      </label><br />

      <label><b>Wet Density (pcf)</b>
        <input style={{width: 75}} className='input' type="text" value={wetDensity} disabled />
      </label><br />

      <label>Tare No
        <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='tareNo' onInput={validate} onChange={changedData} defaultValue={isValidated.tareNo} required />
      </label><br />

      <label>Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='tareWt' onInput={validate} onChange={changedData} defaultValue={isValidated.tareWt} required />
      </label><br />

      <label>Wet Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='wetSoilAndTareWt' onInput={validate} onChange={changedData} defaultValue={isValidated.wetSoilAndTareWt} required />
      </label><br />

      <label>Dry Soil and Tare Wt (g)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='drySoilAndTareWt' onInput={validate} onChange={changedData} defaultValue={isValidated.drySoilAndTareWt} required />
      </label><br />

      <label><b>Moisture Content (%)</b>
        <input style={{width: 75}} className='input' type="text" value={moistureContent} disabled />
      </label><br />

      <label><b>Dry Density (pcf)</b>
        <input style={{width: 75}} className='input' type="text" value={dryDensity} disabled />
      </label>

    </div>
  )

  return (

    <div style={{display: 'inline-block', overflowX: 'auto', width: '100%', height: '100%'}}>

      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addTrial : isModal.edit ? editTrial : null} delete={isModal.edit ? deleteTrial : null} content={modalContent} closeModal={closeModal} /> : null}

      <div style={{display: 'flex', overflowX: 'auto', width: '100%'}}>
        <div style={{margin: 10, flex: '1 0 auto'}}>

          <h3>Sample</h3>

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            <div style={{borderBottom: '1px solid #d3d3d3', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

              <h4 style={{display: 'inline-block'}}>Check point?</h4><input style={{display: 'inline-block', margin: 10, height: 20, width: 20}} type='checkbox' pattern="{1,}" name='isCheckPoint' onInput={validate} onChange={props.changedSample} onClick={null} defaultChecked={isValidated.isCheckPoint} />

            </div>

            <div style={{borderBottom: '1px solid #d3d3d3', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

              <h4 style={{display: 'inline-block'}}>By Outside Company?</h4><input style={{display: 'inline-block', margin: 10, height: 20, width: 20}} type='checkbox' pattern="{1,}" name='isOtherCompany' onInput={validate} onChange={props.changedSample} onClick={null} defaultChecked={isValidated.isOtherCompany} />

            </div>

            {!isValidated.isOtherCompany ?
              <>
                <label>Method
                  <select className='select' pattern=".{1,}" name='method' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.method} required>
                    <option value=""></option>
                    <option value="a">A - 4</option>
                    <option value="b">B - 3/8</option>
                    <option value="c">C - 3/4</option>
                  </select>
                </label><br />

                <label>Total Weight (g)
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='totalWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.totalWt} required />
                </label><br />

                <label>Rock retained on #4 (g)
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='retainWt4' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retainWt4} required />
                  <input style={{width: 75}} className='input' type="text" value={percentOfRock4} disabled />
                </label><br />

                <label>Rock retained on #3/8 (g)
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='retainWt38' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retainWt38} required />
                  <input style={{width: 75}} className='input' type="text" value={percentOfRock38} disabled />
                </label><br />

                <label>Rock retained on #3/4 (g)
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='retainWt34' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.retainWt34} required />
                  <input style={{width: 75}} className='input' type="text" value={percentOfRock34} disabled />
                </label><br />
              </> : null
            }

            {!isValidated.isCheckPoint ?
              <>
                {data.phaseid === 'C' ?
                  <>
                    <label>Curve
                      <input style={{width: 75}} className='input' type="text" defaultValue={isValidated.curve} disabled />
                    </label><br />
                  </> : null
                }

                <label>Optimum Dry Density (pcf)
                  <input style={{width: 75}} className='input' type="text" pattern="\d{2,3}(\.\d{1})?" name='optd' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.optd} required />
                </label><br />

                {data.sampletype !== 'ac' ?

                  <label>Optimum Moisture Content (%)
                    <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='optm' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.optm} required />
                  </label> : <small>Sample type is asphalt, therefore no optimum moisture.</small>

                }
              </> : null
            }

          </div>

          <div style={{textAlign: 'center', marginTop: 10}}>

            <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

            <SentOut data={data} onInput={validate} onChange={props.changedSample} />

            <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

            {isValidated.status === 'reviewed' && data.phaseid === 'C' ?

              <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

                <h4 style={{display: 'inline-block'}}>For field use?</h4><input style={{display: 'inline-block', margin: 10, height: 20, width: 20}} type='checkbox' pattern="{1,}" name='isFieldUse' onInput={validate} onChange={props.changedSample} onClick={null} defaultChecked={isValidated.isFieldUse} />

              </div> : null

            }

            {props.user.todo > 1 ?
              <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
              null
            }

          </div>

        </div>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div>
            <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>
              <h3>Trials</h3>
              {tableOfTrials}
            </div>
          </div>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <Icon name='add_circle' onClick={openAdd} />
          </div>

        </div>

        <div style={{margin: 10, flex: '1 1 auto'}}>
          <h3>Graph</h3>
          <div>
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Max
