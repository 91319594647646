import React from 'react'

const Icon = (props) => {

  const style = {

    backgroundColor: 'transparent',
    border: props.border != undefined ? props.border : 'none',
    color: props.color != undefined ? props.color : 'dodgerblue',
    cursor: 'pointer',
    display: 'inline-block',
    height: 30,
    margin: '3px 6px 3px 3px',
    outline: 'none',
    padding: 3,
    width: 30,
    verticalAlign: 'top'

  }

  // lat and lng are used for viewLocation under common.js

  return (
    <button style={style} >
      <i
        className={props.outline ? 'material-icons-outlined' : props.iconClass !== undefined ? props.iconClass : 'material-icons'}
        onClick={props.onClick}
        data-i={props.i}
        data-id={props.id}
        data-jobnumber={props.jobnumber}
        data-phase={props.phase}
        data-status={props.status}
        data-field={props.field}
        data-btn={props.btn}
        data-lat={props.lat}
        data-lng={props.lng}
      >
        {props.name}
      </i>
    </button>
  )

}

export default Icon
