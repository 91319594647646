import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'

const Following = (props) => {

  const [fetchedFollowing, setFetchedFollowing] = useState([])
  const [isSelectFollowing, setIsSelectFollowing] = useState(false)

  const selectFollowing = () => isSelectFollowing ? setIsSelectFollowing(false) : setIsSelectFollowing(true)

  const addFollowing = (event) => {
    let el = event.target
    let jobnumber = el.getAttribute('data-jobnumber')

    fetch('/api/addFollowing', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: jobnumber, userId: props.user.id})
    })
      .then(res=>res.json())
      .then(
        (result) => {
          fetchData()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )
  }

  const deleteFollowing = (event) => {
    let el = event.target
    let id = parseInt(el.getAttribute('data-id'))

    fetch('/api/deleteFollowing', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id})
    })
      .then(res=>res.json())
      .then(
        (result) => {
          fetchData()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )
  }

  const fetchData = () => {

    fetch('/api/selectFollowing', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({isSelectFollowing: isSelectFollowing, userId: props.user.id})
    })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedFollowing(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )
  }

  useEffect(() => {
    fetchData()
  }, [isSelectFollowing])

  let iconNameAdd = isSelectFollowing ? 'add_circle' : 'add_circle_outline'

  let jobs = fetchedFollowing.map((job, i) => {
    let iconName = job.userId === props.user.id ? 'brightness_1' : 'panorama_fish_eye'
    let onClick = iconName === 'brightness_1' ? 'deleteFollowing' : 'addFollowing'

    return (
      <tr key={i}>
        <Icon
          name={iconName}
          id={job.id}
          jobnumber={job.jobnumber}
          onClick={job.userId === props.user.id ? deleteFollowing : addFollowing}
        />
        <td>{job.jobnumber}</td>
        <td>{job.company}</td>
        <td>{job.city}</td>
      </tr>
    )

  })

  let following = jobs.length > 0 ?
    <table>
      <tbody>
        <tr>
          <th></th>
          <th>JN</th>
          <th>Client</th>
          <th>City</th>
        </tr>
        {jobs}
      </tbody>
    </table> :
    <h3>You are not following any jobs :(</h3>

  return (
    <div style={{margin: 10}}>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <Icon name={iconNameAdd} onClick={selectFollowing} />
        <h2 style={{display: 'inline-block'}}>Following</h2>
      </div>
      {following}
    </div>
  )
}

export default Following
