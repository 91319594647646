import React, {useState, useEffect, useRef} from 'react'
import Loading from 'components/utils/Loading'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import ArchiveMenu from 'components/menu/Archive'
import ArchiveRacks from 'components/main/ArchiveRacks'
import SearchBar from 'components/utils/SearchBar'

import 'styles/container.css'

import { formatDateTime, formatDateYMD, getLocation, filterData, addActivity } from 'scripts/common'

const Archive = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [selectedAll, setSelectedAll] = useState(false)
  const [selectedFound, setSelectedFound] = useState(false)
  const pending = useRef([])

  const isChanged = useRef(false)

  const [isModal, setIsModal] = useState({
    archived: false,
    toArchive: false,
    toDispose: false,
    loading: true,
    rack: true
  })

  const [view, setView] = useState({
    archive: false,
    assignToBeThrownOut: false,
    throwAway: false
  })

  const [selectedSamples, setSelectedSamples] = useState({
    jobNumber: '',
    rack: '',
    row: '',
    col: ''
  })

  const [searchValue, setSearchValue] = useState('')

  const fetchData = () => {

    fetch('/api/selectArchive', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobNumber: !view.archive && !view.assignToBeThrownOut && !view.throwAway && selectedSamples.jobNumber !== '' ? selectedSamples.jobNumber : props.filter.jobNumber,
        phase: props.filter.phaseId,
        rack: selectedSamples.rack,
        row: selectedSamples.row,
        col: selectedSamples.col,
        view: view
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //setFetchedData(result)

        setFetchedData(result.map(data => (
          {...data,
            sampledate: formatDateYMD(data.sampledate),
            archivedtime: formatDateYMD(data.archivedtime),
            selected: false,
            sampletype: data.sampletype === 'ca' ? 'rings': data.sampletype
          }
        )))

        setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [props.filter, view])

  useEffect(() => {
    if (!view.archive && !view.assignToBeThrownOut && !view.throwAway) fetchData()
  }, [selectedSamples])

  const updateArchive = () => {

    if (pending.current.length === 0 && selectedSamples.row === '') {
      alert('Please select a sample and location')
    } else if (selectedSamples.row === '') {
      alert('Please select a location')
    } else if (pending.current.length === 0) {
      alert('Please select a sample')
    } else if (pending.current.length > 0) {

      getLocation(function(latlng){
        let lat = latlng.lat
        let lng = latlng.lng

        fetch('/api/updateArchive', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            pending: pending.current,
            lat: lat,
            lng: lng,
            archiveRack: selectedSamples.rack,
            archiveRow: selectedSamples.row,
            archiveCol: selectedSamples.col
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            setSelectedSamples({
              jobNumber: '',
              rack: '',
              row: '',
              col: ''
            })

            setSelectedFound(false)

            if (selectedAll) setSelectedAll(false)

            addActivity('lab', '', '', 'archive', 'update', '', props.user.username)

            fetchData()
            pending.current = []

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    } else {
      alert('Please select a sample to archive')
    }

  }

  const updateDispose = () => {

    if (pending.current.length > 0) {

      getLocation(function(latlng){
        let lat = latlng.lat
        let lng = latlng.lng

        fetch('/api/updateDispose', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            pending: pending.current,
            lat: lat,
            lng: lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            setSelectedFound(false)

            if (selectedAll) setSelectedAll(false)

            addActivity('lab', '', '', 'assignToBeThrownOut', 'update', '', props.user.username)

            fetchData()
            pending.current = []

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    } else {
      alert('Please select a sample to assign to be thrown out')
    }

  }

  const updateDisposed = () => {

    if (pending.current.length > 0) {

      getLocation(function(latlng){
        let lat = latlng.lat
        let lng = latlng.lng

        fetch('/api/updateDisposed', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            pending: pending.current,
            lat: lat,
            lng: lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            setSelectedFound(false)

            if (selectedAll) setSelectedAll(false)

            addActivity('lab', '', '', 'throwAway', 'update', '', props.user.username)

            fetchData()
            pending.current = []

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    } else {
      alert('Please select a sample to throw away')
    }

  }

  const selectSamples = (jn, rack, row, col) => {

    //console.log(`select: ${jn}, ${rack}, ${row}, ${col}`)

    if (selectedSamples.rack === rack && selectedSamples.row === row && selectedSamples.col === col) {

      setSelectedSamples({
        jobNumber: '',
        rack: '',
        row: '',
        col: ''
      })

    } else {

      setSelectedSamples({
        jobNumber: jn,
        rack: rack,
        row: row,
        col: col
      })

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  // const selectRow = (e) => {
  //
  //   let target = e.target
  //   //console.log(`target: ${target.nodeName}`)
  //   let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
  //   let td = tr.getElementsByTagName('td')
  //   let i = tr.getAttribute('data-i') //td[4].textContent // usually 0, but listOfData was reorganized
  //
  //   if (e.target.nodeName === 'I' || e.target.nodeName === 'Button') {
  //
  //   } else if (i === '' || i === null) {
  //     alert('Error: data index not found. Contact an admin.')
  //   } else {
  //
  //     if (target.nodeName === 'TD') {
  //
  //       console.log(`nothin`)
  //
  //     }
  //
  //   }
  //
  // }

  const openArchived = () => setIsModal(prevState => ({...prevState, archived: true}))

  const openToArchive = () => setIsModal(prevState => ({...prevState, toArchive: true}))

  const openToDispose = () => setIsModal(prevState => ({...prevState, toDispose: true}))

  const closeArchived = () => setIsModal(prevState => ({...prevState, archived: false}))

  const closeToArchive = () => setIsModal(prevState => ({...prevState, toArchive: false}))

  const closeToDispose = () => setIsModal(prevState => ({...prevState, toDispose: false}))

  const toggleRack = () => setIsModal(prevState => ({...prevState, rack: isModal.rack ? false : true}))

  const toggleArchive = () => {

    pending.current = []
    setSelectedFound(false)
    setView(prevState => ({...prevState, archive: view.archive ? false : true, assignToBeThrownOut: false, throwAway: false}))
  }

  const toggleAssignToBeThrownOut = () => {

    pending.current = []
    setSelectedFound(false)
    setView(prevState => ({...prevState, assignToBeThrownOut: view.assignToBeThrownOut ? false : true, archive: false, throwAway: false}))
  }

  const toggleThrowAway = () => {

    pending.current = []
    setSelectedFound(false)
    setView(prevState => ({...prevState, throwAway: view.throwAway ? false : true, assignToBeThrownOut: false, archive: false}))

  }

  const changedData = () => isChanged.current = true

  const savedData = () => isChanged.current = false

  const selectAll = () => {

    pending.current = []

    setFetchedData(fetchedData.map(data => {

      let filter = filterData(data, searchValue)

      if (filter && !selectedAll){

        pending.current.push(data.id)
        return {...data, selected: true}
      } else {


        return {...data, selected: false}
      }

    }))

    setSelectedFound(selectedAll ? false : true)
    setSelectedAll(selectedAll ? false : true)

  }

  const selectIndividual = (e) => {

    let target = e.target
    //console.log(`target: ${target.nodeName}`)
    let tr = target.nodeName === 'I' ? target.parentNode.parentNode.parentNode : target.nodeName === 'Button' ? target.parentNode.parentNode : target.parentNode
    //let td = tr.getElementsByTagName('td')
    let index = parseInt(tr.getAttribute('data-i'))
    let count = 0

    setFetchedData(fetchedData.map((data, i) => {

      //let filter = filterData(data, searchValue)

      if (i === index) {

        if (data.selected) {

          count += 0
          let j = pending.current.indexOf(data.id)
          pending.current.splice(j,1)

        } else {

          count += 1
          pending.current.push(data.id)

        }

        return {...data, selected: data.selected ? false : true}

      } else {

        count += data.selected ? 1 : 0

        return {...data}
      }

    }))

    if (count > 0) {
      console.log('1')
      setSelectedFound(true)
    } else if (count === 0 && selectedFound){
      console.log('3')
      setSelectedFound(false)
    } else {
      console.log('3')
    }

  }

  const nothingFound = () => alert('Please select a sample first')

  const whoAmI = () => alert('Who am I...')

  let listOfData = fetchedData.map((data, i) => {

    // labid AS id, jobnumber, phaseid, sampleno, location, represents, depth, sampletype, status, archiveRack, archiveRow, archiveCol

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let phase = data.phaseid === 'P' ? 'Invest' : data.phaseid === 'C' ? 'Const' : ''
    let sampleNo = data.sampleno === null ? '' : data.sampleno.toString()
    let location = data.location === null ? '' : data.location
    let represents = data.represents === null || data.represents === '' ? '' : `, ${data.represents}`
    let depth = data.depth === null ? '' : data.depth
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let rack = data.archiveRack === null ? '' : data.archiveRack.toString()
    let row = data.archiveRow === null ? '' : data.archiveRow.toString()
    let col = data.archiveCol === null ? '' : data.archiveCol.toString()
    let description = data.description === null ? '' : data.description
    description += data.project === null ? '' : data.project === '' ? '' : `, ${data.project}`
    let client = data.company === null ? '' : data.company
    let city = data.city === null ? '' : data.city
    let archivedTime = data.archivedtime === null ? '' : data.archivedtime
    let sampleDate = data.sampledate === null ? '' : data.sampledate

    let filter = filterData(data, searchValue)

    if (filter) {
      return (
        <tr key={data.id.toString()} data-i={i}>
          {view.archive ? <td><Icon name='circle' outline={data.selected ? false : true} i={i} onClick={selectIndividual} /></td> : null}
          {view.assignToBeThrownOut ? <td><Icon name='circle' color='tomato' outline={data.selected ? false : true} i={i} onClick={selectIndividual} /></td> : null}
          {view.throwAway ? <td><Icon name='circle' color='tomato' outline={data.selected ? false : true} i={i} onClick={selectIndividual} /></td> : null}
          {isModal.rack ? null : <td>{sampleDate}</td>}
          <td>{jn}</td>
          {isModal.rack ? null :
            <>
              <td>{client}</td>
              <td>{city}</td>
              <td className='wrapText'>{description}</td>
            </>
          }
          <td>{phase}</td>
          {phase === 'Invest' ? <td className='wrapText'>{location} @ {depth}ft</td> : <td className='wrapText'>SN {sampleNo}, {location}{represents}</td>}
          {isModal.rack ? null : <td className='wrapText'>{data.purpose}</td>}
          <td>{sampleType}</td>
          <td>{rack}</td>
          <td>{row}</td>
          <td>{col}</td>
        </tr>
      )
    }

  })

  let styleBtnBlue = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }

  let styleBtnRed = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid tomato',
    margin: 10
  }

  let styleBtnGray = {
    color: 'gray',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid gray',
    margin: 10
  }

  // <div style={{display: 'flex', alignItems: 'center'}}>
  //   <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openArchived}><Icon name='archive' /><span>Archived</span></div>
  //   <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openToArchive}><Icon name='archive' iconClass='material-icons-outlined' /><span>To archive</span></div>
  //   <div style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}} onClick={openToDispose}><Icon name='delete' iconClass='material-icons-outlined' color='tomato' /><span>To dispose</span></div>
  // </div>

  return (
    <>
      {isModal.archived ? <ArchiveMenu fetchData={fetchData} filter={props.filter} status='archived' savedData={changedData} changedData={changedData} user={props.user} add={null} closeModal={closeArchived} selectSamples={selectSamples} /> : null}
      {isModal.toArchive ? <ArchiveMenu fetchData={fetchData} filter={props.filter} status='archive' savedData={changedData} changedData={changedData} user={props.user} add={null} closeModal={closeToArchive} selectSamples={selectSamples} /> : null}
      {isModal.toDispose ? <ArchiveMenu fetchData={fetchData} filter={props.filter} status='dispose' savedData={changedData} changedData={changedData} user={props.user} add={null} closeModal={closeToDispose} selectSamples={selectSamples} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          {!isModal.loading ?

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div style={{display: 'flex', alignItems: 'center'}}>

                <div style={view.archive ? styleBtnBlue : styleBtnGray} onClick={toggleArchive}>
                  <Icon name='archive' color={view.archive ? 'dodgerblue' : 'gray'} outline={true} />
                  <span style={{marginRight: 10}}>Archive</span>
                </div>

                <div style={view.assignToBeThrownOut ? styleBtnRed : styleBtnGray} onClick={toggleAssignToBeThrownOut}>
                  <Icon name='delete' color={view.assignToBeThrownOut ? 'tomato' : 'gray'} outline={true} />
                  <span style={{marginRight: 10}}>Assign To Be Thrown Out</span>
                </div>

                <div style={view.throwAway ? styleBtnRed : styleBtnGray} onClick={toggleThrowAway}>
                  <Icon name='waving_hand' color={view.throwAway ? 'orange' : 'gray'} />
                  <span style={{marginRight: 10}}>Throw Away</span>
                </div>

                <div style={isModal.rack ? styleBtnBlue : styleBtnGray} onClick={toggleRack}>
                  <Icon name='table_rows' color={isModal.rack ? 'orange' : 'gray'} />
                  <span style={{marginRight: 10}}>Racks</span>
                </div>

              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {view.archive || view.assignToBeThrownOut || view.throwAway ?

                <div style={{display: 'flex', alignItems: 'center'}}>

                  <div style={selectedFound ? styleBtnBlue : styleBtnGray} onClick={!selectedFound ? nothingFound : view.archive ? updateArchive : view.assignToBeThrownOut ? updateDispose : view.throwAway ? updateDisposed : whoAmI}>
                    <Icon name='check_circle' color={selectedFound ? 'dodgerblue' : 'gray'} />
                    <span style={{marginRight: 10}}>Done</span>
                  </div>

                </div> : null

              }

              {fetchedData.length > 0 ?

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table>

                    <thead>
                      <tr>
                        {view.archive || view.assignToBeThrownOut || view.throwAway ?
                          <th>
                            <Icon
                              name='circle'
                              color={view.archive ? 'dodgerblue' : 'tomato'}
                              outline={selectedAll ? false : true}
                              onClick={selectAll}
                            />
                          </th> : null
                        }
                        {isModal.rack ? null : <th>Sampled</th>}
                        <th>JN</th>
                        {isModal.rack ? null :
                          <>
                            <th>Client</th>
                            <th>City</th>
                            <th>Descrip</th>
                          </>
                        }
                        <th></th>
                        <th>Location</th>
                        {isModal.rack ? null : <th>Purpose</th>}
                        <th>Type</th>
                        <th>Rack</th>
                        <th>Row</th>
                        <th>Col</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfData}
                    </tbody>

                  </table>

                </div> :
                <p style={{margin: 10}}>No samples found.</p>

              }

            </div> :
            <p style={{margin: 10}}>Loading...</p>

          }

        </div>

        {isModal.rack && !isModal.loading ?

          <div style={{margin: 10, flex: '0 1 auto', overflow: 'auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                <ArchiveRacks filter={props.filter} selectSamples={selectSamples} selectedFound={selectedFound} />

              </div>

            </div>

          </div> : null

        }

      </div>

    </>
  )
}

// <div>
//   {rackList}
// </div>
//
// <div>
//   <div style={{display: 'inline-block', width: 200, marginLeft: 10}}>1</div>
//   <div style={shelfHeader}>2</div>
//   <div style={shelfHeader}>3</div>
//   <div style={shelfHeader}>4</div>
//   <div style={shelfHeader}>5</div>
//   <div style={shelfHeader}>6</div>
// </div>
//
// <div>
//   {shelfList}
// </div>

// {assignIcon}
// {phases}

export default Archive
