import React from 'react'

const Selects = (props) => {

  let style = {
    marginRight: 20
  }

  let i = 0
  let jobs = props.jobs.map(job => {
    i++
    return (
      <option key={i} value={job.jobnumber}>{job.jobnumber}</option>
    )
  })

  let job = (
    <label style={style}>JN:
      <select id="selectJobnumber" className="select" onChange={props.onChange}>
        <option value={props.isAdd || props.isResults ? '' : 'all'} defaultValue>{props.isAdd || props.isResults ? '' : 'All'}</option>
        {jobs}
      </select>
    </label>
  )

  let phase = (
    <label style={style}>Phase:
      <select id="selectPhase" className="select" onChange={props.onChange}>
        <option value={props.isAdd ? '' : 'all'} defaultValue>{props.isAdd ? '' : 'All'}</option>
        <option value="P">Invest</option>
        <option value="C">Const</option>
      </select>
    </label>
  )

  // let statusOptions =
  //   props.isTodo && props.isTech ?
  //   (
  //     <select id="selectStatus" className="select" onChange={props.onChange}>
  //       <option value="assigned">Assigned</option>
  //       <option value="archive">To Archive</option>
  //       <option value="dispose">To Dispose of</option>
  //       <option value="all">All</option>
  //     </select>
  //   ) :
  //   props.isTodo && !props.isTech ?
  //   (
  //     <select id="selectStatus" className="select" onChange={props.onChange}>
  //       <option value="completed">Completed</option>
  //     </select>
  //   ) :
  //   (
  //     <select id="selectStatus" className="select" onChange={props.onChange}>
  //       <option value="unassigned">Unassigned</option>
  //       <option value="sampled">Sampled</option>
  //       <option value="delivered">Delivered</option>
  //       <option value="archive">To Archive</option>
  //       <option value="archived">Archived</option>
  //       <option value="dispose">To Dispose of</option>
  //       <option value="disposed">Disposed</option>
  //       <option value="all">All</option>
  //     </select>
  //   )

  let status = props.isAssign ?
    (
      <label style={style}>Status:
        <select id="selectStatus" className="select" onChange={props.onChange}>
          <option value="unassigned">Unassigned</option>
          <option value="sampled">Sampled</option>
          <option value="delivered">Delivered</option>
          <option value="archive">To Archive</option>
          <option value="archived">Archived</option>
          <option value="dispose">To Dispose of</option>
          <option value="disposed">Disposed</option>
          <option value="all">All</option>
        </select>
      </label>
    ) : null

  // let view = props.isArchive || props.isAdd ?
  //   null :
  //   (
  //     <label style={style}>View:
  //       <select id="selectView" className="select" onChange={props.changeView} defaultValue={props.isTodo ? 'dueDate' : 'status'}>
  //         {props.isTodo ? <option value="dueDate">By Due Date</option> : null}
  //         <option value="normal">Normal</option>
  //         <option value="status">By Status</option>
  //       </select>
  //     </label>
  //   )

  //{job}

  //{phase}

  //{view}

  return (
    <div>
      {status}
    </div>
  )
}

export default Selects
