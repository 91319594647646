import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateTime, formatTime } from 'scripts/common'

const Home = (props) => {

  const canvasRef = useRef(null)
  const chartRef = useRef(null)

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const mapPosition = useRef({
    center: null,
    zoom: null
  })

  const [fetchedData, setFetchedData] = useState([])
  const [fetchedDistinctUsers, setFetchedDistinctUsers] = useState([])

  const [searchValue, setSearchValue] = useState('')

  const [isModal, setIsModal] = useState({
    table: true,
    chart: true,
    google: true
  })

  async function fetchData() {

    let response = await fetch('/api/selectDistinctUsersForChart', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (user) => {

        let users = user.map(data => data.user)
        //let users = user

        setFetchedDistinctUsers(users)

        fetch('/api/selectActivityForChart', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            filter: props.filter
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            setFetchedData(result.map((data, i) => {

              return {...data,
                time: formatDateTime(data.time),
                yLabelKey: users.indexOf(data.user)
              }

            }))
          },
          (error) => {
            console.log('Error: selectActivityForChart --> ' + error)
          }
        )

      },
      (error) => {
        console.log('Error: selectActivityForChart --> ' + error)
      }
    )

  }

  const buildChart = () => {

    let canvas, ctx, i

    let style = {
      borderWidth: 1,
      pointBorderColor: 'black',
      pointRadius: 7,
      pointHoverRadius: 10,
      fill: false,
      tension: 0,
      showLine: false,
    }

    // let img = new Image()
    // img.src ='icons/lab.png';

    // let arrNukeOut = {
    //   ...style,
    //   label: 'Nuke Out',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'MediumSeaGreen',
    //   pointStyle: 'triangle'
    // }
    //
    // let arrNukeIn = {
    //   ...style,
    //   label: 'Nuke In',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'tomato',
    //   pointStyle: 'triangle'
    // }
    //
    // let arrStatus = {
    //   ...style,
    //   label: 'status',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'tomato',
    //   pointStyle: 'circle'
    // }
    //
    // let arrStatusStart = {
    //   ...style,
    //   label: 'Start Day',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'MediumSeaGreen',
    //   pointStyle: 'circle'
    // }
    //
    // let arrStatusArrive = {
    //   ...style,
    //   label: 'Arrive Job',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'DodgerBlue',
    //   pointStyle: 'circle'
    // }
    //
    // let arrStatusBreak = {
    //   ...style,
    //   label: 'Break',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: '#ae7e4e',
    //   pointStyle: 'circle'
    // }
    //
    // let arrStatusDepart = {
    //   ...style,
    //   label: 'Depart Job',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'orange',
    //   pointStyle: 'circle'
    // }
    //
    // let arrStatusEnd = {
    //   ...style,
    //   label: 'End Day',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'tomato',
    //   pointStyle: 'circle'
    // }
    //
    // let arrTest = {
    //   ...style,
    //   label: 'test',
    //   data: [],
    //   borderColor: 'black',
    //   pointBackgroundColor: 'black',
    //   pointStyle: 'triangle'
    // }

    let arrMds = {
      ...style,
      label: 'Mds',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'tomato',
      pointStyle: 'circle'
    }

    let arrMax = {
      ...style,
      label: 'Max',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'orange',
      pointStyle: 'circle'
    }

    let arrEi = {
      ...style,
      label: 'Ei',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'mediumseagreen',
      pointStyle: 'circle'
    }

    let arrSo4 = {
      ...style,
      label: 'So4',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'dodgerblue',
      pointStyle: 'circle'
    }

    let arrConsol = {
      ...style,
      label: 'Consol',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'violet',
      pointStyle: 'circle'
    }

    let arrAtt = {
      ...style,
      label: 'Att',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'gray',
      pointStyle: 'circle'
    }

    let arrSe = {
      ...style,
      label: 'Se',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'lightgray',
      pointStyle: 'circle'
    }

    let arrOther = {
      ...style,
      label: 'Other',
      data: [],
      borderColor: 'black',
      pointBackgroundColor: 'black',
      pointStyle: 'circle'
    }

    for (i = 0; i < fetchedData.length; i++) {

      if (filterData(fetchedData[i])) {

        if (fetchedData[i].category === 'mds') {

          arrMds.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else if (fetchedData[i].category === 'max' || fetchedData[i].category === 'max trial') {

          arrMax.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else if (fetchedData[i].category === 'ei' || fetchedData[i].category === 'ei trial') {

          arrEi.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else if (fetchedData[i].category === 'so4') {

          arrSo4.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else if (fetchedData[i].category === 'consol') {

          arrConsol.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else if (fetchedData[i].category === 'att' || fetchedData[i].category === 'att trial') {

          arrAtt.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else if (fetchedData[i].category === 'SE') {

          arrSe.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        } else {

          arrOther.data.push({x: fetchedData[i].time, y: fetchedData[i].yLabelKey, jn: fetchedData[i].jobNumber, phase: fetchedData[i].phase, category: fetchedData[i].category, action: fetchedData[i].action, description: fetchedData[i].description, user: fetchedData[i].user})

        }

      }

    }

    let chartData = [arrMds, arrMax, arrEi, arrSo4, arrConsol, arrAtt, arrSe, arrOther]

    //console.log(`charData: ${JSON.stringify(chartData)}`)

    let minTime = Math.min(...fetchedData.map(data => data.time))

    //console.log(`minTime: ${minTime}`)

    canvas = canvasRef.current
    ctx = canvas.getContext('2d')

    if (chartRef.current) chartRef.current.destroy()

    chartRef.current = new window.Chart(ctx, {
      // The type of chart we want to create
      type: 'line',

      // The data for our dataset
      data: {
        datasets: chartData
      },
      options: {
        animation: 0,
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            type: 'time',
            time: {
              unit: 'hour',
              unitStepSize: 1
              //min: minTime
              //min: 'Wednesday, Oct 16, 2019, 5:00 AM'
            },
            scaleLabel: {
              display: true
            },
            ticks: {
              beginAtZero: true,
              min: minTime,
              //steps: 10,
              //stepValue: 15,
              //max: 100
            }
           }],
           yAxes: [{
               ticks: {
                 beginAtZero: true,
                 callback: function(value, index, values) {
                   return fetchedDistinctUsers[value];
                 }
               }
           }]
        },
        legend: {
            labels: {
                usePointStyle: true,
            },
            //fullWidth: true,
        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        tooltips: {
          //yAlign: 'bottom',
          mode: 'nearest',
          callbacks: {
            label: function(tooltipItem, data) {
              return data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].user
            },
            afterLabel: function(tooltipItem, data) {

              //let tbl = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].category

              //console.log(`tbl ${JSON.stringify(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index])}`)

              // if (tbl === 'nukeOut' || tbl === 'nukeIn') {
              //   return `
              //     Nuke #${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].description} ${tbl === 'nukeOut' ? 'Check out' : 'Checked in'}
              //   `
              // } else {
                return `
                  JN: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].jn}
                  Phase: ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].phase}
                  ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].category}
                  ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].action}
                  ${data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index].description}
                `
              //}

            }
          },
          borderWidth: 2,
          borderColor: '#D3D3D3',
          backgroundColor: '#FFFFFF',
          titleFontSize: 16,
          titleFontColor: '#0066ff',
          bodyFontColor: '#000000',
          bodyFontSize: 20,
          displayColors: false
        }

      }

    });

  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  useEffect(() => {
    if (isModal.chart) buildChart()
  })

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData()
      .then(result => {
        if (isModal.chart) buildChart()
        //if (isModal.google) addMarkers()
      })
    }, 15000) // time in milliseconds (ie 1 mins)
    return () => clearInterval(interval);
  }, [isModal, props.filter, searchValue, fetchedData])

  //============== GOOGLE MAP ======================

  useEffect(() => {
    if (isModal.google) initMap()
  }, [isModal.google])

  useEffect(() => {
    if (isModal.google) addMarkers()
  }, [isModal.google, props.filter, searchValue, fetchedData])

  // useEffect(() => {
  //   if (isModal.google) updateMap()
  // }, [searchValue])

  // Sets the map on all markers in the array.
  const highlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))
    let imageName, tbl
    let imagePath = 'http://labs.google.com/ridefinder/images/'

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        tbl = markerRef.current[i].tbl

        imageName =
        tbl === 'nukeIn' ? 'mm_20_green.png' :
        tbl === 'nukeOut' ? 'mm_20_red.png' :
        tbl === 'status' ? 'mm_20_blue.png' :
        tbl === 'test' ? 'mm_20_orange.png' :
        tbl === 'lab' ? 'mm_20_brown.png' :
        tbl === 'daily' ? 'mm_20_gray.png' :
        tbl === 'drawing' ? 'mm_20_purple.png' : 'mm_20_white.png'

        markerRef.current[i].setIcon(`${imagePath}${imageName}`)
        markerRef.current[i].setAnimation(null)
        //markerRef.current[i].getVisible(false)
      }

      //markerRef.current[i].setMap(mapRef.current);
    }
  }

  // const updateMap = (id, visible) => {
  //
  //   for (let i = 0; i < markerRef.current.length; i++) {
  //     if (markerRef.current[i].get("id") === id) {
  //
  //       markerRef.current[i].setVisible(visible)
  //
  //     }
  //
  //     markerRef.current[i].setMap(mapRef.current);
  //   }
  // }

  // const initMap = () => {
  //
  //   let data = fetchedData.filter(data => {
  //
  //     if (data.lat !== '' && data.lat !== null && data.lng !== '' && data.lng !== null) {
  //
  //       let jn = data.jobnumber === null ? '' : data.jobnumber
  //       let gd = data.phase === null ? '' : data.phase === 'RG' ? 'Grading' : data.phase === 'PG' ? 'Post' : data.phase
  //       let description = data.description === null ? '' : data.description
  //       let tbl = data.tbl === null ? '' : data.tbl
  //       let by = data.who === null ? '' : data.who
  //       let time = data.time === null ? '' : data.time
  //
  //       return true
  //
  //     }
  //
  //     return false
  //
  //
  //   })
  //
  //   let google = googleRef.current
  //
  //   mapRef.current = new window.google.maps.Map(google, {
  //     mapTypeId: window.google.maps.MapTypeId.ROADMAP,
  //     zoom: 9,
  //     center: {lat: 33.847827, lng: -117.862286}
  //   });
  //
  //   let infoWindow = new window.google.maps.InfoWindow;
  //
  //   let marker, i, latLng, description
  //
  //   //let i, latLng
  //
  //   let bounds = new window.google.maps.LatLngBounds()
  //
  //   for (i = 0; i < data.length; i++) {
  //
  //     description = data[i].description === null ? '' : data[i].description
  //
  //     marker = new window.google.maps.Marker({
  //       position: new window.google.maps.LatLng(data[i].lat, data[i].lng),
  //       title: '',
  //       animation: window.google.maps.Animation.DROP,
  //       map: mapRef.current,
  //       icon: {
  //         url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
  //       },
  //       id: data[i].id,
  //       visible: filterData(data[i])
  //     });
  //
  //     marker.setMap(mapRef.current)
  //
  //     markerRef.current.push(marker)
  //
  //     latLng = new window.google.maps.LatLng(data[i].lat, data[i].lng);
  //
  //     bounds.extend(latLng);
  //
  //     window.google.maps.event.addListener(marker, 'click', function () {
  //       infoWindow.setContent('<h3>' + this.title + '</h3>');
  //       infoWindow.open(mapRef.current, this);
  //     });
  //
  //   }
  //
  //   //mapRef.current.fitBounds(bounds)
  //
  //   //mapRef.current.setCenter(bounds.getCenter())
  //
  //   // if (mapPosition.current.center === null || mapPosition.current.zoom === null) {
  //   //
  //   //   mapRef.current.fitBounds(bounds)
  //   //
  //   //   mapRef.current.setCenter(bounds.getCenter())
  //   //
  //
  //   //
  //   //   mapPosition.current = {
  //   //     center: mapRef.current.getCenter(),
  //   //     zoom: mapRef.current.getZoom()
  //   //   }
  //   //
  //   // } else {
  //   //
  //   //   mapRef.current.setCenter(mapPosition.current.center)
  //   //
  //   //   mapRef.current.setZoom(mapPosition.current.zoom)
  //   //
  //   // }
  //
  // }

  // const filterData = (data) => {
  //
  //   let jn = data.jobnumber === null ? '' : data.jobnumber
  //   let gd = data.phase === null ? '' : data.phase === 'RG' ? 'Grading' : data.phase === 'PG' ? 'Post' : data.phase
  //   let description = data.description === null ? '' : data.description
  //   let tbl = data.tbl === null ? '' : data.tbl
  //   let by = data.who === null ? '' : data.who
  //   let time = data.time === null ? '' : data.time
  //
  //   if (
  //
  //     (searchValue === '' ||
  //     jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
  //     gd.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
  //     description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
  //     tbl.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
  //     by.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
  //     time.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
  //
  //   ) {
  //
  //     return true
  //
  //   } else {
  //
  //     return false
  //
  //   }
  //
  // }

  const initMap = () => {

    mapRef.current = new window.google.maps.Map(googleRef.current, {
      mapTypeId: window.google.maps.MapTypeId.ROADMAP,
      zoom: 9,
      center: {lat: 33.847827, lng: -117.862286}
    });

    console.log('chart?')

    addMarkers()

  }

  const addMarkers = () => {

    let data = fetchedData.filter(data => {

      if (data.lat !== '' && data.lat !== null && data.lng !== '' && data.lng !== null) {

          let jn = data.jobnumber === null ? '' : data.jobnumber
          let gd = data.phase === 'P' ? 'Invest' : data.phase === 'C' ? 'Const' : ''
          let category = data.category === null ? '' : data.category
          let action = data.action === null ? '' : data.action
          let description = data.description === null ? '' : data.description
          let by = data.user === null ? '' : data.user
          let time = data.time === null ? '' : data.time

        // if (
        //
        //   (searchValue === '' ||
        //   jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        //   gd.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        //   description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        //   tbl.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        //   by.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        //   time.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
        //
        // ) {
        //
        //   console.log(`filter true: ${searchValue}`)
        //
        //   return data
        //
        // }

        return data

      }


    })

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, visible, imageName
    let imagePath = 'http://labs.google.com/ridefinder/images/'
    let jn, phase, category, action, description, by, time

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds()

    // clear markers

    for (i = 0; i < markerRef.current.length; i++) {
      markerRef.current[i].setMap(null);
    }

    // add markers

    for (i = 0; i < data.length; i++) {

      jn = data[i].jobNumber === null ? '' : data[i].jobNumber
      phase = data[i].phase === 'P' ? 'Invest' : data[i].phase === 'C' ? 'Const' : ''
      category = data[i].category === null ? '' : data[i].category
      action = data[i].action === null ? '' : data[i].action
      description = data[i].description === null ? '' : data[i].description
      by = data[i].user === null ? '' : data[i].user
      time = data[i].time === null ? '' : data[i].time
      visible = filterData(data[i])

      //console.log(`${visible}`)

      imageName = 'mm_20_brown.png'
      // tbl === 'nukeIn' ? 'mm_20_green.png' :
      // tbl === 'nukeOut' ? 'mm_20_red.png' :
      // tbl === 'status' ? 'mm_20_blue.png' :
      // tbl === 'test' ? 'mm_20_orange.png' :
      // tbl === 'lab' ? 'mm_20_brown.png' :
      // tbl === 'daily' ? 'mm_20_gray.png' :
      // tbl === 'drawing' ? 'mm_20_purple.png' : 'mm_20_white.png'


      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].lat, data[i].lng),
        jn: jn,
        phase: phase,
        category: category,
        action: action,
        description: description,
        by: by,
        time: time,
        map: mapRef.current,
        icon: {
          url: `${imagePath}${imageName}`
        },
        id: data[i].id,
        visible: visible
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].lat, data[i].lng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent(`
          <div>
            <p>${this.time}</p>
            <p>${this.by}</p>
            <p>${this.jn} ${this.phase}</p>
            <p>${this.category} (${action}): ${this.description}</p>
          </div>
          `);
        infoWindow.open(mapRef.current, this);
      });

    }

    //mapRef.current.fitBounds(bounds)

    //mapRef.current.setCenter(bounds.getCenter())

    // if (mapPosition.current.center === null || mapPosition.current.zoom === null) {
    //
    //   mapRef.current.fitBounds(bounds)
    //
    //   mapRef.current.setCenter(bounds.getCenter())
    //

    //
    //   mapPosition.current = {
    //     center: mapRef.current.getCenter(),
    //     zoom: mapRef.current.getZoom()
    //   }
    //
    // } else {
    //
    //   mapRef.current.setCenter(mapPosition.current.center)
    //
    //   mapRef.current.setZoom(mapPosition.current.zoom)
    //
    // }

  }

  const toggleTable = () => setIsModal(prevState => ({...prevState, table: isModal.table ? false : true}))

  const toggleChart = () => setIsModal(prevState => ({...prevState, chart: isModal.chart ? false : true}))

  const toggleGoogle = () => {

    if (isModal.google) mapPosition.current = {center: null, zoom: null}

    setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const filterData = (data) => {

    let jn = data.jobnumber === null ? '' : data.jobnumber
    let phase = data.phase === 'P' ? 'Invest' : data.phase === 'C' ? 'Const' : ''
    let category = data.category === null ? '' : data.category
    let action = data.action === null ? '' : data.action
    let description = data.description === null ? '' : data.description
    let by = data.user === null ? '' : data.user
    let time = data.time === null ? '' : data.time

    if (

      (searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      phase.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      category.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      action.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      by.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      time.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {

      return true

    } else {

      return false

    }

  }

  const somethingSoon = () => alert('something cool soon...')

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobNumber === null ? '' : data.jobNumber
    let phase = data.phase === 'P' ? 'Invest' : data.phase === 'C' ? 'Const' : ''
    let category = data.category === null ? '' : data.category
    let action = data.action === null ? '' : data.action
    let description = data.description === null ? '' : data.description
    let by = data.user === null ? '' : data.user
    let time = data.time === null ? '' : formatTime(data.time)

    if (filterData(data)) {

      return (

        <tr data-id={data.id} onClick={somethingSoon} onMouseOver={highlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{time}</td>
          <td>{by}</td>
          <td>{jn} {phase}</td>
          <td>{category}</td>
          <td>{action}</td>
          <td className='wrapText'>{description}</td>
        </tr>

      )

    }

  })

  let chartAndGoogle = (
    <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

      <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

        {isModal.chart ?

          <div style={{margin: 10, flex: '1', overflow: 'auto', border: '1 solid blue'}}>
            {props.filter.startDate === props.filter.endDate ?
              <canvas ref={canvasRef}></canvas> :
              <p>Please select a single Date to view activity</p>
            }
          </div> : null

        }

        {isModal.google ?

          <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto', border: '1 solid blue'}}>
            <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
          </div> : null

        }

      </div>

    </div>
  )

  return (

    <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

      <div style={{margin: 10, flex: isModal.table && (isModal.chart || isModal.google) ? '0 1 auto' : '1 0 auto'}}>

        <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

          <div>
            <Icon name='refresh' title='Refresh' onClick={fetchData} />
            <Icon name='table_rows' title='Toggle Table' outline={true} color={isModal.table ? 'dodgerblue' : 'gray'} onClick={toggleTable} />
            <Icon name='timeline' title='Toggle Chart' color={isModal.chart ? 'dodgerblue' : 'gray'} onClick={toggleChart} />
            <Icon name='location_on' title='Toggle Map' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
          </div>

          <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

          {isModal.table ?

            <>

              {fetchedData.length > 0 ?

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                  <table>
                    <thead>
                      <tr>
                        <th>Time</th>
                        <th>User</th>
                        <th>JN</th>
                        <th>Category</th>
                        <th>Action</th>
                        <th>Desc.</th>
                      </tr>
                    </thead>

                    <tbody>
                      {listOfData}
                    </tbody>
                  </table>

                </div> :
                <p style={{margin: 10}}>No activity found</p>

              }

            </> : null

          }

          {!isModal.table && (isModal.chart || isModal.google) ? chartAndGoogle : null}

        </div>

      </div>

      {isModal.table && (isModal.chart || isModal.google) ? chartAndGoogle : null}

    </div>

  )
}

export default Home
