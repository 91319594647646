import React from 'react'
import Loading from 'components/utils/Loading'
import Following from './Following'
import Activity from './Activity'
import WhatsNew from './WhatsNew'

const Home = (props) => {

  // <div style={{flex: '1 1 auto'}}>
  //   <Activity user={props.user} />
  // </div>

  return (
    <div style={{display: 'flex', overflowX: 'auto'}}>

      <div style={{flex: '1 1 auto'}}>
        <WhatsNew user={props.user} />
      </div>

      <div style={{flex: '1 1 auto'}}>
        <Following user={props.user} />
      </div>

    </div>
  )
}

export default Home
