import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateYMD, formatTime, formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Hydro = (props) => {

  const data = props.data[0]
  //const [isChangedSample, setIsChangedSample] = useState(false)
  const canvasRef = useRef(null)

  const [isValidated, setIsValidated] = useState({
    totalWt: data.totalwt,
    startDate: data.startdate,
    startTime: data.starttime,
    temp2: data.temp2,
    temp5: data.temp5,
    temp15: data.temp15,
    temp30: data.temp30,
    temp60: data.temp60,
    temp120: data.temp120,
    temp250: data.temp250,
    temp1440: data.temp1440,
    read2: data.read2,
    read5: data.read5,
    read15: data.read15,
    read30: data.read30,
    read60: data.read60,
    read120: data.read120,
    read250: data.read250,
    read1440: data.read1440,
    sg: data.sg === '' || data.sg === null ? 2.65 : data.sg,
    drySoilAndTareWtAfter200: data.drysoilandtarewtafter200,
    tareWtAfter200: data.tarewtafter200,
    after200TareNo: data.after200TareNo,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate,
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateHydro', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            totalWt: isValidated.totalWt,
            startDate: isValidated.startDate,
            startTime: isValidated.startTime,
            temp2: isValidated.temp2,
            temp5: isValidated.temp5,
            temp15: isValidated.temp15,
            temp30: isValidated.temp30,
            temp60: isValidated.temp60,
            temp120: isValidated.temp120,
            temp250: isValidated.temp250,
            temp1440: isValidated.temp1440,
            read2: isValidated.read2,
            read5: isValidated.read5,
            read15: isValidated.read15,
            read30: isValidated.read30,
            read60: isValidated.read60,
            read120: isValidated.read120,
            read250: isValidated.read250,
            read1440: isValidated.read1440,
            sg: isValidated.sg,
            drySoilAndTareWtAfter200: isValidated.drySoilAndTareWtAfter200,
            tareWtAfter200: isValidated.tareWtAfter200,
            after200TareNo: isValidated.after200TareNo
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'hydro', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statushydro',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')

      } else if (color === '' || color === null) {
        alert('Please provide a color.')

      } else if (isValidated.totalWt === '' || isValidated.totalWt === null) {
        alert('Please specify a total weight.')

      } else if (isValidated.startDate === '' || isValidated.startDate === null) {
        alert('Please provide a start date.')

      } else if (isValidated.startTime === '' || isValidated.startTime === null) {
        alert('Please provide a start time.')

      } else if (isValidated.temp2 === '' || isValidated.temp2 === null) {
        alert('Please provide a temperature at 2 mins.')

      } else if (isValidated.temp5 === '' || isValidated.temp5 === null) {
        alert('Please provide a temperature at 5 mins.')

      } else if (isValidated.temp15 === '' || isValidated.temp15 === null) {
        alert('Please provide a temperature at 15 mins.')

      } else if (isValidated.temp30 === '' || isValidated.temp30 === null) {
        alert('Please provide a temperature at 30 mins.')

      } else if (isValidated.temp60 === '' || isValidated.temp60 === null) {
        alert('Please provide a temperature at 60 mins.')

      } else if (isValidated.temp120 === '' || isValidated.temp120 === null) {
        alert('Please provide a temperature at 120 mins.')

      } else if (isValidated.temp250 === '' || isValidated.temp250 === null) {
        alert('Please provide a temperature at 250 mins.')

      } else if (isValidated.temp1440 === '' || isValidated.temp1440 === null) {
        alert('Please provide a temperature at 1440 mins.')

      } else if (isValidated.sg === '' || isValidated.sg === null) {
        alert('Please provide a specific gravity.')

      } else if (isValidated.after200TareNo === '' || isValidated.after200TareNo === null) {
        alert('Please provide a tare no for the 200 wash')

      } else if (isValidated.tareWtAfter200 === '' || isValidated.tareWtAfter200 === null) {
        alert('Please provide a tare weight for the 200 wash')

      } else if (isValidated.drySoilAndTareWtAfter200 === '' || isValidated.drySoilAndTareWtAfter200 === null) {
        alert('Please provide a dry soil and tare weight for after the 200 wash')

      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let time2, time5, time15, time30, time60, time120, time250, time1440


  let startDate = new Date(formatDateYMD(new Date(isValidated.startDate)) + ' ' + isValidated.startTime)
  let d = new Date(startDate)

  time2 = formatTime(new Date(d.setMinutes(d.getMinutes()+2)))
  d = new Date(startDate)

  time5 = formatTime(new Date(d.setMinutes(d.getMinutes()+5)))
  d = new Date(startDate)

  time15 = formatTime(new Date(d.setMinutes(d.getMinutes()+15)))
  d = new Date(startDate)

  time30 = formatTime(new Date(d.setMinutes(d.getMinutes()+30)))
  d = new Date(startDate)

  time60 = formatTime(new Date(d.setMinutes(d.getMinutes()+60)))
  d = new Date(startDate)

  time120 = formatTime(new Date(d.setMinutes(d.getMinutes()+120)))
  d = new Date(startDate)

  time250 = formatTime(new Date(d.setMinutes(d.getMinutes()+250)))
  d = new Date(startDate)

  time1440 = formatTime(new Date(d.setMinutes(d.getMinutes()+1440)))
  d = new Date(startDate)

  return (

    <div>

      <div style={{display: 'flex', overflowX: 'auto', width: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            <h3>Before 200 wash</h3>

            <label>Total Weight (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='totalWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.totalWt} required />
            </label>

            <h3>After 200 wash</h3>

            <label>Tare No
              <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='after200TareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.after200TareNo} required />
            </label><br />

            <label>Tare Wt (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='tareWtAfter200' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.tareWtAfter200} required />
            </label><br />

            <label>Dry Soil and Tare Wt (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='drySoilAndTareWtAfter200' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.drySoilAndTareWtAfter200} required />
            </label><br />

            <label>Specific Gravity
              <input style={{width: 75}} className='input' type="text" pattern="[2]{1}\.\d{1,2}" name='sg' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.sg === '' || isValidated.sg === null ? 2.65 : isValidated.sg} required />
            </label><br />

          </div>

        </div>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{textAlign: 'center', marginTop: 10}}>
            <h3>Readings</h3>
          </div>

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            <label>Start Date
              <input className='input' type="date" pattern="{1,}" name='startDate' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.startDate === null || isValidated.startDate === '' ? null : formatDateYMD(new Date(isValidated.startDate))} required />
            </label><br />

            <label>Start Time
              <input className='input' type="time" pattern="{1,}" name='startTime' onInput={validate} onChange={props.changedSample} defaultValue={formatTime(startDate)} required />
            </label>

            {
              isValidated.startDate === null || isValidated.startDate === '' ?
              <h3>Provide a start date.</h3> :
              isValidated.startTime === null || isValidated.startTime === '' ?
              <h3>Provide a start time.</h3> :
              <>
                <div style={{position: 'sticky', top: 35, backgroundColor: 'white', width: '100%'}}>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Time</label>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Temp (°C)</label>
                  <label style={{display: 'inline-block', margin: 10, width: 80}}>Reading</label>
                </div>

                <label>2
                  <input style={{width: 75}} className='input' type="text" value={time2} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp2} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read2} required />
                </label><br />

                <label>5
                  <input style={{width: 75}} className='input' type="text" value={time5} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp5' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp5} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read5' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read5} required />
                </label><br />

                <label>15
                  <input style={{width: 75}} className='input' type="text" value={time15} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp15' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp15} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read15' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read15} required />
                </label><br />

                <label>30
                  <input style={{width: 75}} className='input' type="text" value={time30} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp30} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read30} required />
                </label><br />

                <label>60
                  <input style={{width: 75}} className='input' type="text" value={time60} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp60' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp60} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read60' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read60} required />
                </label><br />

                <label>120
                  <input style={{width: 75}} className='input' type="text" value={time120} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp120' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp120} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read120' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read120} required />
                </label><br />

                <label>250
                  <input style={{width: 75}} className='input' type="text" value={time250} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp250' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp250} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read250' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read250} required />
                </label><br />

                <label>1440
                  <input style={{width: 75}} className='input' type="text" value={time1440} disabled />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp1440' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp1440} required />
                  <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='read1440' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.read1440} required />
                </label>
              </>
            }

          </div>

        </div>

      </div>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

        <SentOut data={data} onInput={validate} onChange={props.changedSample} />

        <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

        {editSampleIcon}

      </div>

    </div>

  )

}

export default Hydro
