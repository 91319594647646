import React, { useState} from 'react'
import Modal from 'components/utils/Modal'
import Icon from 'components/utils/Icon'

const DateRange = (props) => {

  const [filter, setFilter] = useState({
    startDate: '',
    endDate: '',
    useEndDate: true
  })

  const [isError, setIsError] = useState(false)

  const missingDate = () => setIsError(true)

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    if (name === 'useEndDate' && value === false) {
      setFilter(prevState => ({...prevState, useEndDate: false, endDate: filter.startDate}))
    } else if (name === 'startDate' && !filter.useEndDate) {
      setFilter(prevState => ({...prevState, startDate: value, endDate: value}))
    } else {
      setFilter(prevState => ({...prevState, [name]: state ? value : null}))
    }
  }

  const confirmDate = () => {

    if (filter.startDate !== '') {
      let start = filter.startDate
      let end = filter.endDate === '' ? filter.startDate : filter.endDate

      props.selectDateAlt(start, end)
    } else {
      alert('Please select a start date')
    }
  }

  let content = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      <div>

        <div style={{display: 'inline-block', textAlign: 'left', marginTop: 10}}>

          <div>

            <label style={{marginRight: 5}}>Start</label>
            <input className='input' type="date" pattern=".{1,}" name='startDate' onChange={validate} defaultValue={props.filter.startDate} required />

          </div>

          <div>

            <label style={{marginRight: 5}}>End</label>
            {filter.useEndDate ? <input className='input' type="date" pattern=".{1,}" name='endDate' onChange={validate} defaultValue={props.filter.endDate} required /> : null}
            <input type="checkbox" name="useEndDate"  onChange={validate} defaultChecked={filter.useEndDate} />
            <span>use?</span>

          </div>

        </div>

        <div>

          <div style={{display: 'inline-block', textAlign: 'left', marginTop: 10}}>
            <Icon name='check_circle' onClick={confirmDate} />
          </div>

        </div>

      </div>

      <div>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          <h3>OR</h3>

        </div>

      </div>

      <div>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          <table>

            <thead>
              <tr>
                <th></th>
              </tr>
            </thead>

            <tbody>
              <tr onClick={props.selectDate}>
                <td>Today</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>Yesterday</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>Previous week</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>Last 7 days</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>Last 30 days</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>Last 6 months</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>Last year</td>
              </tr>
              <tr onClick={props.selectDate}>
                <td>All</td>
              </tr>
            </tbody>

          </table>

        </div>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} />
}

export default DateRange
