import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import ArchiveRacks from 'components/main/ArchiveRacks'

import 'styles/container.css'

import { formatDateTime, getLocation, addActivity } from 'scripts/common'

const Archive = (props) => {

  const [fetchedJobs, setFetchedJobs] = useState([])
  const [fetchedSamples, setFetchedSamples] = useState([])
  const [searchValue, setSearchValue] = useState('')

  const [viewBy, setViewBy] = useState('Jobs')
  const [is, setIs] = useState({
    archive: false,
    dispose: false,
    disposed: false,
    racks: false
  })

  const pending = useRef([])

  const archiveLocation = useRef({
    rack: 1,
    row: null,
    col: null
  })

  const fetchData = () => {

    fetch('/api/selectMenuArchivedJobs', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: props.filter.jobnumber, phase: props.filter.phase, status: props.status})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log(`result: ${JSON.stringify(result)}`)

        setFetchedJobs(result.map(data => ({...data, isSelectedP: false, isSelectedC: false})))

        //console.log(`jobs: ${JSON.stringify([...new Set(result.map(data => data.jobnumber))])}`)

        //setFetchedJobs([...new Set(result.map(data => data.jobnumber))])
        setFetchedJobs(result)
      },
      (error) => {
        console.log('Error: selectMenuArchivedJobs --> ' + error)
      }
    )

    fetch('/api/selectMenuArchivedSamples', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: props.filter.jobnumber, phase: props.filter.phase, status: props.status})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        console.log(`result: ${JSON.stringify(result)}`)

        setFetchedSamples(result.map(data => ({...data, isSelected: false,})))

        //console.log(`jobs: ${JSON.stringify([...new Set(result.map(data => data.jobnumber))])}`)

        //setFetchedJobs([...new Set(result.map(data => data.jobnumber))])
        setFetchedSamples(result)
      },
      (error) => {
        console.log('Error: selectMenuArchived --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const addShelf = (rack, row, col) => {

    archiveLocation.current = {
      rack: rack,
      row: row,
      col: col
    }

    console.log(`location: ${JSON.stringify(archiveLocation)}`)

    closeRacks()

  }

  const selectViewBy = (e) => setViewBy(e.target.textContent)

  const openRacks = () => setIs(prevState => ({...prevState, racks: true}))

  const closeRacks = () => setIs(prevState => ({...prevState, racks: false}))

  const toggleArchive = () => {
    if (viewBy === 'Samples') {
      setIs({archive: is.archive ? false : true, dispose: false, disposed: false})

      props.savedData()
      pending.current = []
      archiveLocation.current = {rack: 1, row: null, col: null}

      setFetchedSamples(fetchedSamples.map(data => ({...data, isSelected: false,})))
      setFetchedJobs(fetchedJobs.map(data => ({...data, isSelectedP: false, isSelectedC: false})))

    } else {
      alert(`Please View By Samples to Edit`)
    }
  }

  const toggleDispose = () => {
    setIs({archive: false, dispose: is.dispose ? false : true, disposed: false})

    props.savedData()
    pending.current = []
    archiveLocation.current = {rack: 1, row: null, col: null}

    setFetchedSamples(fetchedSamples.map(data => ({...data, isSelected: false,})))
    setFetchedJobs(fetchedJobs.map(data => ({...data, isSelectedP: false, isSelectedC: false})))

  }

  const toggleDisposed = () => {
    setIs({archive: false, dispose: false, disposed: is.disposed ? false : true})

    props.savedData()
    pending.current = []
    archiveLocation.current = {rack: 1, row: null, col: null}

    setFetchedSamples(fetchedSamples.map(data => ({...data, isSelected: false,})))
    setFetchedJobs(fetchedJobs.map(data => ({...data, isSelectedP: false, isSelectedC: false})))

  }

  const updatePending = (event) => {
    let el = event.target
    let i = parseInt(el.getAttribute('data-i'))
    let jobnumber = el.getAttribute('data-jobnumber')
    let phase = el.getAttribute('data-phase')
    let btn = el.getAttribute('data-btn')
    let name = el.textContent
    let updateData = []
    //let updatePending = []
    let index

    if (btn === 'job') {

      //updatePending = pending

      setFetchedSamples(
        fetchedSamples.map(sample => {
          if (sample.jobnumber === jobnumber && (phase === 'all' || sample.phaseid === phase)) {

            name === 'check_circle' ?
            pending.current.splice(pending.current.indexOf(sample.id),1) :
            pending.current.push(sample.id)

            return (
              {...sample, isSelected: name === 'check_circle' ? false : true}
            )

          } else {

            return (
              {...sample}
            )

          }
        })
      )

      setFetchedJobs(
        fetchedJobs.map(data =>
          data.jobnumber === jobnumber && phase === 'all' ?
          {...data, isSelectedP: name === 'check_circle' ? false : true, isSelectedC: name === 'check_circle' ? false : true} :
          data.jobnumber === jobnumber && phase === 'P' ?
          {...data, isSelectedP: name === 'check_circle' ? false : true} :
          data.jobnumber === jobnumber && phase === 'C' ?
          {...data, isSelectedC: name === 'check_circle' ? false : true} :
          data
        )
      )
      //console.log('updatePending: ' + updatePending)
      //setPending(updatePending)

    } else if (fetchedSamples[i].isSelected) {

      updateData = fetchedSamples
      updateData[i].isSelected = false
      index = pending.current.indexOf(fetchedSamples[i].id)
      pending.current.splice(index,1)

      setFetchedSamples(
        fetchedSamples.map((data, j) =>
          j === i ?
          {...data, isSelected: false} :
          data
        )
      )

    } else {

      updateData = fetchedSamples
      updateData[i].isSelected = true
      pending.current.push(fetchedSamples[i].id)

      setFetchedSamples(
        fetchedSamples.map((data, j) =>
          j === i ?
          {...data, isSelected: true} :
          data
        )
      )

    }

    pending.current.length > 0 ? props.changedData() : props.savedData()

  }

  const updateArchive = () => {

    if (pending.current.length === 0 && archiveLocation.current.row === null) {
      alert('Please select a sample and location')
    } else if (archiveLocation.current.row === null) {
      alert('Please select a location')
    } else if (pending.current.length === 0) {
      alert('Please select a sample')
    } else if (pending.current.length > 0 && archiveLocation.current.row !== null) {

      getLocation(function(latlng){
        let lat = latlng.lat
        let lng = latlng.lng

        fetch('/api/updateArchive', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            pending: pending.current,
            lat: lat,
            lng: lng,
            archiveRack: archiveLocation.current.rack,
            archiveRow: archiveLocation.current.row,
            archiveCol: archiveLocation.current.col
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            addActivity('lab', '', '', 'archive', 'update', '', props.user.username)

            fetchData()
            props.fetchData() // updates source, ie archive, todo
            props.savedData()
            pending.current = []
            archiveLocation.current = {rack: 1, row: null, col: null}

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const updateDispose = () => {

    if (pending.current.length > 0) {

      getLocation(function(latlng){
        let lat = latlng.lat
        let lng = latlng.lng

        fetch('/api/updateDispose', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            pending: pending.current,
            lat: lat,
            lng: lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            fetchData()

            props.savedData()
            pending.current = []
            archiveLocation.current = {rack: 1, row: null, col: null}

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    } else {
      alert('Please select a sample to assign to be thrown out')
    }

  }

  const updateDisposed = () => {

    if (pending.current.length > 0) {

      getLocation(function(latlng){
        let lat = latlng.lat
        let lng = latlng.lng

        fetch('/api/updateDisposed', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            pending: pending.current,
            lat: lat,
            lng: lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            fetchData()

            props.savedData()
            pending.current = []
            archiveLocation.current = {rack: 1, row: null, col: null}

          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    } else {
      alert('Please select a sample to throw away')
    }

  }

  let phaseIds = ['P','C']
  let tableContent, listOfData

  if (viewBy === 'Samples') {

    tableContent = phaseIds.map(phase => {

      let jobs = fetchedJobs.map(job => {

        let countSamples = 0

        let samples = fetchedSamples.map((sample, i) => {

          if (job.jobnumber === sample.jobnumber && phase === sample.phaseid) {


            let styleType = {
              color: 'white',
              backgroundColor:
                sample.sampletype === 'bulk' ? 'dodgerblue' :
                sample.sampletype === 'ca' ? 'orange':
                sample.sampletype === 'spt' ? 'mediumseagreen' :
                sample.sampletype === 'smbag' ? 'slateblue' :
                sample.sampletype === 'ac' ? 'gray' :
                sample.sampletype === 'shelby' ? 'violet' : 'tomato',
              width: sample.phaseid === 'P' ? 50 : 100
            }

            let jobNumber = sample.jobnumber === null ? '' : sample.jobnumber
            let phase = sample.phaseid === 'P' ? 'Invest' : sample.phaseid === 'C' ? 'Const' : ''
            let location = sample.location === null ? '' : sample.location
            let depth = sample.depth === null ? '' : sample.depth
            let sampleNo = sample.sampleno === null ? '' : sample.sampleno.toString()
            let represents = sample.represents === null ? '' : sample.represents
            let purpose = sample.purpose === null ? '' : sample.purpose
            let sampleType = sample.sampletype === null ? '' : sample.sampletype

            // labid AS id, jobnumber, phaseid, sampleno, location, represents, purpose, depth, sampletype, archiveRack, archiveRow, archiveCol

            if (
              searchValue === '' ||
              jobNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              phase.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              location.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              depth.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              sampleNo.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              represents.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              purpose.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
              sampleType.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
            ) {

              countSamples++ // removes header if no records

              return (
                <tr key={sample.id.toString()}>
                  {is.archive ? <td><Icon name={sample.isSelected ? 'check_circle' : 'panorama_fish_eye'} i={i} onClick={updatePending} /></td> : null}
                  {is.dispose ? <td><Icon name={sample.isSelected ? 'check_circle' : 'panorama_fish_eye'} color='tomato' i={i} onClick={updatePending} /></td> : null}
                  {is.disposed ? <td><Icon name={sample.isSelected ? 'check_circle' : 'panorama_fish_eye'} color='tomato' i={i} onClick={updatePending} /></td> : null}
                  {sample.phaseid === 'P' ?
                    <>
                      <td>{sample.location}</td>
                      <td>{sample.depth}</td>
                    </> :
                    <>
                      <td>{sample.sampleno}</td>
                      <td>{sample.location}</td>
                      <td>{sample.represents}</td>
                      <td>{sample.purpose}</td>
                    </>
                  }

                  <td style={styleType}>{sample.sampletype}</td>
                  <td>{sample.archiveRack}</td>
                  <td>{sample.archiveRow}</td>
                  <td>{sample.archiveCol}</td>
                  <td>{sample.archivetime}</td>
                </tr>
              )
            }

          }

        })

        let sampleList =
          (
            <table>
              <thead>
                <tr className='tableHeader_alt2'>
                  {is.archive ? <th><Icon name={job.isSelectedP ? 'check_circle' : 'panorama_fish_eye'} jobnumber={job.jobnumber} phase={phase} btn='job' onClick={updatePending} /></th> : null}
                  {is.dispose ? <th><Icon name={job.isSelectedP ? 'check_circle' : 'panorama_fish_eye'} color='tomato' jobnumber={job.jobnumber} phase={phase} btn='job' onClick={updatePending} /></th> : null}
                  {is.disposed ? <th><Icon name={job.isSelectedP ? 'check_circle' : 'panorama_fish_eye'} color='tomato' jobnumber={job.jobnumber} phase={phase} btn='job' onClick={updatePending} /></th> : null}
                  {phase === 'P' ?
                    <>
                      <th>Location</th>
                      <th>Depth</th>
                    </> :
                    <>
                      <th>SN</th>
                      <th>Location</th>
                      <th>Represents</th>
                      <th>Purpose</th>
                    </>
                  }
                  <th>Type</th>
                  <th>Rack</th>
                  <th>Row</th>
                  <th>Col</th>
                </tr>
              </thead>
              <tbody>
                {samples}
              </tbody>
            </table>
          )

        let foundSamples = countSamples > 0 ?
          (
            <div>
              <div className='jobHeader_alt2'>
                <h2 style={{display: 'inline-block'}}>{job.jobnumber} {job.company} - {job.city}</h2>
              </div>
              {sampleList}
            </div>
          ) : null

        return (
          foundSamples
        )

      })

      return jobs

    })

  } else if (viewBy === 'Jobs') {

    listOfData = fetchedJobs.map(data => {

      let jobNumber = data.jobnumber === null ? '' : data.jobnumber
      let company = data.company === null ? '' : data.company
      let city = data.city === null ? '' : data.city

      if (
        searchValue === '' ||
        jobNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        company.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
        city.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
      ) {
        return (
          <tr key={data.jobnumber.toString()} onClick={null}>
            {is.dispose ? <td><Icon name={data.isSelectedP ? 'check_circle' : 'panorama_fish_eye'} color='tomato' jobnumber={data.jobnumber} phase='all' btn='job' onClick={updatePending} /></td> : null}
            {is.disposed ? <td><Icon name={data.isSelectedP ? 'check_circle' : 'panorama_fish_eye'} color='tomato' jobnumber={data.jobnumber} phase='all' btn='job' onClick={updatePending} /></td> : null}
            <td>{jobNumber}</td>
            <td>{company}</td>
            <td>{city}</td>
          </tr>
        )
      }

    })

  }

  let styleNonActive = {
    borderRadius: 5,
    margin: 10,
    padding: 10,
    cursor: 'pointer'
  }

  let styleActive = {
    borderRadius: 5,
    margin: 10,
    padding: 10,
    cursor: 'pointer',
    backgroundColor: 'dodgerblue',
    color: 'white'
  }

  let styleBtn = {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    borderRadius: 10,
    border: '2px solid dodgerblue',
    margin: 10
  }

  let content = (
    <div>
      {is.racks ? <ArchiveRacks filter={props.filter} addShelf={addShelf} closeModal={closeRacks} modal={true} selectSamples={props.selectSamples} /> : null}

      <div style={{margin: 10}}>
        <span>View By:</span>
        <span className='menuOptions'  style={viewBy === 'Jobs' ? styleActive : styleNonActive} onClick={selectViewBy}>Jobs</span>
        <span style={{borderRight: '1px solid gray'}}></span>
        <span className='menuOptions' style={viewBy === 'Samples' ? styleActive : styleNonActive} onClick={selectViewBy}>Samples</span>
      </div>

      <div style={{display: 'flex', alignItems: 'center', marginTop: 20}}>
        {(props.status === 'archive' || props.status === 'archived') && props.user.archive > 1 ?
          <div style={styleBtn} onClick={toggleArchive}>
            <Icon name='edit' color={is.archive ? 'dodgerblue' : 'gray'} />
            <span style={{marginRight: 10}}>Add/Edit</span>
          </div> : null
        }
        {(props.status === 'archive' || props.status === 'archived') && props.user.archive > 1 && is.archive ?
          <div style={styleBtn} onClick={openRacks}>
            <Icon name='edit_location' color='#FFC04D' />
            <span style={{marginRight: 10}}>Select Rack</span>
          </div> : null
        }
        {props.status === 'archived' && props.user.archive > 2 ?
          <div style={styleBtn} onClick={toggleDispose}>
            <Icon name='delete' color={is.dispose ? 'tomato' : 'gray'} />
            <span style={{marginRight: 10}}>Assign To Be Thrown Out</span>
          </div> : null
        }
        {props.status === 'dispose' && props.user.archive > 1 ?
          <div style={styleBtn} onClick={toggleDisposed}>
            <Icon name='delete' color={is.disposed ? 'tomato' : 'gray'} />
            <span style={{marginRight: 10}}>Throw Away</span>
          </div> : null
        }
      </div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div>

        <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

          {viewBy === 'Samples' ?
            tableContent :
            <table>

              <thead>
                <tr>
                  {is.dispose || is.disposed ? <th></th> : null}
                  <th>JN</th>
                  <th>Client</th>
                  <th>City</th>
                </tr>
              </thead>

              <tbody>
                {listOfData}
              </tbody>

            </table>
          }

        </div>

      </div>

    </div>
  )

  return <Modal add={is.archive ? updateArchive : is.dispose ? updateDispose : is.disposed ? updateDisposed : null} content={content} closeModal={props.closeModal} maxWidth='none' />
}

export default Archive
