import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Mds = (props) => {

  const data = props.data[0]
  const [fetchedDescriptions, setFetchedDescriptions] = useState([])
  const [fetchedColors, setFetchedColors] = useState([])
  //const [isChangedSample, setIsChangedSample] = useState(false)

  const [isValidated, setIsValidated] = useState({
    densityTaken: data.densityTaken === 1 ? true : false,
    disturbed: data.disturbed,
    ringNo: data.ringno,
    wetSoilAndRingWt: data.wetsoilandringwt,
    tareNo: data.tareNo,
    tareWt: data.tarewt,
    wetSoilAndTareWt: data.wetsoilandtarewt,
    drySoilAndTareWt: data.drysoilandtarewt,
    ppTop: data.ppTop,
    ppBottom: data.ppBottom,
    descriptionTop: data.descriptionTop,
    descriptionBottom: data.descriptionBottom,
    colorTop: data.colorTop,
    colorBottom: data.colorBottom,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const fetchData = () => {

    fetch('/api/selectDescription', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setFetchedDescriptions(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

    fetch('/api/selectColor', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
    })
    .then(res=>res.json())
    .then(
      (result) => {
        setFetchedColors(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateMds', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            densityTaken: isValidated.densityTaken,
            disturbed: isValidated.disturbed,
            ringNo: isValidated.ringNo,
            wetSoilAndRingWt: isValidated.wetSoilAndRingWt,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            wetSoilAndTareWt: isValidated.wetSoilAndTareWt,
            drySoilAndTareWt: isValidated.drySoilAndTareWt,
            ppTop: isValidated.ppTop,
            ppBottom: isValidated.ppBottom,
            descriptionTop: isValidated.descriptionTop,
            descriptionBottom: isValidated.descriptionBottom,
            colorTop: isValidated.colorTop,
            colorBottom: isValidated.colorBottom
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'mds', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusmds',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.tareNo === '' || isValidated.tareNo === null) {
        alert('Please specify a tare number.')
      } else if (isValidated.tareWt === '' || isValidated.tareWt === null) {
        alert('Please specify a tare weight.')
      } else if (isValidated.wetSoilAndTareWt === '' || isValidated.wetSoilAndTareWt === null) {
        alert('Please specify a wet soil and tare weight.')
      } else if (isValidated.drySoilAndTareWt === '' || isValidated.drySoilAndTareWt === null) {
        alert('Please specify a dry soil and tare weight.')
      } else if (isValidated.descriptionTop === '' || isValidated.descriptionTop === null) {
        alert('Please specify a top description.')
      } else if (isValidated.descriptionBottom === '' || isValidated.descriptionBottom === null) {
        alert('Please specify a bottom description.')
      } else if (isValidated.colorTop === '' || isValidated.colorTop === null) {
        alert('Please specify a top color.')
      } else if (isValidated.colorBottom === '' || isValidated.colorBottom === null) {
        alert('Please specify a bottom color.')
      } else if (isValidated.densityTaken) {

        if (isValidated.disturbed === 'yes') {
          update()
        } else if (isValidated.disturbed === '' || isValidated.disturbed === null) {
          alert('Please specify if disturbed.')
        } else if (isValidated.ringNo === '' || isValidated.ringNo === null) {
          alert('Please provide the number of rings.')
        } else if (isValidated.wetSoilAndRingWt === '' || isValidated.wetSoilAndRingWt === null) {
          alert('Please specify a wet soil and ring weight.')
        } else if (isValidated.ppTop === '' || isValidated.ppTop === null) {
          alert('Please specify a top pocket pen.')
        } else if (isValidated.ppBottom === '' || isValidated.ppBottom === null) {
          alert('Please specify a bottom pocket pen.')
        } else {
          update()
        }

      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const clearDensityInputs = (event) => {
    let value = event.target.checked

    if (!value) {
      if (window.confirm('Remove density if found?')) {

        setIsValidated(prevState => ({...prevState,
          disturbed: '',
          ringNo: null,
          wetSoilAndRingWt: null,
          ppTop: '',
          ppBottom: ''
        }))

      } else {
        event.target.checked = true

        // the below code isnt necessary i believe. if cb is set to true, it doesnt change.
        // Therefore onChange doesnt trigger. However this is safer for now

        setIsValidated(prevState => ({...prevState,
          densityTaken: true
        }))
      }
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let descriptionOptions = fetchedDescriptions.map(description => (
    <option value={description.description}>{description.description}</option>
  ))

  let descriptionTop = fetchedDescriptions.length > 0 ?
    <label>Description
      <select className='select' pattern=".{1,}" name='descriptionTop' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.descriptionTop} required>
        <option value=""></option>
        {descriptionOptions}
      </select>
    </label> :
    null

  let descriptionBottom = fetchedDescriptions.length > 0 ?
    <label>Description
      <select className='select' pattern=".{1,}" name='descriptionBottom' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.descriptionBottom} required>
        <option value=""></option>
        {descriptionOptions}
      </select>
    </label> :
    null

  let colorOptions = fetchedColors.map(color => (
    <option value={color.color}>{color.color}</option>
  ))

  let colorTop = fetchedColors.length > 0 ?
    <label>Color
      <select className='select' pattern=".{1,}" name='colorTop' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.colorTop} required>
        <option value=""></option>
        {colorOptions}
      </select>
    </label> :
    null

  let colorBottom = fetchedColors.length > 0 ?
    <label>Color
      <select className='select' pattern=".{1,}" name='colorBottom' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.colorBottom} required>
        <option value=""></option>
        {colorOptions}
      </select>
    </label> :
    null

  let avgRingWt = data.sampletype === 'ca' ? 45 : 71 // 71 for shelbies
  let ringFactor = data.sampletype === 'ca' ? 0.828 : 0.587 // for shelbies
  let sg = 2.7
  let wtOfRings = avgRingWt * Number(isValidated.ringNo)
  let wtOfSoil = Number(isValidated.wetSoilAndRingWt) - wtOfRings
  let wetDensity = Math.round(10*(wtOfSoil * ringFactor)/Number(isValidated.ringNo))/10

  let wtOfWater = Number(isValidated.wetSoilAndTareWt) - Number(isValidated.drySoilAndTareWt)
  let wtOfDrySoil = Number(isValidated.drySoilAndTareWt) - Number(isValidated.tareWt)
  let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10
  let dryDensity = Math.round(10*wetDensity/(1+(moistureContent/100)))/10
  let saturation = Math.round(10*(moistureContent/((62.4/dryDensity)-(1/sg))))/10

  let densityInputs = isValidated.densityTaken ?
  (
    <div style={{display: 'inline-block', textAlign: 'right'}}>

      <label>Disturbed
        <select style={{width: 50}} className='select' pattern=".{1,}" name='disturbed' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.disturbed} required>
          <option value={null}></option>
          <option value='no'>No</option>
          <option value='yes'>Yes</option>
        </select>
      </label><br />

      {isValidated.disturbed !== 'yes' ?

        <>
          <label>No of Rings
            <select style={{width: 50}} className='select' pattern=".{1,}" name='ringNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ringNo} required>
              <option value={null}></option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
            </select>
          </label><br />

          <label>Wet Soil and Ring Wt (g)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='wetSoilAndRingWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.wetSoilAndRingWt} required />
          </label><br />

          <label><b>Wet Density (pcf)</b>
            <input style={{width: 75}} className='input' type="text" value={wetDensity} disabled />
          </label><br />
        </>  : null

      }

    </div>
  ) : null

  return (
    <div style={{display: 'inline-block', margin: 10}}>

      {data.sampletype === 'ca' || data.sampletype === 'shelby' ?

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

          <div>
            <h3 style={{display: 'inline-block'}}>Density?</h3>
            <input style={{display: 'inline-block', margin: 10, height: 20, width: 20}} type='checkbox' pattern="{1,}" name='densityTaken' onInput={validate} onChange={props.changedSample} onClick={clearDensityInputs} defaultChecked={isValidated.densityTaken} />
            <small> (Shelby included)</small>
          </div>

        </div> :

        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

          <span>This sample is a {data.sampletype}. Only sample type 'ca' or 'shelby' can have densities.</span>

        </div>

      }

      {isValidated.densityTaken ?

        <div>
          <div><small>Sample type: {data.sampletype}</small></div>
          <div><small>Avg ring wt: {avgRingWt}</small></div>
          <div><small>Ring factor: {ringFactor}</small></div>
          <div><small>Specific Gravity: {sg}</small></div>
        </div> : null

      }

      {densityInputs}

      <div style={{borderTop: '1px solid #d3d3d3', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <h3>Moisture</h3>

      </div>

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <label>Tare No
          <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9 ]{1,}" name='tareNo' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.tareNo} required />
        </label><br />

        <label>Tare Wt (g)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='tareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.tareWt} required />
        </label><br />

        <label>Wet Soil and Tare Wt (g)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='wetSoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.wetSoilAndTareWt} required />
        </label><br />

        <label>Dry Soil and Tare Wt (g)
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='drySoilAndTareWt' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.drySoilAndTareWt} required />
        </label><br />

        <label><b>Moisture Content (%)</b>
          <input style={{width: 75}} className='input' type="text" value={moistureContent} disabled />
        </label><br />

        {isValidated.densityTaken && isValidated.disturbed === 'no' ?

          <>
            <label><b>Dry Density (pcf)</b>
              <input style={{width: 75}} className='input' type="text" value={dryDensity} disabled />
            </label><br />

            <label><b>Saturation (%)</b>
              <input style={{width: 75}} className='input' type="text" value={saturation} disabled />
            </label><br />
          </> : null

       }

      </div>

      <div style={{borderTop: '1px solid #d3d3d3', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>

        <h3>Description</h3>

      </div>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <h4>Top</h4>

      </div>

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <div>
          {descriptionTop}
        </div>

        <div>
          {colorTop}
        </div>

        {isValidated.densityTaken ?
          <>
            <label>Pocket Pen</label>
              <select className='select' pattern=".{1,}" name='ppTop' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ppTop} required>
              <option value={null}></option>
              <option value='0'>0</option>
              <option value='0.25'>0.25</option>
              <option value='0.5'>0.5</option>
              <option value='0.75'>0.75</option>
              <option value='1'>1</option>
              <option value='1.25'>1.25</option>
              <option value='1.5'>1.5</option>
              <option value='1.75'>1.75</option>
              <option value='2'>2</option>
              <option value='2.25'>2.25</option>
              <option value='2.5'>2.5</option>
              <option value='2.75'>2.75</option>
              <option value='3'>3</option>
              <option value='3.25'>3.25</option>
              <option value='3.5'>3.5</option>
              <option value='3.75'>3.75</option>
              <option value='4'>4</option>
              <option value='4.25'>4.25</option>
              <option value='4.5'>4.5</option>
              <option value='4.5+'>4.5+</option>
            </select>
          </> : null
        }

      </div>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <h4>Bottom</h4>

      </div>

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <div>
          {descriptionBottom}
        </div>

        <div>
          {colorBottom}
        </div>

        {isValidated.densityTaken ?
          <>
            <label>Pocket Pen</label>
            <select className='select' pattern=".{1,}" name='ppBottom' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ppBottom} required>
              <option value={null}></option>
              <option value='0'>0</option>
              <option value='0.25'>0.25</option>
              <option value='0.5'>0.5</option>
              <option value='0.75'>0.75</option>
              <option value='1'>1</option>
              <option value='1.25'>1.25</option>
              <option value='1.5'>1.5</option>
              <option value='1.75'>1.75</option>
              <option value='2'>2</option>
              <option value='2.25'>2.25</option>
              <option value='2.5'>2.5</option>
              <option value='2.75'>2.75</option>
              <option value='3'>3</option>
              <option value='3.25'>3.25</option>
              <option value='3.5'>3.5</option>
              <option value='3.75'>3.75</option>
              <option value='4'>4</option>
              <option value='4.25'>4.25</option>
              <option value='4.5'>4.5</option>
              <option value='4.5+'>4.5+</option>
            </select>
          </> : null
        }

        <div style={{textAlign: 'center', marginTop: 10}}>

          <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

          <SentOut data={data} onInput={validate} onChange={props.changedSample} />

          <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

          {editSampleIcon}

        </div>

      </div>

    </div>
  )

}

export default Mds
