import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Acsa = (props) => {

  const data = props.data[0]
  //const [isChangedSample, setIsChangedSample] = useState(false)

  const [isValidated, setIsValidated] = useState({
    ac3_2: data.ac3_2,
    ac1: data.ac1,
    ac3_4: data.ac3_4,
    ac1_2: data.ac1_2,
    ac3_8: data.ac3_8,
    ac4: data.ac4,
    ac8: data.ac8,
    ac30: data.ac30,
    ac50: data.ac50,
    ac200: data.ac200,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateAcsa', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            ac3_2: isValidated.ac3_2,
            ac1: isValidated.ac1,
            ac3_4: isValidated.ac3_4,
            ac1_2: isValidated.ac1_2,
            ac3_8: isValidated.ac3_8,
            ac4: isValidated.ac4,
            ac8: isValidated.ac8,
            ac30: isValidated.ac30,
            ac50: isValidated.ac50,
            ac200: isValidated.ac200
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            //setIsChangedSample(false)
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'acsa', 'update', description, props.user.username)

            // if (isValidated.status === 'completed') {
            //   props.closeTestModal()
            // }

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusacsa',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.ac200 === '' || isValidated.ac200 === null) {
        alert('Please provide at least the 200.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  //const changedSample = () => setIsChangedSample(true)

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  return (

    <div style={{margin: 10, display: 'inline-block', textAlign: 'right'}}>

      <label>3/2" (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac3_2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac3_2} required />
      </label><br />

      <label>1" (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac1' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac1} required />
      </label><br />

      <label>3/4" (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac3_4' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac3_4} required />
      </label><br />

      <label>1/2" (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac1_2' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac1_2} required />
      </label><br />

      <label>3/8" (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac3_8' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac3_8} required />
      </label><br />

      <label>#4 (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac4' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac4} required />
      </label><br />

      <label>#8 (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac8' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac8} required />
      </label><br />

      <label>#30 (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac30' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac30} required />
      </label><br />

      <label>#50 (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac50' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac50} required />
      </label><br />

      <label>#200 (%)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,3}(\.\d{1})?" name='ac200' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.ac200} required />
      </label>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

        <SentOut data={data} onInput={validate} onChange={props.changedSample} />

        <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

        {editSampleIcon}

      </div>

    </div>

  )

}

export default Acsa
