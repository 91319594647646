import React, { useState, useEffect } from 'react'
import Icon from 'components/utils/Icon'
import SearchBar from 'components/utils/SearchBar'
import Modal from 'components/utils/Modal'
import TestModal from 'components/utils/TestModal'

import { formatDateYMD } from 'scripts/common'

const ViewByList = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [isList, setIsList] = useState(false)
  const [selected, setSelected] = useState('mds')

  const [selectSample, setSelectSample] = useState({
    id: null,
    field: null,
    data: null
  })

  const [isModal, setIsModal] = useState({
    add: false
  })

  const fetchData = () => {

    fetch('/api/selectResultsByListView', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jobNumber: props.filter.jobNumber,
        jobs: props.fetchedJobs,
        table: selected,
        tech: props.tech
      }) //, status: status})
    })
    .then(res=>res.json())
    .then(
      (result) => {

        //console.log(`result: ${JSON.stringify(result)}`)
        setFetchedData(result)
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    //if (props.filter.jobNumber !== '')
    fetchData()
  }, [props.filter, selected])

  const updateOrigin = () => {
    fetchData()
    props.fetchData()
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let id = td[0].textContent //tr.getAttribute('key') //td[0].textContent
    let field = tr.getAttribute('data-field')

    if (id === '' || id === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {
      console.log(`id: ${id} ~ ${field}`)
      setSelectSample({id: id, field: field})
      openAdd()

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))
  const closeAdd = () => setIsModal(prevState => ({...prevState, add: false}))

  // const openList = () => setIsList(true)
  // const closeList = () => setIsList(false)

  const selectListTest = (e) => {
    setSelected(e.target.textContent)
  }

  let listOfData = fetchedData.length > 0 ? fetchedData.map(data => {

    //console.log(`list data: ${JSON.stringify(data)}`)

    // phaseid, jobnumber, sampleno, location, depth, represents, purpose, sampletype, description, uscs, color, additionalcolor

    let jobNumber = data.jobnumber === null ? '' : data.jobnumber
    let phase = data.phaseid === 'P' ? 'Invest' : data.phaseid === 'C' ? 'Const' : ''
    let sampleNo = data.sampleno === null ? '' : data.sampleno.toString()
    let location = data.location === null ? '' : data.location
    let depth = data.depth === null ? '' : data.depth
    let sampleType = data.sampletype === null ? '' : data.sampletype
    let description = data.description === null ? '' : data.description
    let color = data.color === null ? '' : data.color
    let status = data.status === null ? '' : data.status
    let dueDate = data.duedate === null ? '' : formatDateYMD(data.duedate)
    let comments = data.comments === null ? '' : data.comments
    let notes = data.notes === null ? '' : data.notes

    if (
      (searchValue === '' ||
      jobNumber.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      phase.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleNo.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      location.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      depth.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      sampleType.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      color.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      status.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      dueDate.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      comments.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      notes.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)
    ) {

      let reviewed = 'black'
      let completed = 'gray'
      let started = 'yellow'
      let assigned = 'skyblue'

      let styleStatus = {
        color: data.status === 'reviewed' ? 'white' : 'black',
        backgroundColor: data.status === 'reviewed' ? reviewed :
        data.status === 'completed' ? completed :
        data.status === 'started' ? started :
        data.status === 'assigned' ? assigned : 'none'
      }

      let sampleInfo = (
        <>
          <td style={{display: 'none'}}>{data.labid}</td>
          <td>{jobNumber}</td>
          {data.phaseid === 'P' ?
            <>
              <td>{location}</td>
              <td>{depth}</td>
            </> :
            <>
              <td>{sampleNo}</td>
              <td>{location}</td>
            </>
          }
          <td>{dueDate}</td>
          <td>{description}</td>
          <td>{color}</td>
          <td style={styleStatus}>{status}</td>
        </>
      )

      if (selected === 'mds') {

        let avgRingWt = 45
        let ringFactor = 0.828
        let sg = 2.7
        let wtOfRings = avgRingWt * Number(data.ringno)
        let wtOfSoil = Number(data.wetsoilandringwt) - wtOfRings
        let wetDensity = Math.round(10*(wtOfSoil * ringFactor)/Number(data.ringno))/10

        let wtOfWater = Number(data.wetsoilandtarewt) - Number(data.drysoilandtarewt)
        let wtOfDrySoil = Number(data.drysoilandtarewt) - Number(data.tarewt)
        let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10
        let dryDensity = Math.round(10*wetDensity/(1+(moistureContent/100)))/10
        let saturation = Math.round(10*(moistureContent/((62.4/dryDensity)-(1/sg))))/10

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{data.drysoilandtarewt !== null && data.drysoilandtarewt !== '' ? moistureContent : ''}</td>
            <td>{data.densityTaken === 1 ? data.disturbed === 'no' ? dryDensity : 'Dist' : ''}</td>
            <td>{data.densityTaken === 1 ? data.disturbed === 'no' ? saturation : '' : ''}</td>
          </tr>
        )

      } else if (selected === 'max') {

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{data.curve}</td>
            <td>{data.optd}</td>
            <td>{data.optm}</td>
          </tr>
        )

      } else if (selected === 'ei') {

        let expansionIndex = data.finalDialReading !== null && data.finalDialReading !== '' && data.initialDialReading !== null && data.initialDialReading !== '' ?
        Math.round((data.finalDialReading - data.initialDialReading) * 1000) : data.ei !== null && data.ei !== '' ? data.ei : ''
        let expansionPotential = expansionIndex !== '' ? expansionIndex < 21 ? 'Very Low' : expansionIndex < 51 ? 'Low' : expansionIndex < 91 ? 'Medium' : expansionIndex < 131 ? 'High' : 'Very High' : ''

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{expansionIndex}</td>
            <td>{expansionPotential}</td>
          </tr>
        )

      } else if (selected === 'so4') {

        let sulfateIndex
        let dilution0 = data.dilution0/1000
        let dilution10 = data.dilution10/100
        let dilution100 = data.dilution100/10
        let dilution1000 = data.dilution1000

        let sulfateExposure

        if (data.dilution1000 !== null && data.dilution1000 !== '') {

          sulfateIndex = dilution1000
          sulfateExposure = dilution1000 < 0.1 ? 'Negligible' : dilution1000 < 0.2 ? 'Moderate' : dilution1000 < 2 ? 'Severe' : 'Very Severe'

        } else if (data.dilution100 !== null && data.dilution100 !== '') {

          sulfateIndex = dilution100
          sulfateExposure = dilution100 < 0.1 ? 'Negligible' : dilution100 < 0.2 ? 'Moderate' : dilution100 < 2 ? 'Severe' : 'Very Severe'

        } else if (data.dilution10 !== null && data.dilution10 !== '') {

          sulfateIndex = dilution10
          sulfateExposure = dilution10 < 0.1 ? 'Negligible' : dilution10 < 0.2 ? 'Moderate' : dilution10 < 2 ? 'Severe' : 'Very Severe'

        } else if (data.dilution0 !== null && data.dilution0 !== '') {

          sulfateIndex = dilution0
          sulfateExposure = dilution0 < 0.1 ? 'Negligible' : dilution0 < 0.2 ? 'Moderate' : dilution0 < 2 ? 'Severe' : 'Very Severe'

        } else if (data.so4 !== null && data.so4 !== '') {

          sulfateIndex = data.so4
          sulfateExposure = data.so4 < 0.1 ? 'Negligible' : data.so4 < 0.2 ? 'Moderate' : data.so4 < 2 ? 'Severe' : 'Very Severe'

        } else {

          sulfateIndex = ''
          sulfateExposure = ''

        }

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{sulfateIndex}</td>
            <td>{sulfateExposure}</td>
          </tr>
        )

      } else if (selected === 'se') {

        let se1 = Math.ceil((data.sand1 / data.clay1) * 100)
        let se2 = Math.ceil((data.sand2 / data.clay2) * 100)
        let se3 = Math.ceil((data.sand3 / data.clay3) * 100)

        let avgSe = Math.ceil((se1 + se2 + se3) / 3)

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{avgSe}</td>
          </tr>
        )

      } else if (selected === 'att') {

        let ll = data.ll === null || data.ll === '' ? '' : data.ll
        let pi = data.ll === null || data.ll === '' || data.pl === null || data.pl === '' ? '' : Math.round((data.ll - data.pl) * 10) / 10

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{ll}</td>
            <td>{pi}</td>
          </tr>
        )

      } else if (selected === 'consol') {

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{data.rack}</td>
          </tr>
        )

      } else if (selected === '200') {

        let wtOfWater = Number(data.wetsoilandtarewt) - Number(data.drysoilandtarewt)
        let wtOfDrySoil = Number(data.drysoilandtarewt) - Number(data.mctarewt)
        let moistureContent = Math.round(10*wtOfWater/wtOfDrySoil*100)/10

        let totalWt = data.moistureTaken ? (Number(data.totalwt) - Number(data.tarewt)) / (1 + (moistureContent/100)) : Number(data.totalwt) - Number(data.tarewt)
        let retainWt = Number(data.retainwt) - Number(data.tarewt)

        let retained200 = Math.round(10*(retainWt / totalWt) * 100)/10
        let passed200 = Math.round(10*(100 - retained200))/10

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{retained200}</td>
            <td>{passed200}</td>
          </tr>
        )

      } else if (selected === 'rval') {

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{data.rval}</td>
            <td>{data.exudationPressure}</td>
            <td>{data.pressure}</td>
            <td>{data.expansionPressure}</td>
            <td>{data.pressure}</td>
          </tr>
        )

      } else if (selected === 'ph') {

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{data.ph}</td>
          </tr>
        )

      } else if (selected === 'ch') {

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td>{data.ch}</td>
          </tr>
        )

      } else if (selected === 'resist') {

        return (
          <tr key={data.id.toString()} data-field={`status${selected}`} onClick={selectRow}>
            {sampleInfo}
            <td></td>
          </tr>
        )

      }

    }

  }) : <p>No tests found.</p>

  let active = {
    backgroundColor: 'dodgerblue',
    border: '1px solid dodgerblue',
    borderRadius: 5,
    margin: 5,
    padding: 5,
    color: 'white',
    cursor: 'pointer'
  }

  let notActive = {
    border: '1px solid gray',
    borderRadius: 5,
    margin: 5,
    padding: 5,
    color: 'gray',
    cursor: 'pointer'
  }

  const exportToExcel = () => {

    let downloadLink;
    let dataType = 'application/vnd.ms-excel';
    let tableSelect = document.getElementById('toExcel');
    let tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');

    // Specify file name
    let filename = 'download.xls';

    // Create download link element
    downloadLink = document.createElement("a");

    document.body.appendChild(downloadLink);

    if (navigator.msSaveOrOpenBlob) {

      let blob = new Blob(['\ufeff', tableHTML], {
          type: dataType
      });
      navigator.msSaveOrOpenBlob( blob, filename)

    } else {
      // Create a link to the file
      downloadLink.href = 'data:' + dataType + ', ' + tableHTML

      // Setting the file name
      downloadLink.download = filename

      //triggering the function
      downloadLink.click()
    }

  }

  let content = (
    <div>
      {isModal.add ?
        <TestModal
          id={selectSample.id}
          field={selectSample.field}
          user={props.user}
          who={isModal.tech === undefined ? 'results' : isModal.tech ? 'todoTech' : 'todoManager'}
          onClick={closeAdd}
          updateOrigin={updateOrigin}
        /> : null}

      <div style={{margin: '10px 10px 20px'}}>
        <span style={selected === 'mds' ? active : notActive} onClick={selectListTest}>mds</span>
        <span style={selected === 'max' ? active : notActive} onClick={selectListTest}>max</span>
        <span style={selected === 'ei' ? active : notActive} onClick={selectListTest}>ei</span>
        <span style={selected === 'so4' ? active : notActive} onClick={selectListTest}>so4</span>
        <span style={selected === 'consol' ? active : notActive} onClick={selectListTest}>consol</span>
        <span style={selected === 'att' ? active : notActive} onClick={selectListTest}>att</span>
        <span style={selected === '200' ? active : notActive} onClick={selectListTest}>200</span>
        <span style={selected === 'se' ? active : notActive} onClick={selectListTest}>se</span>
        <span style={selected === 'rval' ? active : notActive} onClick={selectListTest}>rval</span>
        <span style={selected === 'ph' ? active : notActive} onClick={selectListTest}>ph</span>
        <span style={selected === 'ch' ? active : notActive} onClick={selectListTest}>ch</span>
        <span style={selected === 'resist' ? active : notActive} onClick={selectListTest}>resist</span>
        <span style={notActive}>more coming soon</span>
      </div>

      <div><Icon name='download' onClick={exportToExcel} /></div>

      <SearchBar search={search} clearSearch={clearSearch} />

      <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>

        <table id="toExcel">

          <thead>
            <tr>
              <th style={{display: 'none'}}>id</th>
              <th>JN</th>
              <th></th>
              <th></th>
              <th>Due</th>
              <th>Desc</th>
              <th>Color</th>
              <th>Status</th>
              {
                selected === 'mds' ?
                <>
                  <th>Moist</th>
                  <th>Dry Dens</th>
                  <th>Sat</th>
                </> :
                selected === 'max' ?
                <>
                  <th>Curve</th>
                  <th>Opt Dens</th>
                  <th>Opt Moist</th>
                </> :
                selected === 'ei' ?
                <>
                  <th>Index</th>
                  <th>Potential</th>
                </> :
                selected === 'so4' ?
                <>
                  <th>Index</th>
                  <th>Exposure</th>
                </> :
                selected === 'consol' ?
                <>
                  <th>Rack</th>
                </> :
                selected === 'se' ?
                <>
                  <th>SE</th>
                </> :
                selected === 'att' ?
                <>
                  <th>LL</th>
                  <th>PI</th>
                </> :
                selected === '200' ?
                <>
                  <th>Retained 200</th>
                  <th>Passing 200</th>
                </> :
                selected === 'rval' ?
                <>
                  <th>R-value (pre App)</th>
                  <th>By Exudation Pressure</th>
                  <th>Pressure (psi)</th>
                  <th>By Expansion Pressure</th>
                  <th>TI</th>
                </> :
                selected === 'ph' ?
                <>
                  <th>pH</th>
                </> :
                selected === 'ch' ?
                <>
                  <th>Chloride Content (ppm)</th>
                </> :
                selected === 'resist' ?
                <>
                  <th>Coming soon</th>
                </> :
                null
              }
            </tr>
          </thead>

          <tbody>
            {listOfData}
          </tbody>

        </table>

      </div>

    </div>
  )

  return <Modal content={content} closeModal={props.closeModal} maxWidth='none' />
}

export default ViewByList
