import React from "react";

import 'styles/container.css'

import Home from './Home'
import Add from './Add'
import Assign from './Assign'
import Todo from './Todo'
import Results from './Results'
import Archive from './Archive'
import Manage from './Manage'

const Container = (props) => {

  return (
    <>
      {props.component === 'Home' ? <Home user={props.user} filter={props.filter} /> : null}
      {props.component === 'Add' ? <Add user={props.user} filter={props.filter} /> : null}
      {props.component === 'Assign' ? <Assign user={props.user} filter={props.filter} /> : null}
      {props.component === 'Todo' ? <Todo user={props.user} filter={props.filter} /> : null}
      {props.component === 'Results' ? <Results user={props.user} filter={props.filter} /> : null}
      {props.component === 'Archive' ? <Archive user={props.user} filter={props.filter} /> : null}
      {props.component === 'Manage' ? <Manage user={props.user} filter={props.filter} component={props.component} componentManage={props.componentManage} /> : null}
    </>
  )
}

export default Container
