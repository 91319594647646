import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'

import { formatDateYMD, formatDateTime, getLocation, catchError } from 'scripts/common'

const History = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    google: false,
    loading: true
  })

  const isChanged = useRef(false)

  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null
  })

  const fetchData = () => {

    fetch('/api/selectHistory', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter: props.filter
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)

        setFetchedData(result.map((data, i) => {

          return {...data,
            time: data.time !== null ? formatDateTime(data.time) : data.time
          }

        }))

        setIsModal(prevState => ({...prevState, loading: false}))

      },
      (error) => {
        //catchError(props.filter.jobNumber, props.filter.gradeId, props.component, 'selectHistory', JSON.stringify(error), props.user.username, props.user.device)
        console.log(`Error: ${error}`)
      }
    )

  }

  useEffect(() => {
    if (isModal.google) initMap()
  }, [props.filter, isModal.google, fetchedData])

  useEffect(() => {
    if (isModal.google) updateMap()
  }, [searchValue, isModal.google, fetchedData])

  useEffect(() => {
    fetchData()
  }, [props.filter])

  // Sets the map on all markers in the array.
  const hightlightMarker = (e) => {

    let id = parseInt(e.target.parentNode.getAttribute('data-id'))

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/yellow-dot.png')
        markerRef.current[i].setAnimation(window.google.maps.Animation.BOUNCE)
      } else {

        markerRef.current[i].setIcon('http://maps.google.com/mapfiles/ms/icons/blue-dot.png')
        markerRef.current[i].setAnimation(null)
        markerRef.current[i].getVisible(false)
      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const updateMap = (id, visible) => {

    for (let i = 0; i < markerRef.current.length; i++) {
      if (markerRef.current[i].get("id") === id) {

        markerRef.current[i].setVisible(visible)

      }

      markerRef.current[i].setMap(mapRef.current);
    }
  }

  const initMap = () => {

    let data = fetchedData.filter(data => {

      if (data.lat !== '' && data.lat !== null && data.lng !== '' && data.lng !== null) {

        let jn = data.jobNumber === null ? '' : data.jobNumber
        let gd = data.phase === null ? '' : data.phase
        let category = data.category === null ? '' : data.category
        let action = data.action === null ? '' : data.action
        let description = data.description === null ? '' : data.description
        let entryBy = data.user !== null && data.user !== '' ? data.user : ''
        let entryTime = data.time === null ? '' : data.time

        return true

      }

      return false


    })

    let google = googleRef.current

    mapRef.current = new window.google.maps.Map(google, {
         mapTypeId: window.google.maps.MapTypeId.ROADMAP
    });

    let infoWindow = new window.google.maps.InfoWindow;

    let marker, i, latLng, jn, gd, category, action, description, entryBy, entryTime

    //let i, latLng

    let bounds = new window.google.maps.LatLngBounds();

    //console.log(`locations: ${JSON.stringify(data)}`)

    for (i = 0; i < data.length; i++) {

      jn = data[i].jobNumber === null ? '' : data[i].jobNumber
      gd = data[i].phase === null ? '' : data[i].phase
      category = data[i].category === null ? '' : data[i].category
      action = data[i].action === null ? '' : data[i].action
      description = data[i].description === null ? '' : data[i].description
      entryBy = data[i].user !== null && data[i].user !== '' ? data[i].user : ''
      entryTime = data[i].time === null ? '' : data[i].time

      marker = new window.google.maps.Marker({
        position: new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng),
        title: entryBy,
        animation: window.google.maps.Animation.DROP,
        map: mapRef.current,
        icon: {
          url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'
        },
        id: data[i].id,
        visible: filterData(data[i])
      });

      marker.setMap(mapRef.current)

      markerRef.current.push(marker)

      latLng = new window.google.maps.LatLng(data[i].entrylat, data[i].entrylng);

      bounds.extend(latLng);

      window.google.maps.event.addListener(marker, 'click', function () {
        infoWindow.setContent('<h3>' + this.title + '</h3>');
        infoWindow.open(mapRef.current, this);
      });

    }

    mapRef.current.fitBounds(bounds);

    mapRef.current.setCenter(bounds.getCenter());

  }

  const changedData = () => isChanged.current = true

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const toggleGoogle = () => setIsModal(prevState => ({...prevState, google: isModal.google ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const filterData = (data) => {

    let jn = data.jobNumber === null ? '' : data.jobNumber
    let gd = data.phase === null ? '' : data.phase
    let category = data.category === null ? '' : data.category
    let action = data.action === null ? '' : data.action
    let description = data.description === null ? '' : data.description
    let entryBy = data.user !== null && data.user !== '' ? data.user : ''
    let entryTime = data.time === null ? '' : data.time

    if (

      (searchValue === '' ||
      jn.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      gd.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      category.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      action.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      description.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryBy.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {

      return true

    } else {

      return false

    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let jn = data.jobNumber === null ? '' : data.jobNumber
    let gd = data.phase === null ? '' : data.phase === 'C' ? 'Const' : data.phase === 'P' ? 'Invest' : ''
    let category = data.category === null ? '' : data.category
    let action = data.action === null ? '' : data.action
    let description = data.description === null ? '' : data.description
    let user = data.user !== null && data.user !== '' ? data.user : ''
    let time = data.time === null ? '' : data.time

    let filter = filterData(data)
    //console.log(`filter ${filter}`)

    if (filter) {

      if (isModal.google) updateMap(data.id, true)

      return (
        <tr key={data.id.toString()} data-id={data.id} onClick={null} onMouseOver={hightlightMarker}>
          <td style={{display: 'none'}}>{i}</td>
          {isModal.google ?
            <td>
              {data.lat === '' || data.lat === null || data.lng === '' || data.lng === null ?
              <Icon name='wrong_location' color='tomato' /> :
              <Icon name='where_to_vote' color='dodgerblue' />}
            </td> : null
          }
          <td>{time}</td>
          <td>{user}</td>
          {props.filter.jobNumber === null || props.filter.jobNumber === '' ?
            <>
              <td>{jn}</td>
              <td>{gd}</td>
            </> : null
          }
          <td>{category}</td>
          <td>{action}</td>
          <td>{description}</td>

        </tr>
      )

    } else {
      if (isModal.google) updateMap(data.id, false)
    }

  })

  return (
    <>
      {!isModal.loading ?
        <div style={{display: 'flex', width: '100%', height: '100%', overflow: 'auto'}}>

          <div style={{margin: 10, flex: isModal.google ? '0 1 auto' : '1 0 auto'}}>

            <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

              <div>
                <Icon name='refresh' onClick={fetchData} />
                <Icon name='location_on' color={isModal.google ? 'dodgerblue' : 'gray'} onClick={toggleGoogle} />
              </div>

              <SearchBar search={search} searchValue={searchValue} clearSearch={clearSearch} />

              {fetchedData.length > 0 ?

                <>

                  <p style={{margin: 10}}>{fetchedData.length} Total Records. (If no filters, limit 500)</p>

                  <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                    <table>

                      <thead>
                        <tr>
                          {isModal.google ? <th></th> : null}
                          <th>Time</th>
                          <th>By</th>
                          {props.filter.jobNumber === null || props.filter.jobNumber === '' ? <><th>JN</th><th></th></> : null}
                          <th>Category</th>
                          <th>Action</th>
                          <th>Descrip</th>
                        </tr>
                      </thead>

                      <tbody>
                        {listOfData}
                      </tbody>

                    </table>

                  </div>

                </> :
                <p style={{margin: 10}}>No history found.</p>

              }

            </div>

          </div>

          {isModal.google ?

            <div style={{margin: 10, flex: '1 0 auto', overflow: 'auto'}}>
              <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
            </div> : null

          }

        </div> :
        <p style={{margin: 10}}>Loading...</p>

      }

    </>
  )

}

export default History
