import React, {useState, useEffect} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Resist = (props) => {

  const data = props.data[0]
  const [fetchedTrials, setFetchedTrials] = useState([])
  const [isTrial, setIsTrial] = useState(false)
  const [isModal, setIsModal] = useState(false)
  //const [isChangedSample, setIsChangedSample] = useState(false)
  const [isChangedTrial, setIsChangedTrial] = useState(false)

  const [isValidated, setIsValidated] = useState({
    id: null,
    addedWater: null,
    resist: null,
    temp: data.temp,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const fetchData = () => {

    fetch('/api/selectResistTrials', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({resistId: data.id})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedTrials(result)
        setIsTrial(false)
        setIsChangedTrial(false)
        setIsModal(false)
        setIsValidated(prevState => ({...prevState, id: null, addedWater: null, resist: null}))

      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateResist', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            temp: isValidated.temp
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'resist', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusresist',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
                // if (isValidated.status === 'completed') {
                //   props.closeTestModal()
                // }
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.temp === '' || isValidated.temp === null) {
        alert('Please provide a temperature.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const selectTrial = (event) => {

    let el = event.target
    let id = el.getAttribute('data-id')

    fetch('/api/selectResistTrial', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id})
    })
    .then(res=>res.json())
    .then(
      (result) => {

        setIsValidated(prevState => ({
          ...prevState,
          id: id,
          addedWater: result[0].addedWater,
          resist: result[0].resist
        }))

        setIsModal(true)

      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  const addTrial = () => {

    if (isValidated.addedWater === '' || isValidated.addedWater === null) {
      alert('Please provide added water.')
    } else if (isValidated.resist === '' || isValidated.resist === null) {
      alert('Please provide a measured resistivity.')
    } else {

      getLocation(function(latlng){

        fetch('/api/addResistTrial', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            resistId: data.id,
            addedWater: isValidated.addedWater,
            resist: isValidated.resist,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'resist trial', 'add', description, props.user.username)

            fetchData()
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const editTrial = () => {

    getLocation(function(latlng){

      fetch('/api/editResistTrial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id,
          addedWater: isValidated.addedWater,
          resist: isValidated.resist,
          by: props.user.username,
          time: formatDateTime(new Date()),
          lat: latlng.lat,
          lng: latlng.lng
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'resist trial', 'edit', description, props.user.username)

          fetchData()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    })

  }

  const deleteTrial = (event) => {

    if (!props.user.todo > 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (window.confirm('This will delete the trial permanently. Proceed?')) {

      let el = event.target
      let id = el.getAttribute('data-id')

      fetch('/api/deleteResistTrial', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: id})
      })
      .then(res=>res.json())
      .then(
        (result) => {

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'resist trial', 'delete', description, props.user.username)

          fetchData()
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const showTrialInputs = () => setIsTrial(true)

  //const changedSample = () => setIsChangedSample(true)

  const changedTrial = () => setIsChangedTrial(true)

  const closeModal = () => {

    const update = () => {
      setIsChangedTrial(false)
      setIsTrial(false)
      setIsModal(false)
      setIsValidated(prevState => ({...prevState,
        id: null,
        addedWater: null,
        resist: null
      }))
    }

    if (isChangedTrial) {
      if (window.confirm('You have unsaved data. Close anyways?')) {
        update()
      }
    } else {
      update()
    }
  }

  let trials = fetchedTrials.map(trial => {

    let editIcon = props.user.todo > 1 ?
      <Icon name='edit' id={trial.id} onClick={selectTrial} /> :
      null

    let deleteIcon = props.user.todo > 2 ?
      <Icon name='delete' color='tomato' id={trial.id} onClick={deleteTrial} /> :
      null

    return (
      <tr>
        <td>{trial.addedWater}</td>
        <td>{trial.resist}</td>
        {editIcon}
        {deleteIcon}
      </tr>
    )
  })

  let tableOfTrials = trials.length > 0 ?
  (
    <table>
      <thead>
        <tr>
          <th>+ Water (ml)</th>
          <th>Resist (ohm)</th>
        </tr>
      </thead>
      <tbody>
        {trials}
      </tbody>
    </table>
  ) :
  <h4>No Trials started.</h4>

  let modalContent = (
    <div style={{textAlign: 'center'}}>

      <label>Added Water (ml)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}" name='addedWater' onInput={validate} onChange={changedTrial} defaultValue={isValidated.addedWater} required />
      </label><br />

      <label>Resistivity (ohm)
        <input style={{width: 75}} className='input' type="text" pattern="\d{1,6}" name='resist' onInput={validate} onChange={changedTrial} defaultValue={isValidated.resist} required />
      </label>

      <div style={{textAlign: 'center', marginTop: 10}}>

        <Icon name='check_circle' color={isChangedTrial ? 'dodgerblue' : 'gray'} onClick={isChangedTrial ? editTrial : nothingChanged} />

      </div>

    </div>
  )

  let modal = isModal ? <Modal content={modalContent} closeModal={closeModal} /> : null

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let addTrialIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={isChangedTrial ? 'dodgerblue' : 'gray'} onClick={isChangedTrial ? addTrial : nothingChanged} /> :
    null

  return (
    <div>
    {modal}
      <div style={{overflowX: 'auto'}}>

        <div>
          <div style={{display: 'inline-block', textAlign: 'center', marginTop: 10}}>
            <h3>Trials</h3>
            {tableOfTrials}
          </div>
        </div>

        {isTrial ?

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            <label>Added Water (ml)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}" name='addedWater' onInput={validate} onChange={changedTrial} required />
            </label><br />

            <label>Resistivity (ohm)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,6}" name='resist' onInput={validate} onChange={changedTrial} required />
            </label><br />

            <div style={{textAlign: 'center', marginTop: 10}}>

              {addTrialIcon}

            </div>

          </div> :

          <div style={{textAlign: 'center', marginTop: 10}}>
            <Icon name='add_circle' onClick={showTrialInputs} />
          </div>

        }

        <div style={{textAlign: 'center', marginTop: 10}}>

          <label>Temp (°C)
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,2}(\.\d{1})?" name='temp' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.temp} required />
          </label>

        </div>

        <div style={{textAlign: 'center', marginTop: 10}}>

          <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

          <SentOut data={data} onInput={validate} onChange={props.changedSample} />

          <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

          {editSampleIcon}

        </div>

      </div>
    </div>
  )
}

export default Resist
