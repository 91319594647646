import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import SearchBar from 'components/utils/SearchBar'
import ViewLocation from 'components/utils/ViewLocation'

import { formatDateYMD, formatDateTime, getLocation } from 'scripts/common'

const Descriptions = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false
  })
  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    description: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    description: ''
  })

  const fetchData = () => {

    fetch('/api/selectDescriptions', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      }
      // ,
      // body: JSON.stringify({
      //   jobNumber: props.filter.jobNumber,
      //   gradeId: props.filter.gradeId
      // })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectDescriptions --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data id not found. Contact an admin.')
    } else {

      //console.log(`data: ${JSON.stringify(fetchedData[i])}`)

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        description: fetchedData[i].description
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addDescription = () => {

    if (props.user.description < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description.")
    } else {

      getLocation(function(latlng){

        fetch('/api/addDescription', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            description: isValidated.description
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            // setFetchedData(prevState => ([
            //   {
            //     username: isValidated.userName,
            //     userpw: isValidated.userPW,
            //     userLevel: isValidated.userlevel,
            //     fullname: isValidated.fullName,
            //     email: isValidated.email,
            //     phone: isValidated.phone,
            //     guestaccess: isValidated.guestAccess,
            //     status: isValidated.status
            //   },
            //   ...prevState])
            // )

            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not add. Contact and admin.')
            console.log('Error: addDescription --> ' + error)
          }
        )

      })

    }

  }

  const editDescription = () => {

    if (props.user.description < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else if (isChanged.current === false) {
      alert('Nothing has been changed.')
    } else if (isValidated.description === null || isValidated.description ==='') {
        alert("Please provide a description.")
    } else {

      getLocation(function(latlng){

        fetch('/api/editDescription', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            description: isValidated.description
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                description: isValidated.description
              } :
              data
            ))

            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not edit. Contact and admin.')
            console.log('Error: editDescription --> ' + error)
          }
        )

      })

    }

  }

  const deleteDescription = () => {

    if (props.user.description < 3) {
      alert('You do not have the required permission. Contact an admin.')
    } else {

      if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

        fetch('/api/deleteDescription', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.id
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
            isChanged.current = false
            closeModal()

          },
          (error) => {

            alert('Error: could not delete. Contact and admin.')
            console.log('Error: deleteDescription --> ' + error)
          }
        )

      }

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => {

    if (props.user.description < 2) {
      alert('You do not have the required permission. Contact an admin.')
    } else {
      setIsModal(prevState => ({...prevState, add: true}))
    }

  }

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  let listOfData = fetchedData.map((data, i) => {

    let description = data.description === null ? '' : data.description

    if (
      searchValue === '' ||
      description.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    ) {
      return (
        <tr onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{description}</td>
        </tr>
      )
    }

  })

  let modalContent = (
    <div style={{width: '100%', height: '100%', textAlign: 'center'}}>

      {isModal.edit ? <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'inline-block', textAlign: 'right'}}>

        <label>Descrip
          <input style={{width: 200}} className='input' type="text" pattern="[a-zA-Z\s]{1,}" name='description' onInput={validate} onChange={changedData} defaultValue={isValidated.description} required />
        </label>

      </div>

    </div>
  )

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addDescription : isModal.edit ? editDescription : null} delete={isModal.edit ? deleteDescription : null} content={modalContent} closeModal={closeModal} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

            <div>
              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

              <table>

                <thead>
                  <tr>
                    <th>Descrip</th>
                  </tr>
                </thead>

                <tbody>
                  {listOfData}
                </tbody>

              </table>
              </div> :
              <p style={{margin: 10}}>No Descriptions found.</p>
            }

          </div>

        </div>

      </div>

    </>
  )

}

export default Descriptions
