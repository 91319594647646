import React, {useState, useEffect, useRef} from 'react'
import Modal from 'components/utils/Modal'

const ArchiveRacks = (props) => {

  const [fetchedData, setFetchedData] = useState([])
  const [racks, setRacks] = useState([
    {name: 1, state: true},
    {name: 2, state: false},
    {name: 3, state: false},
    {name: 4, state: false},
    {name: 5, state: false}
  ])

  const [shelf, setShelf] = useState({rack: null, row: null, col: null})

  const fetchData = () => {
    fetch('/api/archivedSamples', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({jobnumber: props.filter.jobNumber, phase: props.filter.phaseId})
    })
      .then(res=>res.json())
      .then(
        (result) => {
          setFetchedData(result)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )
  }

  useEffect(() => {
    fetchData()
  }, [props.filter])

  useEffect(() => {
    if (!props.selectedFound) fetchData() // when saved, becomes false..technically they can unselect and trigger, but good for now
  }, [props.selectedFound])

  const selectRack = (event) => {
    let value = parseInt(event.target.value)

    setRacks(racks.map(rack => rack.name === value ? {...rack, state: true} : {...rack, state: false}))

  }

  const selectShelf = (event) => {

    let el = event.target
    let rack = parseInt(el.getAttribute('data-rack'))
    let row = parseInt(el.getAttribute('data-row'))
    let col = parseInt(el.getAttribute('data-col'))

    props.selectSamples('', rack, row, col)

    //console.log(`rack: ${rack[0].name}, row: ${row}, col: ${col}`)

    setShelf({rack: rack, row: row, col: col})

  }

  const selectSamples = (event) => {

    event.stopPropagation()

    let el = event.target
    let jn = el.getAttribute('data-jn')
    let rack = parseInt(el.getAttribute('data-rack'))
    let row = parseInt(el.getAttribute('data-row'))
    let col = parseInt(el.getAttribute('data-col'))

    props.selectSamples(jn, rack, row, col)

  }

  const returnShelf = () => {
    if (props.addShelf !== undefined) {
      if (shelf.row !== null) {
        props.addShelf(shelf.rack, shelf.row, shelf.col)
      } else {
        alert(`Please select a shelf.`)
      }
    }
  }

  let rackList = racks.map(rack => {

    let style = {
      backgroundColor: rack.state ? 'dodgerblue' : 'white',
      border: '1px solid #d3d3d3',
      borderRadius: 5,
      color: rack.state ? 'white' : 'black',
      display: 'inline-block',
      fontSize: 30,
      height: 50,
      width: 50,
      margin: 10
    }

    return (
      <button type="button" style={style} value={rack.name} onClick={selectRack}>{rack.name}</button>
    )
  })

  let rack = racks.filter(data => data.state === true)
  let rows = [1,2,3,4,5]
  let cols = [1,2,3,4,5,6]

  let shelfList = rows.map(row => {

    let colList = cols.map(col => {

      let archivedList = fetchedData.map(data => {
        //console.log(`JN: ${JSON.stringify(data)}`)
        let style = {
          backgroundColor: 'white',
          border: '1px solid black',
          borderRadius: 5,
          float: 'left',
          margin: 2,
          textAlign: 'center',
          width: 60
        }

        // console.log(`rack: ${rack.name} = ${data.archiveRack}`)
        // console.log(`row: ${row} = ${data.archiveRow}`)
        // console.log(`col: ${col} = ${data.archiveCol}`)

        return (
          rack[0].name === data.archiveRack &&
          row === data.archiveRow &&
          col === data.archiveCol ?
          <div style={style} data-jn={data.jobnumber} data-rack={rack[0].name} data-row={row} data-col={col} onClick={selectSamples}>{data.jobnumber}</div> :
          null
        )

      })

      //console.log('row: ' + row + ', col: ' + col)
      //props.selectShelf === undefined ? null : props.selectShelf
      return (
        <div className={shelf.rack === rack[0].name && shelf.row === row && shelf.col === col ? 'activeShelf' : 'archiveShelf'} data-rack={rack[0].name} data-row={row} data-col={col} onClick={selectShelf}>{archivedList}</div>
      )
    })

    let style = {
      display: 'flex',
      overflow: 'auto'
    }

    //<span>{row}</span>

    return (
      <div style={style}>{colList}</div>
    )
  })

  let shelfHeader = {
    display: 'inline-block',
    width: 202
  }

  // <div>
  //   <div style={{display: 'inline-block', width: 200, marginLeft: 10}}>1</div>
  //   <div style={shelfHeader}>2</div>
  //   <div style={shelfHeader}>3</div>
  //   <div style={shelfHeader}>4</div>
  //   <div style={shelfHeader}>5</div>
  //   <div style={shelfHeader}>6</div>
  // </div>

  let content = (
    <div>

      <div>
        {rackList}
      </div>



      <div style={{margin: 10}}>
        {shelfList}
      </div>

    </div>
  )

  return props.modal ? <Modal content={content} add={returnShelf} closeModal={props.closeModal} maxWidth='none' /> : content

}

export default ArchiveRacks
