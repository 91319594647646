import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import Modal from 'components/utils/Modal'

import SampleStatus from 'components/utils/SampleStatus'
import TestNotes from 'components/utils/TestNotes'
import SentOut from 'components/utils/SentOut'

import { formatDateTime, getLocation, nothingChanged, addActivity } from 'scripts/common'

const Consol = (props) => {

  const data = props.data[0]
  const [fetchedConsolReadings, setFetchedConsolReadings] = useState([])
  const [isReading, setIsReading] = useState(false)
  const [isModal, setIsModal] = useState(false)
  //const [isChangedSample, setIsChangedSample] = useState(false)
  const [isChangedReading, setIsChangedReading] = useState(false)

  const [isValidated, setIsValidated] = useState({
    dialReading: null,
    loadApplied: null,
    rack: data.rack,
    beforeWetSoil: data.beforewetsoilandringwt,
    afterWetSoil: data.afterwetsoilandringwt,
    afterDrySoil: data.afterdrysoilandtarewtandringwt,
    ringWt: data.ringwt,
    tareNo: data.tareNo,
    tareWt: data.tarewt,
    status: data.status,
    notes: data.notes,
    sentOutCompany: data.sentOutCompany,
    sentOutDeliverDate: data.sentOutDeliverDate,
    sentOutReceiveDate: data.sentOutReceiveDate
  })

  const canvasRef = useRef(null)
  const loadAppliedRef = useRef(null)
  const dialReadingRef = useRef(null)

  const fetchData = () => {

    fetch('/api/selectConsolReadings', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: data.labid})
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + result)
        setFetchedConsolReadings(result)
        setIsReading(false)
        setIsChangedReading(false)
        setIsModal(false)
        setIsValidated(prevState => ({...prevState, dialReading: null, loadApplied: null}))
      },
      (error) => {
        console.log('error: ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    buildGraph()
  })

  const buildGraph = () => {
    let canvas, ctx, chart, percentConsol, loadAccum
    let backgroundColor = '#d3d3d3'
    let borderColor = '#000000'
    let pointBackgroundColor = []
    let pointBorderColor = []

    let data = fetchedConsolReadings.map((reading, i) => {
      if (i === 0) {
        // skip
        pointBackgroundColor.push(backgroundColor)
        pointBorderColor.push(borderColor)
      } else if (i === 1) {
        if (reading.loadapplied === 0) backgroundColor = '#000000'

        pointBackgroundColor.push(backgroundColor)
        pointBorderColor.push(borderColor)
        loadAccum = reading.loadapplied * 1000
        percentConsol = Math.round((reading.dialreading - fetchedConsolReadings[0].dialreading) * 100 * 10)/10

        return ({x: loadAccum, y: percentConsol})
      } else {
        if (reading.loadapplied === 0) backgroundColor = '#000000'

        pointBackgroundColor.push(backgroundColor)
        pointBorderColor.push(borderColor)
        loadAccum = loadAccum + reading.loadapplied * 1000
        percentConsol = Math.round((reading.dialreading - fetchedConsolReadings[0].dialreading) * 100 * 10)/10

        return ({x: loadAccum, y: percentConsol})
      }
    })

    // plots data before submit for review purposes
    if (isValidated.dialReading !== '' && isValidated.dialReading !== null && isValidated.loadApplied !== '' && isValidated.loadApplied !== null) {
      if (data.length > 0) {
        pointBackgroundColor.push('dodgerblue')
        pointBorderColor.push(borderColor)
        loadAccum = loadAccum + isValidated.loadApplied * 1000
        percentConsol = Math.round((isValidated.dialReading - fetchedConsolReadings[0].dialreading) * 100 * 10)/10
        data.push({x: loadAccum, y: percentConsol})
      }
    }

    canvas = canvasRef.current
    ctx = canvas.getContext('2d')
    // // must destroy previous charts to prevent data carried over
    // ctx ? ctx.destory() : canvas.getContext('2d')
    chart = new window.Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [{
          label: 'readings',
          data: data,
          borderColor: 'black',
          borderWidth: 1,
          pointBackgroundColor: pointBackgroundColor,
          pointBorderColor: pointBorderColor,
          pointRadius: 5,
          pointHoverRadius: 5,
          fill: false,
          tension: 0,
          showLine: true
        }]
      },
      options: {
        maintainAspectRatio: true,
        legend: {display: false},
        scales: {
          xAxes: [{
            type: 'logarithmic',
            position: 'bottom',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              min: 100,
              max: 100000,
              callback: function (value, index, values) {
                return Number(value.toString()) //pass tick values as a string into Number function
              }
            },
            afterBuildTicks: function (chartObj) { //Build ticks labelling as per your need
              chartObj.ticks = []
              chartObj.ticks.push(100)
              chartObj.ticks.push(1000)
              chartObj.ticks.push(10000)
              chartObj.ticks.push(100000)
            }
          }],
          yAxes: [{
            type: 'linear',
            position: 'left',
            gridLines: {
               color: '#d3d3d3'
            },
            ticks: {
              reverse: true,
              min: 0
            }
          }]
        }
      }
    })
  }

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    event.target.style.backgroundColor = state ? 'white' : 'tomato'

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectConsolReading = (event) => {

    let el = event.target
    let id = el.getAttribute('data-id')

    fetch('/api/selectConsolReading', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({id: id})
    })
    .then(res=>res.json())
    .then(
      (result) => {

        setIsValidated(prevState => ({
          ...prevState,
          consolId: id,
          dialReading: result[0].dialreading,
          loadApplied: result[0].loadapplied
        }))

        setIsModal(true)

      },
      (error) => {
        console.log('error: ' + error)
      }
    )
  }

  const addConsolReading = () => {

    if (isValidated.rack === '' || isValidated.rack === null) {
      alert('Please select a rack.')
    } else if (isValidated.dialReading === '' || isValidated.dialReading === null) {
      alert('Please provide a dial reading.')
    } else if (isValidated.loadApplied === '' || isValidated.loadApplied === null) {
      alert('Please provide a load applied.')
    } else {

      getLocation(function(latlng){

        fetch('/api/addConsolReading', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.labid,
            dialReading: isValidated.dialReading,
            loadApplied: isValidated.loadApplied,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            fetchData()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'consol', 'add', description, props.user.username)
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const editConsolReading = () => {

    if (isValidated.rack === '' || isValidated.rack === null) {
      alert('Please select a rack.')
    } else if (isValidated.dialReading === '' || isValidated.dialReading === null) {
      alert('Please provide a dial reading.')
    } else if (isValidated.loadApplied === '' || isValidated.loadApplied === null) {
      alert('Please provide a load applied.')
    } else {

      getLocation(function(latlng){

        fetch('/api/editConsolReading', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: isValidated.consolId,
            dialReading: isValidated.dialReading,
            loadApplied: isValidated.loadApplied,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            fetchData()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'consol', 'edit', description, props.user.username)
          },
          (error) => {
            console.log('error: ' + error)
          }
        )

      })

    }

  }

  const deleteConsolReading = (event) => {

    if (window.confirm('This will delete the reading permanently. Proceed?')) {

      let el = event.target
      let id = el.getAttribute('data-id')

      fetch('/api/deleteConsolReading', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({id: id})
      })
      .then(res=>res.json())
      .then(
        (result) => {
          fetchData()

          let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

          addActivity('lab', data.jobnumber, data.phaseid, 'consol', 'delete', description, props.user.username)
        },
        (error) => {
          console.log('error: ' + error)
        }
      )

    }

  }

  const updateData = () => {

    const update = () => {

      getLocation(function(latlng){

        fetch('/api/updateConsol', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            id: data.id,
            rack: isValidated.rack,
            beforeWetSoil: isValidated.beforeWetSoil,
            afterWetSoil: isValidated.afterWetSoil,
            afterDrySoil: isValidated.afterDrySoil,
            ringWt: isValidated.ringWt,
            tareNo: isValidated.tareNo,
            tareWt: isValidated.tareWt,
            status: isValidated.status,
            startedby: data.startedby, // prevents updating startby
            completedby: data.completedby, // prevents updating completedby
            reviewedby: data.reviewedby, // prevents updating reviewedby
            notes: isValidated.notes,
            sentOutCompany: isValidated.sentOutCompany,
            sentOutDeliverDate: isValidated.sentOutDeliverDate,
            sentOutReceiveDate: isValidated.sentOutReceiveDate,
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + result)
            props.updateOrigin()
            props.updateSample()
            props.savedSample()

            let description = data.phaseid === 'P' ? `${data.location} @ ${data.depth}ft` :`SN ${data.sampleno}, ${data.location}`

            addActivity('lab', data.jobnumber, data.phaseid, 'consol', 'update', description, props.user.username)

            //this handles status on tbllab..eventually phase out!!

            fetch('/api/updateModalStatus', {
              method: 'post',
              headers: {
                'Accept': 'application/json, text/plain, */*',
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                id: data.labid,
                field: 'statusconsol',
                status: isValidated.status
              })
            })
            .then(res=>res.json())
            .then(
              (result) => {
                //console.log('result: ' + result)
                // if (isValidated.status === 'completed') {
                //   props.closeTestModal()
                // }
              },
              (error) => {
                alert('Error: could not update status')
                console.log('error: ' + error)
              }
            )

          },
          (error) => {
            alert('Error: could not update test')
            console.log('error: ' + error)
          }
        )

      })

    }

    if (isValidated.status === 'assigned' || isValidated.status === 'started') {
      update()

    } else if (isValidated.status === 'notRun') {

      if (isValidated.notes === '' || isValidated.notes === null) {
        alert('Please add a note.')
      } else {
        update()
      }

    } else if (isValidated.status === 'completed' || isValidated.status === 'reviewed') {

      let d = document
      let description = d.getElementById('sampleDescription').value
      let color = d.getElementById('sampleColor').value

      if (description === '' || description === null) {
        alert('Please provide a description.')
      } else if (color === '' || color === null) {
        alert('Please provide a color.')
      } else if (isValidated.beforeWetSoil === '' || isValidated.beforeWetSoil === null) {
        alert('Please provide a before wet soil weight.')
      } else if (isValidated.rack === '' || isValidated.rack === null) {
        alert('Please select a rack.')
      } else if (isValidated.afterWetSoil === '' || isValidated.afterWetSoil === null) {
        alert('Please provide an after wet soil weight.')
      } else if (isValidated.afterDrySoil === '' || isValidated.afterDrySoil === null) {
        alert('Please provide an after dry soil weight.')
      } else if (isValidated.ringWt === '' || isValidated.ringWt === null) {
        alert('Please provide a ring weight.')
      } else if (isValidated.tareNo === '' || isValidated.tareNo === null) {
        alert('Please provide a tare number.')
      } else if (isValidated.tareWt === '' || isValidated.tareWt === null) {
        alert('Please provide a tare weight.')
      } else {
        update()
      }

    } else {
      alert('Error: unexpected status.')
      console.log('updateSample Status error: ' + isValidated.status)
    }

  }

  const showConsolReadingInputs = () => setIsReading(true)

  //const changedSample = () => setIsChangedSample(true)

  const changedReading = () => setIsChangedReading(true)

  const closeModal = () => {

    const update = () => {
      setIsChangedReading(false)
      setIsReading(false)
      setIsModal(false)
      setIsValidated(prevState => ({...prevState, dialReading: null, loadApplied: null}))
    }

    if (isChangedReading) {
      if (window.confirm('You have unsaved data. Close anyways?')) {
        update()
      }
    } else {
      update()
    }
  }

  let modalContent = (
    <div style={{textAlign: 'center'}}>

    <label>Load Applied
      <input style={{width: 75}} className='input' type="text" pattern="-?\d{1,2}(\.\d{1,})?" name='loadApplied' onInput={validate} onChange={changedReading} defaultValue={isValidated.loadApplied} required />
    </label><br />

    <label>Dial Reading
      <input style={{width: 75}} className='input' type="text" pattern="\d{1}\.\d{3,4}" name='dialReading' onInput={validate} onChange={changedReading} defaultValue={isValidated.dialReading} required />
    </label><br />

      <div style={{textAlign: 'center', marginTop: 10}}>

        <Icon name='check_circle' color={isChangedReading ? 'dodgerblue' : 'gray'} onClick={isChangedReading ? editConsolReading : nothingChanged} />

      </div>

    </div>
  )

  let modal = isModal ? <Modal content={modalContent} closeModal={closeModal} /> : null

  let readings = fetchedConsolReadings.map(reading => {

    let editIcon = props.user.todo > 1 ?
      <Icon name='edit' id={reading.consolid} onClick={selectConsolReading} /> :
      null

    let deleteIcon = props.user.todo > 2 ?
      <Icon name='delete' color='tomato' id={reading.consolid} onClick={deleteConsolReading} /> :
      null

    return (
      <tr>
        <td>{formatDateTime(new Date(reading.entrytime))}</td>
        <td>{reading.loadapplied}</td>
        <td>{reading.dialreading}</td>
        <td>{reading.entryby}</td>
        {editIcon}
        {deleteIcon}
      </tr>
    )
  })

  let tableOfReadings = readings.length > 0 ?
    (
      <table>
        <thead>
          <tr>
            <th>Date Read</th>
            <th>Load Applied</th>
            <th>Dial Reading</th>
            <th>By</th>
          </tr>
        </thead>
        <tbody>
          {readings}
        </tbody>
      </table>
    ) :
    <h4>No Readings taken. Initial Reading will not graph.</h4>

  let editSampleIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={props.isChangedSample ? 'dodgerblue' : 'gray'} id={data.id} onClick={props.isChangedSample ? updateData : nothingChanged} /> :
    null

  let addReadingIcon = props.user.todo > 1 ?
    <Icon name='check_circle' color={isChangedReading ? 'dodgerblue' : 'gray'} onClick={isChangedReading ? addConsolReading : nothingChanged} /> :
    null

  return (
    <div>
      {modal}
      <div style={{display: 'inline-block', borderBottom: '1px solid #d3d3d3', margin: 10, width: 'calc(100% - 15px)'}}>

        <table style={{margin: '0 auto'}}>
          <tr>
            <th>Sat</th>
            <th>Load</th>
            <th>Unload</th>
            <th>Reload</th>
            <th>Unload</th>
            <th>Time</th>
          </tr>
          <tr>
            <td style={{borderBottom: 'none'}}>{data.saturation}</td>
            <td style={{borderBottom: 'none'}}>{data.cload}</td>
            <td style={{borderBottom: 'none'}}>{data.unload}</td>
            <td style={{borderBottom: 'none'}}>{data.reload}</td>
            <td style={{borderBottom: 'none'}}>{data.unload2}</td>
            <td style={{borderBottom: 'none'}}>{data.timereading}</td>
          </tr>
        </table>

      </div>

      <div style={{display: 'flex', overflowX: 'auto', width: '100%'}}>
        <div style={{margin: 10, flex: '1 0 auto'}}>

          <h3>Sample</h3>

          <h4>Before</h4>

          <div style={{display: 'inline-block', textAlign: 'right'}}>
            <label>Wt of Wet Soil and Ring (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='beforeWetSoil' onInput={validate} onChange={props.changedSample} defaultValue={data.beforewetsoilandringwt || ''} required />
            </label>
          </div>

          <h4>After</h4>

          <div style={{display: 'inline-block', textAlign: 'right'}}>

            <div>
              <label>Tare No</label>
              <input style={{width: 75}} className='input' type="text" pattern="[a-zA-Z0-9]{1,}" name='tareNo' onInput={validate} onChange={props.changedSample} defaultValue={data.tareNo || ''} required />
            </div>

            <label>Tare Wt (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='tareWt' onInput={validate} onChange={props.changedSample} defaultValue={data.tarewt || ''} required />
            </label><br />

            <label>Wet Soil and Ring and Tare Wt (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='afterWetSoil' onInput={validate} onChange={props.changedSample} defaultValue={data.afterwetsoilandringwt || ''} required />
            </label><br />

            <label>Dry Soil and Ring and Tare Wt (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='afterDrySoil' onInput={validate} onChange={props.changedSample} defaultValue={data.afterdrysoilandtarewtandringwt || ''} required />
            </label><br />

            <label>Ring Wt (g)
              <input style={{width: 75}} className='input' type="text" pattern="\d{1,4}(\.\d{1})?" name='ringWt' onInput={validate} onChange={props.changedSample} defaultValue={data.ringwt || ''} required />
            </label><br />

          </div>

          <div style={{textAlign: 'center', marginTop: 10}}>

            <TestNotes notes={data.notes} onInput={validate} onChange={props.changedSample} />

            <SentOut data={data} onInput={validate} onChange={props.changedSample} />

            <SampleStatus who={props.who} status={data.status} onInput={validate} onChange={props.changedSample} />

            {editSampleIcon}

          </div>

        </div>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <h3>Readings</h3>

          <label>Rack
            <select style={{width: 50}} className='select' pattern=".{1,}" name='rack' onInput={validate} onChange={props.changedSample} defaultValue={isValidated.rack} required>
              <option value={null}></option>
              <option value='1'>1</option>
              <option value='2'>2</option>
              <option value='3'>3</option>
              <option value='4'>4</option>
              <option value='5'>5</option>
              <option value='6'>6</option>
              <option value='7'>7</option>
              <option value='8'>8</option>
              <option value='9'>9</option>
              <option value='10'>10</option>
              <option value='11'>11</option>
              <option value='12'>12</option>
            </select>
          </label><br />

          {tableOfReadings}

          {isReading ?

            <div style={{textAlign: 'center'}}>

              <label>Load Applied
                <input style={{width: 75}} className='input' ref={loadAppliedRef} type="text" pattern="-?\d{1,2}(\.\d{1,})?" name='loadApplied' onInput={validate} onChange={changedReading} required />
              </label><br />

              <label>Dial Reading
                <input style={{width: 75}} className='input' ref={dialReadingRef} type="text" pattern="\d{1}\.\d{3,4}" name='dialReading' onInput={validate} onChange={changedReading} required />
              </label><br />

              <div style={{textAlign: 'center', marginTop: 10}}>

                {addReadingIcon}

              </div>

            </div> :

            <div style={{textAlign: 'center', marginTop: 10}}>
              <Icon name='add_circle' onClick={showConsolReadingInputs} />
            </div>

          }

        </div>

        <div style={{margin: 10, flex: '1 1 auto'}}>
          <h3>Graph</h3>
          <div style={{flex: '1 1 auto'}}>
            <canvas ref={canvasRef}></canvas>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Consol
